// esg.js
import ESGPractice from "@/views/esg/ESGPractice.vue";
import Environmental from "@/views/esg/Environmental.vue";
import Social from "@/views/esg/Social.vue";
import Governance from "@/views/esg/Governance.vue";

const esgRoutes = [
    {
        path: "esg-practice",
        component: ESGPractice,
        meta: { main: '지속가능경영', sub: '가후이엔씨의 ESG' }
    },
    {
        path: "environmental",
        component: Environmental,
        meta: { main: '지속가능경영', sub: 'Environmental' }
    },
    {
        path: "social",
        component: Social,
        meta: { main: '지속가능경영', sub: 'Social' }
    },
    {
        path: "governance",
        component: Governance,
        meta: { main: '지속가능경영', sub: 'Governance' }
    }
];

export default esgRoutes;
