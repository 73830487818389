<template>
  <img ref="firstImage" class="first-image fade-in-up" style="width: 100%;" src="/img/public_img/bg_02_img.png" alt="Logo"/>
  <div class="main-container">
    <div class="div-head">
      <span class="head-text">
        효율적인 공정을 위한 혁신적인 자동화<br/>
        Process & Factory Automation
      </span>
    </div>
    <div class="div-head">
      <span class="head-sub-text">
        자동화 시스템은 반복적이고 일관된 작업을 빠르고 정확하게 수행할 수 있어, 인력에 비해 훨씬 더 높은 생산성을 발휘할 수 있습니다.<br/>
        이는 생산 시간 단축, 비용 절감, 생산성 향상으로 이어집니다. 또한 인간의 실수나 변동성을 최소화하여 제품의 일관된 품질을<br/>
        보장할 수 있습니다. 정밀한 제어와 모니터링이 가능해지면서 불량률을 줄이고, 높은 품질의 제품을 안정적으로 생산할 수 있습니다.
      </span>
    </div>
    <div class="div-head">
      <span class="head-sub-text">
        당사의 P.A & F.A System은 다년간의 경험을 통하여 국산화한 제어 시스템으로 안정성, 경제성, 신뢰성을 특징으로 합니다.<br/>
        당사는 설치에서부터 응용 S/W개발, 시운전 및 사용자 교육, Document, A/S등 모든 업무를 최적의 품질로 제공합니다.
      </span>
    </div>
    <div class="div-sub-head">
      <span class="sub-head-text">
        가후이엔씨는 굵직한 산업의 P.A& F.A 시스템을 구축 하였습니다.<br/>
        시멘트, 소각로, 출하시스템 등<br/>
        우리나라 주요 산업의 자동화 시스템 개발/구축 경험을 가지고 있습니다!
      </span>
    </div>
  </div>
  <div v-if="!isSmallScreen" class="card-container">
    <div class="main-container card-flex">
      <Card class="card card1">
        <template #title>
          <span class="card-title-icon">|</span>
          <span class="card-title-text">시멘트 Process 자동화</span>
        </template>
        <template #content>
          <span class="card-text">
            광산설비 자동화<br/>
            부원료 처리설비 자동화<br/>
            Raw Mill & Klin 설비 자동화<br/>
            Coal Mill & Clinker 이송설비 자동화<br/>
            Packer & Bulk 출하설비 자동화<br/>
            Ship Loading & Unloading 설비 자동화<br/>
            집진설비자동화<br/>
          </span>
        </template>
      </Card>
      <Card class="card card2">
        <template #title>
          <span class="card-title-icon">|</span>
          <span class="card-title-text">소각 Process 자동화</span>
        </template>
        <template #content>
          <span class="card-text">
            소각료 연료 공급계통 자동화<br/>
            배기 가스 처리 계통 자동화<br/>
            재처리 설비 자동화<br/>
            쓰레기 반입 설비 자동화<br/>
            소각로 Utility 설비 자동화<br/>
          </span>
        </template>
      </Card>
      <Card class="card card3">
        <template #title>
          <span class="card-title-icon">|</span>
          <span class="card-title-text">출하 Process 자동화</span>
        </template>
        <template #content>
          <span class="card-text">
            오일 및 가스, 출하 자동화<br/>
            시멘트 및 레미콘, 출하 자동화<br/>
            기타, 제품 출하 자동화<br/>
          </span>
        </template>
      </Card>
      <Card class="card card4">
        <template #title>
          <span class="card-title-icon">|</span>
          <span class="card-title-text">배연 탈활(FGD) Process 자동화</span>
        </template>
        <template #content>
          <span class="card-text">
            배기가스 계통 설비 자동화<br/>
            Absorber 계통 설비 자동화<br/>
            Limestone Preparation 계통 자동화<br/>
            Gypsum De-watering 계통 설비 자동화<br/>
            석회석/석고 취급 설비 자동화<br/>
            탈황 폐수 처리 설비 자동화<br/>
          </span>
        </template>
      </Card>
      <Card class="card card5">
        <template #title>
          <span class="card-title-icon">|</span>
          <span class="card-title-text">철강 Process 자동화</span>
        </template>
        <template #content>
          <span class="card-text">
            원료 이송 설비 자동화<br/>
            계중 설비 자동화<br/>
            부원료 및 합금철 투입설비 자동화<br/>
          </span>
        </template>
      </Card>
      <Card class="card card6">
        <template #title>
          <span class="card-title-icon">|</span>
          <span class="card-title-text">수처리 Process 자동화</span>
        </template>
        <template #content>
          <span class="card-text">
            폐수처리 설비 자동화<br/>
            상,하수 처리 설비 자동화<br/>
            순수 처리 설비 자동화<br/>
          </span>
        </template>
      </Card>
      <Card class="card card7">
        <template #title>
          <span class="card-title-icon">|</span>
          <span class="card-title-text">기타 Process 자동화</span>
        </template>
        <template #content>
          <span class="card-text">
            식음료 공정 자동화<br/>
            폐타이어 등 폐자원 활용설비 자동화<br/>
            석유화학, 정제 공정 자동화<br/>
            제지 공정 자동화<br/>
            제분, 제과 공장 자동화<br/>
          </span>
        </template>
      </Card>
    </div>
  </div>

  <!-- 540px 이하일 때만 보여지는 Carousel 카드 컨테이너 -->
  <div v-if="isSmallScreen" class="card-container-m">
    <Carousel :value="cards" :numVisible="1" :numScroll="1" class="small-screen-carousel">
      <template #item="slotProps">
        <Card :class="slotProps.data.class">
          <template #title>
            <span class="card-title-icon">|</span>
            <span class="card-title-text">{{ slotProps.data.title }}</span>
          </template>
          <template #content>
            <span class="card-text">{{ slotProps.data.content }}</span>
          </template>
        </Card>
      </template>
    </Carousel>
  </div>
</template>

<script setup>
import {ref, onMounted, onUnmounted} from 'vue';
import {useIntersectionObserver} from '@/utils/useIntersectionObserver';
import Card from 'primevue/card';
import Carousel from 'primevue/carousel';


const firstImage = ref(null);
useIntersectionObserver([firstImage]);

// 카드 데이터 정의
const cards = [
  { title: '시멘트 Process 자동화', content: `광산설비 자동화\n부원료 처리설비 자동화\nRaw Mill & Klin 설비 자동화\nCoal Mill & Clinker 이송설비 자동화\nPacker & Bulk 출하설비 자동화\nShip Loading & Unloading 설비 자동화`, class: 'card card1' },
  { title: '소각 Process 자동화', content: '소각료 연료 공급계통 자동화\n배기 가스 처리 계통 자동화\n재처리 설비 자동화\n쓰레기 반입 설비 자동화\n소각로 Utility 설비 자동화', class: 'card card2' },
  { title: '출하 Process 자동화', content: '오일 및 가스, 출하 자동화\n시멘트 및 레미콘, 출하 자동화\n기타, 제품 출하 자동화', class: 'card card3' },
  { title: '배연 탈활(FGD) Process 자동화', content: '배기가스 계통 설비 자동화\nAbsorber 계통 설비 자동화\nLimestone Preparation 계통 자동화\nGypsum De-watering 계통 설비 자동화\n석회석/석고 취급 설비 자동화\n탈황 폐수 처리 설비 자동화', class: 'card card4' },
  { title: '철강 Process 자동화', content: '원료 이송 설비 자동화\n계중 설비 자동화\n부원료 및 합금철 투입설비 자동화', class: 'card card5' },
  { title: '수처리 Process 자동화', content: '폐수처리 설비 자동화\n상,하수 처리 설비 자동화\n순수 처리 설비 자동화', class: 'card card6' },
  { title: '기타 Process 자동화', content: '식음료 공정 자동화\n폐타이어 등 폐자원 활용설비 자동화\n석유화학, 정제 공정 자동화\n제지 공정 자동화\n제분, 제과 공장 자동화', class: 'card card7' }
];

// 화면 크기 체크
const isSmallScreen = ref(window.innerWidth <= 540);
const handleResize = () => {
  isSmallScreen.value = window.innerWidth <= 540;
};

onMounted(() => {
  window.addEventListener('resize', handleResize);
});

onUnmounted(() => {
  window.removeEventListener('resize', handleResize);
});

</script>

<style scoped>
.div-head{
  margin: 0 0 2.0833vw 0;
}
.head-text{
  font-weight: bold;
  font-size: 2.5000vw;
}
.head-sub-text{
  font-size: 1.4583vw;
}
.div-sub-head{
  margin: 14.5833vw 0 10.4167vw 0;
  text-align: center;
}
.sub-head-text{
  font-weight: bold;
  font-size: 2.5000vw;
}
.card-container{
  background-color: #dddddd;
}
.card-flex{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.card-title-icon{
  color: yellow;
  font-size: 2.5000vw;
  margin: 0 0 0 3%;
}
.card-title-text{
  color: white;
  font-size: clamp(1.2500vw, 4vw, 2.3958vw); /* 최소 1.2500vw, 최대 2.5000vw, 화면 크기에 따른 중간값 4vw */
  margin: 0 0 0 1%;
}
.card-text{
  display: inline-block; /* 혹은 block으로 변경 가능 */
  color: white;
  font-size: 1.4583vw;
  margin: 5% 0 3% 7%;
}
.card{
  width: 48%;
  margin: 1% 1% 1% 1%;
  aspect-ratio: 1 / 0.6; /* 가로 : 세로 비율 */
  background-size: cover; /* 비율을 유지하며 영역을 채움 */
  background-position: center; /* 이미지를 중앙에 위치 */
  background-repeat: no-repeat;
}
.card1{
  margin-top: 10%;
  background-image: url('/img/product/factoryControl/con_banner01.png');
}
.card2{
  margin-top: 10%;
  background-image: url('/img/product/factoryControl/con_banner02.png');
}
.card3{
  background-image: url('/img/product/factoryControl/con_banner03.png');
}
.card4{
  background-image: url('/img/product/factoryControl/con_banner04.png');
}
.card5{
  background-image: url('/img/product/factoryControl/con_banner05.png');
}
.card6{
  background-image: url('/img/product/factoryControl/con_banner06.png');
}
.card7{
  background-image: url('/img/product/factoryControl/con_banner07.png');
  margin-bottom: 10%;
  width: 100%;
  aspect-ratio: 1 / 0.1; /* 가로 : 세로 비율 */
}
@media (max-width: 540px) {
  .first-image{
    display: none;
  }
  .main-container{
    width: 92.5926vw;
  }
  .head-text{
    display: block;
    font-size: 5.9259vw;
    margin: 11.1111vw 0 7.4074vw 0;
  }
  .head-sub-text{
    font-size: 4.4444vw;
  }
  .sub-head-text{
    font-size: 4.8148vw;
  }
  .card-title-icon{
    margin: 0 auto;
    font-size: 4.8148vw;
  }
  .card-title-text{
    font-size: 4.8148vw;
  }
  .card-text{
    white-space: pre-line;
    font-size: 3.3333vw;
    margin: 0 auto;
  }
  .card{

    width: 95%;
    height: 46.2963vw;
    background-size: cover; /* 비율을 유지하며 영역을 채움 */
    background-position: center; /* 이미지를 중앙에 위치 */
    background-repeat: no-repeat;
    aspect-ratio: auto; /* 가로 : 세로 비율 */
  }
  .card3{
    margin-top: 10%;  }
  .card4{
    margin-top: 10%;  }
  .card5{
    margin-top: 10%;  }
  .card6{
    margin-top: 10%;  }
  .card7{
    margin-top: 10%;
    margin-bottom: 0;
    width: 100%;
    aspect-ratio: auto; /* 가로 : 세로 비율 */  }
}
</style>