import { createRouter, createWebHistory, RouterView } from 'vue-router';
import MainPage from '../views/MainPage.vue';
import introduceRoutes from "@/router/introduce.js";
import esgRoutes from "@/router/esg.js";
import businessRoutes from "@/router/business.js";
import productsRoutes from "@/router/products.js";
import newsRoutes from "@/router/news.js";

const routes = [
  {
    path: '/',
    name: 'MainPage',
    component: MainPage,
    meta: { title: '메인 페이지', backgroundImage: '/img/header/header_img0.jpg', backgroundImage_m:'/img/header/m/m_main_bg01.jpg' }
  },
  {
    path: "/introduce",
    component: RouterView,
    children: introduceRoutes,
    meta: { title: '회사소개', backgroundImage: '/img/header/header_img1.jpg', backgroundImage_m:'/img/header/m/m_header_img1.jpg' }
  },
  {
    path: "/esg",
    component: RouterView,
    children: esgRoutes,
    meta: { title: 'ESG경영', backgroundImage: '/img/header/header_img2.jpg', backgroundImage_m:'/img/header/m/m_header_img2.jpg' }
  },
  {
    path: "/business",
    component: RouterView,
    children: businessRoutes,
    meta: { title: '사업분야' , backgroundImage: '/img/header/header_img3.jpg', backgroundImage_m:'/img/header/m/m_header_img3.jpg'}
  },
  {
    path: "/products",
    component: RouterView,
    children: productsRoutes,
    meta: { title: '제품소개' , backgroundImage: '/img/header/header_img4.jpg', backgroundImage_m:'/img/header/m/m_header_img4.jpg'}
  },
  {
    path: "/2news",
    component: RouterView,
    children: newsRoutes,
    meta: { title: '소식' , backgroundImage: '/img/header/header_img1.jpg'}
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;
