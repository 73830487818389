<template>
  <img ref="firstImage" class="first-image fade-in-up" style="width: 100%;" src="/img/public_img/bg_02_img.png" alt="Logo"/>
  <div class="main-container hs">
    <div class="div-head">
      <span class="head-text">H/W & S/W Solution<br/></span>
      <span class="head-sub-text">당사는 각종 제어 장비, 계측기기 및 관련 S/W를 공급하고 있습니다.<br/>
      당사가 제공하는 각종 계기 및 계측기 설계, 납품, 설치, 감리, 시운전, After Service까지 모든 것을 책임 집니다.<br/>
      이제 더 이상 사후관리 걱정이 필요없습니다.</span>
    </div>
    <div class="product-card">
      <div class="product-card-img">
        <img class="card-img" src="/img/product/HS/hwsw_img01.jpg" alt="Logo"/>
        <img class="card-img-m" src="/img/product/HS/m/cate_img05_01.jpg" alt="Logo"/>
      </div>
      <div class="product-card-text">
        <span class="card-text-head">
          PLC & DCS
        </span>
        <div class="card-text-tags">
          <span class="card-text-tag">Schneider Electric, Modicon PLC</span>
          <span class="card-text-tag">Siemens, SIMATIC S7 PLC</span>
          <span class="card-text-tag">Rockwell Automation, AB PLC</span>
          <span class="card-text-tag">Mitsubishi Electric, MELSEC PLC</span>
          <span class="card-text-tag">VIPA, Modular PLC</span>
          <span class="card-text-tag">VIPA, 200V PLC</span>
          <span class="card-text-tag">Omron, CJ/CS/CV/CVM PLC</span>
          <span class="card-text-tag">LS산전, XGT/GLOFA-GM/Master-K PLC</span>
          <span class="card-text-tag">ABB, System 800xA/Compact 800</span>
        </div>
      </div>
    </div>
    <div class="product-card">
      <div class="product-card-img">
        <img class="card-img" src="/img/product/HS/hwsw_img02.jpg" alt="Logo"/>
        <img class="card-img-m" src="/img/product/HS/m/cate_img05_02.jpg" alt="Logo"/>
      </div>
      <div class="product-card-text">
        <span class="card-text-head">
          Control Panel Manufacturing
        </span>
        <div class="card-text-tags">
          <span class="card-text-tag">Control Panel</span>
          <span class="card-text-tag">Marshalling Panel</span>
          <span class="card-text-tag">Auxiliary Panel</span>
          <span class="card-text-tag">System Cabinet and Operation Console Desk</span>
          <span class="card-text-tag">수, 배전 Panel</span>
        </div>
      </div>
    </div>
    <div class="product-card">
      <div class="product-card-img">
        <img class="card-img" src="/img/product/HS/hwsw_img03.jpg" alt="Logo"/>
        <img class="card-img-m" src="/img/product/HS/m/cate_img05_03.jpg" alt="Logo"/>
      </div>
      <div class="product-card-text">
        <span class="card-text-head">
          Instrument & Industrial Device
        </span>
        <div class="card-text-tags">
          <span class="card-text-tag">Tank Gauging Sensor</span>
          <span class="card-text-tag">Flow meter, Control Valve</span>
          <span class="card-text-tag">Temperature, Pressure, Level, Flow Transmitter</span>
          <span class="card-text-tag">Analyzer</span>
          <span class="card-text-tag">Advantech, IEI, Moxa, Hirschmann, Helmholz</span>
        </div>
      </div>
    </div>
    <div class="product-card">
      <div class="product-card-img">
        <img class="card-img" src="/img/product/HS/hwsw_img04.jpg" alt="Logo"/>
        <img class="card-img-m" src="/img/product/HS/m/cate_img05_04.jpg" alt="Logo"/>
      </div>
      <div class="product-card-text">
        <span class="card-text-head">
          HMI (MMI) Package
        </span>
        <div class="card-text-tags">
          <span class="card-text-tag">AVEVA, InTouch</span>
          <span class="card-text-tag">Siemens, WinCC</span>
          <span class="card-text-tag">Rockwell Automation, RSView/FactoryTalk</span>
          <span class="card-text-tag">GE Intelligent Platforms, iFIX</span>
          <span class="card-text-tag">케이디티시스템즈, CIMON</span>
        </div>
      </div>
    </div>
    <div class="product-card">
      <div class="product-card-img">
        <img class="card-img" src="/img/product/HS/hwsw_img05.jpg" alt="Logo"/>
        <img class="card-img-m" src="/img/product/HS/m/cate_img05_05.jpg" alt="Logo"/>
      </div>
      <div class="product-card-text">
        <span class="card-text-head">
          User Define Application Program Service
        </span>
        <span class="card-text-head-sub">
          JAVA 및 C# 등 Language S/W를 이용하여<br/>
          고객의 특별 주문에 맞는 다양한 주문자형 프로그램을 개발/제공하고 있습니다.
        </span>
        <div class="card-text-tags">
          <span class="card-text-tag">FA와 OA간 연계 프로그램 개발</span>
          <span class="card-text-tag">현장 계측장비 등 이기종간 통신 프로그램 개발</span>
          <span class="card-text-tag">Data Logging 프로그램 개발</span>
          <span class="card-text-tag">Data Acquisition 프로그램 개발</span>
          <span class="card-text-tag">SMS 등 모바일 연계 및 앱 프로그램 개발</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {ref} from 'vue';
import {useIntersectionObserver} from '@/utils/useIntersectionObserver';

const firstImage = ref(null);

useIntersectionObserver([firstImage]);
</script>

<style scoped>
.div-head {
  margin: 0 0 2.0833vw 0;
}

.head-text {
  font-weight: bold;
  font-size: 2.5000vw;
}

.head-sub-text {
  font-size: 1.4583vw;
}
.product-card{
  display: flex;
  background-color: #dddddd;
  margin-bottom: 2.6042vw;
}
.product-card-img{
}
.product-card-text{
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 2.6042vw;
}
.card-text-head{
  font-size: 2.5000vw;
  font-weight: bold;
  margin-bottom: 1.0417vw;
}
.card-text-tags{
  display: flex;
  flex-wrap: wrap;
  gap: 0.5208vw;
}
.card-text-tag{
  padding: 0.5208vw 1.0417vw 0.5208vw 1.0417vw;
  background-color: #f5f5f5;
  font-size: 1.2500vw;
  border-radius: 0.5208vw;
}
.hs{
  margin-bottom: 10.4167vw;
}
.card-img-m{
  display:none;
}
.card-text-head-sub{
  font-size: 1.4583vw;
}
@media (max-width: 540px) {
  .first-image{
    display: none;
  }
  .main-container{
    width: 92.5926vw;
  }
  .card-img{
    display:none;
  }
  .card-img-m{
    display:block;
  }
  .head-text{
    display:block;
    font-size: 5.1852vw;
    margin: 9.2593vw 0 3.7037vw 0;
  }
  .head-sub-text{
    display:block;
    font-size: 4.0741vw;
    margin: 0 0 18.5185vw 0;
  }
  .card-text-head{
    font-size: 5.1852vw;
  }
  .card-text-tags{
    gap: 0.9259vw;
  }
  .card-text-tag{
    font-size: 2.5926vw;
    border-radius: 1.8519vw;
    padding: 0.5556vw 1.8519vw 0.5556vw 1.8519vw;
  }
  .card-text-head-sub{
    display: none;
  }
}
</style>