<template>
  <div class="footer-all">
    <div class="footer-container">
      <div class="footer-menu">
        <span class="footer-head">회사소개</span>
        <span class="footer-content" @click="navigateTo('/introduce/about-us')">About Us</span>
        <span class="footer-content" @click="navigateTo('/introduce/greeting')">대표인사말</span>
        <span class="footer-content" @click="navigateTo('/introduce/history')">회사 연혁</span>
        <span class="footer-content" @click="navigateTo('/introduce/ourPeople')">인재상</span>
        <span class="footer-content" @click="navigateTo('/introduce/team-structure')">조직구성</span>
        <span class="footer-content" @click="navigateTo('/introduce/directions')">오시는 길</span>
      </div>
      <div class="footer-menu">
        <span class="footer-head">지속가능경영</span>
        <span class="footer-content" @click="navigateTo('/esg/esg-practice')">가후이엔씨의 ESG</span>
        <span class="footer-content" @click="navigateTo('/esg/environmental')">Environmental</span>
        <span class="footer-content" @click="navigateTo('/esg/social')">Social</span>
        <span class="footer-content" @click="navigateTo('/esg/governance')">Governance</span>
      </div>
      <div class="footer-menu">
        <span class="footer-head">사업분야</span>
        <span class="footer-content" @click="navigateTo('/business/areas')">주요사업영역</span>
        <span class="footer-content" @click="navigateTo('/business/business-introduce')">사업소개</span>
        <span class="footer-content" @click="navigateTo('/business/achievements')">사업실적</span>
        <span class="footer-content" @click="navigateTo('/business/client')">고객사</span>
        <span class="footer-content" @click="navigateTo('/business/partner')">협력사</span>
      </div>
      <div class="footer-menu">
        <span class="footer-head">제품소개</span>
        <span class="footer-content" @click="navigateTo('/products/factory-control')">공장제어</span>
        <span class="footer-content" @click="navigateTo('/products/scada')">SCADA</span>
        <span class="footer-content" @click="navigateTo('/products/hardware-software')">HW & SW</span>
        <span class="footer-content" @click="navigateTo('/products/others')">기타</span>
      </div>
      <div class="footer-menu">
        <span class="footer-head">교육</span>
        <span class="footer-content" @click="goToLink">사이버연수원</span>
      </div>
    </div>
    <!-- 구분선 -->
    <hr class="footer-divider">

    <div class="footer-info">
      <img src="/img/logo/kahu_logo_footer_gray.png" alt="회사 로고" class="footer-logo">
      <div class="contact-info">
        <p>가후이엔씨(주) 대표이사: 서광익 | 사업자등록번호: 206-81-76790</p>
        <p>경기도 성남시 중원구 사기막골로 159 201호</p>
        <p>대표전화: 031) 606-9988 | 대표팩스: 031) 606-9989</p>
        <p></p>
        <p>Copyright Kaku Engineering & Consulting Corp.All rights reserved</p>
      </div>
      <div class="contact-info-m">
        <img src="/img/logo/kahu_type_logo.png" alt="회사 로고" class="footer-logo-m">
        <span class="contact-info-m-body">대표이사: 서광익<br/>
          사업자등록번호: 206-81-76790<br/>
          경기도 성남시 중원구 사기막골로 159 201호<br/>
          대표전화: 031) 606-9988<br/>
          대표팩스: 031) 606-9989<br/>
        </span>
        <span class="contact-info-m-body copyright">
          Copyright Kaku Engineering & Consulting Corp.All rights reserved<br/>
        </span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router';

const router = useRouter();

const navigateTo = (path) => {
  router.push(path);
};
function goToLink() {
  window.open("https://kahuencflex.hunet.co.kr/Home");
}
</script>

<style scoped>
.footer-all{
  background-color: rgba(0, 0, 0, 0.73);
  padding: 0 22%;  /* 왼쪽과 오른쪽에 각각 10%의 패딩 적용 */
  font-size: 0.7813vw;
  color: rgba(248, 248, 255, 0.79);
  margin-top: auto;  /* 다른 컨텐츠가 위에 있을 경우, 풋터가 아래로 밀리도록 설정 */
  width: 100%; /* 가로 전체를 차지 */
}
.footer-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: baseline;
  padding: 0.7813vw 1.0417vw;
}

.footer-menu {
  display: flex;
  flex-direction: column;
}

.footer-head {
  font-weight: bold;
  margin-bottom: 0.4167vw;
}

/* 구분선 스타일 */
.footer-divider {
  width: 100%;
  border: 0;
  height: 0.0521vw;
  background-color: darkgrey;
}

/* 회사 정보 섹션 스타일 */
.footer-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 1.0417vw;
}

.footer-logo {
  width: 13.0208vw;
}

.contact-info {
  text-align: left;
}

.contact-info p {
  margin: 0.2604vw 0;
}
.footer-content{
  display: block;
  margin-bottom: 0.2604vw;
  cursor: pointer;
  color: #ddd;
  transition: color 0.3s ease, text-decoration 0.3s ease;
}
.footer-content:hover {
  color: #fff;
  text-decoration: underline;
}
.contact-info-m{
  display: none;
}
.footer-logo-m{
  display: none;
}
@media (max-width: 540px) {
  .footer-container{
    display: none;
  }
  .footer-divider{
    display: none;
  }
  .footer-logo{
    display: none;
  }
  .contact-info {
    display: none;
  }
  .contact-info-m{
    display: flex;
    flex-direction: column;
    gap: 5.5556vw;
    font-size: 3.7037vw;
  }
  .contact-info-m-head{
    font-weight: bold;
    font-size: 5.5556vw;
  }
  .contact-info-m-body{

  }
  .footer-all{
    padding: 9.2593vw 1.8519vw 9.2593vw 1.8519vw;
  }
  .footer-logo-m{
    display: block;
    width: 64.8148vw;
  }
  .copyright{
    font-size: 2.9630vw;
  }
}
</style>