<script setup>

</script>

<template>
      <span class="content-head">
      가후이엔씨는 어떤 일이든<br/>
      도전하고 주도하는 <br class="m-br" />인재를 기다립니다!
    </span>
  <div class="head-img">
    <img class="head-img-content" src="/img/introduce/people/intro_page05_c1.png" alt="people_img01"/>
    <img class="head-img-content-m" src="/img/introduce/people/m/intro_page05_m_c1.png" alt="people_img01"/>
    <img class="head-img-content" src="/img/introduce/people/intro_page05_c2.png" alt="people_img02"/>
    <img class="head-img-content-m" src="/img/introduce/people/m/intro_page05_m_c2.png" alt="people_img02"/>
    <img class="head-img-content" src="/img/introduce/people/intro_page05_c3.png" alt="people_img03"/>
    <img class="head-img-content-m" src="/img/introduce/people/m/intro_page05_m_c3.png" alt="people_img03"/>
    <img class="head-img-content" src="/img/introduce/people/intro_page05_c4.png" alt="people_img04"/>
    <img class="head-img-content-m" src="/img/introduce/people/m/intro_page05_m_c4.png" alt="people_img04"/>
    <img class="head-img-content" src="/img/introduce/people/intro_page05_c5.png" alt="people_img05"/>
    <img class="head-img-content-m" src="/img/introduce/people/m/intro_page05_m_c5.png" alt="people_img05"/>
    <img class="head-img-content" src="/img/introduce/people/intro_page05_c6.png" alt="people_img06"/>
    <img class="head-img-content-m" src="/img/introduce/people/m/intro_page05_m_c6.png" alt="people_img06"/>
  </div>

  <div class="main-container container-content">
    <div class="" ref="peopleContainer">
      <div class="people-list">
        <div class="people-list-head">
          <img class="people-list-head-img" src="/img/introduce/people/intro_page05_num1.png" alt="Image"  />
          <img class="people-list-head-img-m" src="/img/introduce/people/m/intro_page05_m_num1.png" alt="Image"  />
        </div>
        <span class="people-list-content">
            창의적인 접근과 문제 해결 능력으로 끊임없이 새로운 아이디어를 찾고, 기존 방식을 개선하려는 의지를 가진 인재입니다.
          </span>
      </div>
      <div class="people-list">
        <div class="people-list-head">
          <img class="people-list-head-img" src="/img/introduce/people/intro_page05_num2.png" alt="Image"  />
          <img class="people-list-head-img-m" src="/img/introduce/people/m/intro_page05_m_num2.png" alt="Image"  />
        </div>
        <span class="people-list-content">
            고객의 필요와 요구사항을 깊이 이해하고,
          이를 충족시키기 위해 최선을 다하여 고객과의 관계에서 신뢰와 만족을 구축할 수 있는 인재입니다.
          </span>
      </div>
      <div class="people-list">
        <div class="people-list-head">
          <img class="people-list-head-img" src="/img/introduce/people/intro_page05_num3.png" alt="Image"  />
          <img class="people-list-head-img-m" src="/img/introduce/people/m/intro_page05_m_num3.png" alt="Image"  />
        </div>
        <span class="people-list-content">
            실패를 두려워하지 않고,
          실패를 성장의 기회로 삼아 지속적으로 학습하고 개인의 역량을 개발하는 데 전념하는 인재입니다.
          </span>
      </div>
      <div class="people-list">
        <div class="people-list-head">
          <img class="people-list-head-img" src="/img/introduce/people/intro_page05_num4.png" alt="Image"  />
          <img class="people-list-head-img-m" src="/img/introduce/people/m/intro_page05_m_num4.png" alt="Image"  />
        </div>
        <span class="people-list-content">
            모든 업무에서 정직하고 투명한 행동을 기반으로 하며,
          자신의 결정과 행동에 책임을 지며 이해관계자들과 신뢰를 구축하고 유지할 수 있는 인재입니다.
          </span>
      </div>
      <div class="people-list">
        <div class="people-list-head">
          <img class="people-list-head-img" src="/img/introduce/people/intro_page05_num5.png" alt="Image"  />
          <img class="people-list-head-img-m" src="/img/introduce/people/m/intro_page05_m_num5.png" alt="Image"  />
        </div>
        <span class="people-list-content">
            사회와 환경에 긍정적인 영향을 미치기 위해 노력하며, 지속 가능한 개발 목표를 실천하는 인재입니다.
          </span>
      </div>
      <div class="people-list">
        <div class="people-list-head">
          <img class="people-list-head-img" src="/img/introduce/people/intro_page05_num6.png" alt="Image"  />
          <img class="people-list-head-img-m" src="/img/introduce/people/m/intro_page05_m_num6.png" alt="Image"  />
        </div>
        <span class="people-list-content">
            다양한 배경과 전문성을 가진 팀원들과 효과적으로 협력하여,
          공동의 목표를 달성할 수 있는 탁월한 팀워크 능력을 지닌 인재입니다.
          </span>
      </div>
    </div>
  </div>
  <div>

  </div>
</template>

<style scoped>
.container-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15.6250vw;
  margin-top: 5.2083vw;
}

.content-head {
  width:78.1250vw;
  margin: 0 auto;
  display: block;
  padding: 5.2083vw 0 5.2083vw 0;
  font-size: 2.9167vw;
  font-weight: bold;
}

.head-img{
  width: 100%;
  background-image: url('/img/introduce/people/intro_page05_bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  pointer-events: none; /* 이미지에 대한 마우스 이벤트 방지 */
  display: flex;
  align-items: center;
  justify-content: center;
  gap:2.6042vw;
  padding: 10.4167vw 0 10.4167vw 0;
}
.head-img-content{
  width: 9.8958vw;
}
.head-img-content-m{
  display: none;
}
.people-list-head-img{
  width: 78.1250vw;
}
.people-list-head-img-m{
  display: none;
}
.people-list{
  text-align: left;
  margin: 3.1250vw 0 0 0;
}
.people-list-head{
  display: flex;
  align-items: flex-end;
  gap:1.0417vw;
}
.people-list-content{
  display: block;
  margin:1.5625vw 6.2500vw 0 6.2500vw;
  font-size: 1.6667vw;
  line-height: 1.5; /* 기본 여백 제거 */
}
.m-br{
  display: none;
}
@media (max-width: 540px) {
  .content-head {
    padding: 9.2593vw 0 9.2593vw 0;
    font-size: 5.9259vw;
    font-weight: bold;
    text-align: center;
  }
  .m-br{
    display: block;
  }
  .head-img{
    background-image: url('/img/introduce/people/m/intro_page05_m_bg.png');
    gap:2.6042vw;
    padding: 5.5556vw 18.5185vw 5.5556vw 18.5185vw;
    flex-wrap: wrap;
  }
  .head-img-content{
    display: none;
  }
  .head-img-content-m{
    width: 18.5185vw;
    display: block;
  }
  .people-list-head-img-m{
    width: 83.3333vw;
    display: block;
  }
  .people-list-head-img{
    display: none;
  }
  .people-list-content{
    display: block;
    margin:1.5625vw 0 0 0;
    font-size: 3.7037vw;
    line-height: 1.5; /* 기본 여백 제거 */
  }
}
</style>