<script setup>

</script>

<template>
  <div class="esg-social-container">
    <div class="content-block content-block-m">
      <p class="esg-head">사회</p>
      <p class="esg-head-sub">Social</p>
      <p class="esg-head-content">가후이엔씨는 환경, 사회, 지배구조 측면에서 기업의 사회적 책임을 다하기 위하여 지속가능한 근무환경과 문화를 만들기 위해 노력합니다.</p>
      <img src="/img/esg/mid_banner.jpg" alt="Image" class="esg-img"/>
    </div>
  </div>
  <img src="/img/esg/m/m_esg_img_03.jpg" alt="Image" class="esg-img-m"/>
  <div class="esg-social-container esg-social-container-m">
    <div class="content-block">
      <div class="esg-content-title-container">
        <p class="content-title">안전보건관리</p>
        <p class="content-title-sub">안전보건 교육 및 훈련을 통해 임직원의 안전보건 인식을 제고하고, 안전을 중시하는 문화를 형성합니다.</p>
      </div>
      <div class="esg-content-container">
        <p class="esg-content">1) 임직원이 안전하고 쾌적하게 일할 수 있는 작업환경 및 근무환경을 조성합니다.</p>
        <p class="esg-content">2) 안전보건 관련 법규 및 안전보건관리규정을 명확히 인지하고 철저히 준수합니다.</p>
        <p class="esg-content">3) 안전보건경영체계를 구축하여 사전에 유해위험요인을 파악 및 제거함으로써 기업의 모든 활동영역에서 안전사고 또는 재해가 발생하지 않도록 노력합니다.</p>
        <p class="esg-content">4) 안전보건 교육 및 훈련을 통해 임직원의 안전보건 인식을 제고하고 안전을 중시하는 문화를 형성합니다.</p>
        <p class="esg-content">5) 임직원 및 기타 이해관계자의 의견을 적극 수렴하여 현장 중심의 안전보건 활동을 수립 및 시행한다.</p>
        <p class="esg-content">6) 안전사고 또는 재해 발생 시 피해를 최소화를 위해 비상대응체계를 마련합니다.</p>
      </div>
    </div>
    <div class="content-block">
      <div class="esg-content-title-container">
        <p class="content-title">인권경영규정</p>
        <p class="content-title-sub">임직원 및 이해관계자의 인권을 보호하고 증진시키기 위해 인권법을 국제 표준 및 국내외 가이드라인을 준수합니다.</p>
      </div>
      <div class="esg-content-container">
        <p class="esg-content">1) 차별금지:</p>
        <p class="esg-content">- 고용, 업무수행, 승진, 인센티브 지급, 교육기회 등에서 성별, 임신여부, 국적,지역,인종,종교,정치성향, 피부색 등의 이유로 차별하지 않습니다.</p>
        <p class="esg-content">- 성과에 대하여 공정하게 평가하고 보상하는 체계를 갖춥니다.</p>
        <br/>
        <p class="esg-content">2) 근로조건 준수:</p>
        <p class="esg-content">- 회사는 법정 근로시간을 준수하고 해외 사업장이 있는 경우 국가별 법규를 준수합니다.</p>
        <p class="esg-content">- 임직원에 대하여 법에서 정한 최저임금 이상의 보수를 지급하며, 급여명세서와 함께 합당한 보수를 지급합니다.</p>
        <p class="esg-content">- 회사는 임직원의 초과근무에 대하여 법규에서 정하는 기준에 따라 초과근로수당을 지급합니다.</p>
        <br/>
        <p class="esg-content">3) 인도적 대우:</p>
        <p class="esg-content">- 모든 임직원의 사생활을 존중하며 정신적·육체적 강압, 학대, 협박이나 감금 등 불합리한 대우를 금지합니다.</p>
        <p class="esg-content">- 회사는 임직원의 인격권,건강권 및 휴식권을 보장하고 건전한 노동환경을 조성합니다.</p>
        <p class="esg-content">- 직장 내 언어적 · 신체적 폭력 및 괴롭힘이나 성희롱 등의 행위를 금지하며, 위반 시 징계 및 피해자 보호 절차를 마련합니다.</p>
        <br/>
        <p class="esg-content">4) 강제노동 및 아동노동 금지</p>
        <p class="esg-content">- 인신매매를 포함하여 근로자의 자유의사에 어긋나는 강제노동을 금지합니다.</p>
        <p class="esg-content">- 회사는 근로자에게 신분증이나 여권 등의 양도를 요구하지 않습니다.</p>
        <p class="esg-content">- 15세 미만의 아동은 고용하지 않습니다.</p>
        <p class="esg-content">- 15세 이상, 18세 미만의 연소자의 경우 법규에 따라 교육권을 보장하고 야간근무나 초과근무를 포함하여 안전이나 건강을 위협하는 업무에 투입해서는
          안됩니다.</p>
        <br/>
        <p class="esg-content">5) 노동자의 권리 보장</p>
        <p class="esg-content">- 회사는 노동자의 결사의 자유와 단체교섭의 자유를 보장합니다.</p>
        <p class="esg-content">- 근로자는 자유롭게 노동조합의 결성이 허용되며, 노동조합에 가입 또는 활동에 대하여 불이익을 받지 않습니다.</p>
        <br/>
        <p class="esg-content">6) 산업,안전보건 보장</p>
        <p class="esg-content">- 회사는 안전하고 위생적인 근로환경을 제공하며, 사업장의 시설과 장비 도구 등의 정기적인 점검을 통해 안전사고를 예방합니다.</p>
        <p class="esg-content">- 회사는 업무 상 발생한 사고에 대하여 적절한 보상방안을 마련합니다.</p>
        <br/>
        <p class="esg-content">7) 고객의 인권 보호</p>
        <p class="esg-content">- 회사는 제품이나 서비스를 제공시 고객의 안전,생명,건강, 재산 등의 보호를 위해 노력합니다.</p>
        <p class="esg-content">- 회사는 개인정보 보호법 등 개인정보 관련 법률을 준수하고 고객의 개인정보를 보호합니다.</p>
        <br/>
        <p class="esg-content">8) 지역주민 인권 보호</p>
        <p class="esg-content">- 모든 임직원은 업무 수행 시 지역주민의 인권이 침해되지 않도록 유의하며, 지역주민의 안전보건에 대한 권리, 지적재산권, 거주의 자유를 보호합니다.</p>
        <br/>
        <p class="esg-content">9) 신고제도</p>
        <p class="esg-content">-임직원은 누구라도 신고자라는 사정을 알면서도 그 인적 사항 또는 신고자임을 미루어 알 수 있는 사실을 다른 사람에게 암시하고나 공개 또는 보도하여서는
          안됩니다.</p>
        <p class="esg-content">- 임직원은 누구든지 신고 등의 이유로 신고자에게 부당한 불이익조치를 해서는 안됩니다.</p>
        <p class="esg-content">- 신고자 등은 신고 등을 이유로 불이익조치를 받은 때에는 원상회복이나 그 밖에 필요한 조치를 신청할 수 있습니다.</p>
      </div>
    </div>
    <div class="content-block">
      <div class="esg-content-title-container">
        <p class="content-title">취업규칙</p>
        <p class="content-title-sub">ESG경영에 만족하는 취업규칙을 도입하여 회사와 직원간의 신뢰를 형성합니다.</p>
      </div>
      <div class="esg-content-container">
        <p class="esg-content">1) 이 규정은 가후이엔씨 사원의 채용과 복무 및 근로조건 등에 관한 사항을 정함을 목적으로 합니다.</p>
        <p class="esg-content">2) 이 규칙은 회사에 근무하는 사원에게 적용됩니다.</p>
        <p class="esg-content">3) 이 규칙에서 '사원'이라 함은 본 규칙에서 정한 바에 따라 채용된 자를 의미합니다.</p>
        <p class="esg-content">4) 회사는 사원의 채용, 임금, 복리후생, 교육훈련, 배치·전보·승진, 퇴직·해고·정년에 있어서 합리적 이유없이 성별, 연령, 신앙, 사회적 신분, 출신지역,
          학력, 출신학교, 혼인 임신 출산 또는 병력 등을 이유로 차별하지 않습니다.</p>
        <p class="esg-content">5) 회사는 정당한 사유없이 사원의 집회 및 결사의 자유를 금지할 수 없습니다.</p>
        <p class="esg-content">6) 회사는 집회 및 결사를 실시한 사원에게 업무상 불리한 조치를 취하거나 개인의 권리를 제한하는 직접적 또는 간접적 조치를 취해서는 안됩니다.</p>
      </div>
    </div>
    <div class="content-block">
      <div class="esg-content-title-container">
        <p class="content-title">행동규범</p>
        <p class="content-title-sub">근로자들의 기본적인 인권을 보호하고 존중하기 위한 인사정책의 운영을 위해 행동규범을 정합니다.</p>
      </div>
      <div class="esg-content-container">
        <p class="esg-content">1) 총칙</p>
        <p class="esg-content">목적: 본 규정은 가후이엔씨(주)의 근로자의 인권 보호와 관련된 인사정책 운영을 목적으로 함.</p>
        <p class="esg-content">적용범위: 회사 및 1차 협력업체에 적용.</p>
        <br/>
        <p class="esg-content">2) 기업의 사회적 책임에 따른 인사정책</p>
        <p class="esg-content">자발적 취업: 강제 근로 금지, 근로자 자발적 퇴직 보장, 신분증 및 여권 등의 요구 금지.</p>
        <p class="esg-content">아동 노동 금지: 법적 연령 이하 근로자 고용 금지 및 확인 절차 마련.</p>
        <p class="esg-content">근로시간 준수: 법정 최대 근로시간 준수.</p>
        <p class="esg-content">임금과 복리후생: 최저임금 준수, 초과근무 수당 지급, 감금 징계 금지.</p>
        <p class="esg-content">인도적 대우: 성희롱, 학대, 체벌 금지, 근로자 기본 자유 보장.</p>
        <p class="esg-content">차별금지: 인종, 성별 등 불법적 차별 금지.</p>
        <p class="esg-content">단결의 자유: 근로자의 결사 및 노동조합 참여 자유 보장.</p>
        <br/>
        <p class="esg-content">3) 관리체계</p>
        <p class="esg-content">법규 및 고객 요구사항 파악: 관련 법령 및 고객 요구사항 정기 모니터링.</p>
        <p class="esg-content">리스크 평가 및 관리: 노동 관행 리스크 평가 및 통제.</p>
        <p class="esg-content">협력업체 준수 관리: 협력업체의 준수 여부 평가 및 관리.</p>
        <p class="esg-content">지속적인 개선: 규정 이행 및 개선 촉진.</p>
        <br/>
        <p class="esg-content">4) 윤리 정책</p>
        <p class="esg-content">청렴성: 모든 거래에서 최고 수준의 청렴성 유지, 반부패 모니터링.</p>
        <p class="esg-content">부당이익 금지: 뇌물 수수 및 부당이익 행위 금지.</p>
        <p class="esg-content">정보 공개: 법규에 따른 정보 공개 및 기밀 유지.</p>
      </div>
    </div>
  </div>
</template>

<style scoped>
.esg-head {
  font-weight: bold;
  font-size: 2.2917vw;
  margin-bottom: 0.0000vw;
}

.esg-head-sub {
  font-weight: bold;
  font-size: 1.5625vw;
  margin-top: 0.0000vw;
  color: #cccccc;
}

.esg-head-content {
  font-size: 1.4583vw;
}

.esg-social-container {
  width: 78.1250vw;
  margin: 0.0000vw auto;
}

.content-block {
  margin-bottom: 10%;
  margin-top: 5%;
}

.esg-content-title-container {
  display: flex;
  align-items: center;
}

.content-title {
  padding: 0.5208vw 0.0000vw 0.5208vw 2.6042vw;
  font-size: 2.7083vw;
  font-weight: bold;
  width: 35%;
}

.content-title-sub {
  font-size: 1.4583vw;
  color: #565656;
  width: 60%;
}

.esg-content-container {
  background-color: #dddddd;
  padding: 1.5625vw 0.0000vw 1.5625vw 0.0000vw;
}

.esg-content {
  width: 90%;
  margin: 0.0000vw auto;
  font-size: 1.2500vw;
  padding-bottom: 0.7813vw;
}

.esg-img {
  width: 100%;
}

.esg-img-m {
  display: none;
}

@media (max-width: 540px) {
  .esg-img {
    display: none;
  }

  .esg-img-m {
    display: block;
    width: 100%;
  }

  .esg-head {
    font-size: 6.6667vw;
    margin: 0 2.7778vw 0 0;
  }

  .esg-head-sub {
    font-size: 5.1852vw;
    margin: 0;
  }

  .esg-head-content {
    font-size: 4.4444vw;
    word-break: keep-all; /* 단어 단위로 줄바꿈이 발생하도록 */
    white-space: normal; /* 기본 줄바꿈 동작 유지 */
  }

  .content-block-m {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
  }
  .esg-social-container-m{
    width: 100%;
  }
  .esg-content-title-container{
    flex-direction: column;
    align-items: baseline;
  }
  .content-title{
    font-size: 5.5556vw;
    margin: 0;
    padding: 0 0 0 5.5556vw;
  }
  .content-title-sub{
    font-size: 4.0741vw;
    width: 100%;
    padding: 0 5.5556vw 0 5.5556vw;
    word-break: keep-all; /* 단어 단위로 줄바꿈이 발생하도록 */
    white-space: normal; /* 기본 줄바꿈 동작 유지 */
  }
  .esg-content{
    font-size: 4.0741vw;
  }
}
</style>