<script setup>

</script>

<template>
  <div class="esg-practice-container">
    <div class="esg-first-text">
      <p class="esg-head">ESG(지속가능경영)란?</p>
      <p class="esg-head-content">ESG는 환경, 사회, 지배구조 측면에서 <br/>
        중장기 기업가치에 직·간접적으로 큰 영향을 미치는 비재무적 성과 지표입니다.</p>
      <p class="esg-head-content-sub">
        ESG는 Environmental(환경), Social(사회), Governance(지배구조)의 첫 글자를 조합한 단어로 기업의 친환경 경영, 사회적 책임, 투명한 지배구조 등을
        의미합니다.<br/><br/>
        실질적인 ESG는 기업이 ‘지속 가능한’ 비즈니스를 달성하기 위한 세 가지 핵심 요소로, 재무제표에는 직접적으로 보이지 않아도 기업의 중장기 기업가치에 막대한 영향을 주는 비재무적 지표로 정의할 수
        있습니다. <br/><br/>
        ESG의 숨은 핵심 키워드는 바로 기업의 지속가능성, 기업가치, 그리고 비재무적 성과 지표입니다.<br/></p>
    </div>
    <img src="/img/esg/esg_img.png" alt="Image" class="esg-img"/>
    <img src="/img/esg/bg_mid_img.jpg" alt="Image" class="esg-img"/>
  </div>
  <img src="/img/esg/m/m_esg_img.png" alt="Image" class="esg-img-m"/>
  <div class="esg-practice-container">

    <p class="esg-middle">
      가후이엔씨는 자동화 분야라면 누구에게도 뒤지지 않는<br/>
      경험과 기술력을 바탕으로 최고의 자동화 서비스를 제공합니다.<br/>
      뿐만 아니라 앞으로도 계속해서 자동화 분야에 있어서<br/>
      지속 가능한 미래를 위해 ESG 경영을 실천하고자 합니다.<br/>
    </p>
    <p class="esg-middle-m">
      가후이엔씨는 자동화 분야라면 <br/>
      누구에게도 뒤지지 않는 <br/>
      경험과 기술력을 바탕으로 <br/>
      최고의 자동화 서비스를 제공합니다.<br/><br/>
      뿐만 아니라 앞으로도 계속해서 <br/>
      자동화 분야에 있어서<br/>
      지속 가능한 미래를 위해 <br/>
      ESG 경영을 실천하고자 합니다.<br/>
    </p>
    <div style="margin-bottom: 20%;">
      <p class="esg-text-last">환경, 사회, 지배구조 측면에서 기업의 사회적 책임을 다하기 위하여 관련 법규를 준수하고, 지역사회 구성원으로서 사회에 기여하고자 합니다!</p>
      <p class="esg-text-last">ESG 경영 추진을 위하여 환경,사회,지배구조 영역에서 유관 법규를 준수하는 한편, 더 나아가 사회에 기여하기 위하여 다음과 같은 사항을 실천합니다!</p>
      <p class="esg-text-last">자사의 모든 임직원을 포함하여 다양한 이해관계자의 기대에 부응하고, 당사의 지속가능한 성장을 추진하기 위하여 ESG 경영을 추진합니다.</p>
      <p class="esg-text-last">책임있는 기업시민으로서 자사의 역할이 중요함을 인식하고 있으며, 다양한 이해관계자의 요청사항에 귀를 기울이고, 사회적인 신뢰를 쌓기 위하여 노력합니다!</p>
      <p class="esg-text-last">기업 운영 전반/비지니스 운영 전반에 ESG 경영의 도입을 고려하고 지속가능한 기업이 되도록 노력합니다!</p>
  </div>
  </div>
  <div>
    <p class="esg-text-last-m">환경, 사회, 지배구조 측면에서 기업의 사회적 책임을 다하기 위하여 관련 법규를 준수하고, 지역사회 구성원으로서 사회에 기여하고자 합니다!</p>
    <p class="esg-text-last-m">ESG 경영 추진을 위하여 환경,사회,지배구조 영역에서 유관 법규를 준수하는 한편, 더 나아가 사회에 기여하기 위하여 다음과 같은 사항을 실천합니다!</p>
    <p class="esg-text-last-m">자사의 모든 임직원을 포함하여 다양한 이해관계자의 기대에 부응하고, 당사의 지속가능한 성장을 추진하기 위하여 ESG 경영을 추진합니다.</p>
    <p class="esg-text-last-m">책임있는 기업시민으로서 자사의 역할이 중요함을 인식하고 있으며, 다양한 이해관계자의 요청사항에 귀를 기울이고, 사회적인 신뢰를 쌓기 위하여 노력합니다!</p>
    <p class="esg-text-last-m">기업 운영 전반/비지니스 운영 전반에 ESG 경영의 도입을 고려하고 지속가능한 기업이 되도록 노력합니다!</p>
  </div>
</template>

<style scoped>
.esg-practice-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 78.1250vw;
  margin: 0.0000vw auto;
}

.esg-first-text {
  margin-bottom: 10%;
}

.esg-text-last {
  background-color: #dddddd;
  font-size: 1.2500vw;
  padding: 2% 10% 2% 10%;
  border-radius: 1.0417vw;
}

.esg-img {
  width: 100%;
}

.esg-head {
  font-weight: bold;
  font-size: 2.9167vw;
}

.esg-head-content {
  font-size: 1.8750vw;
}

.esg-head-content-sub {
  font-size: 1.2500vw;
}

.esg-middle {
  font-weight: bold;
  font-size: 1.8750vw;
  text-align: center;
  margin: 10% 0 10% 0
}
.esg-middle-m{
  display:none;
}
.esg-img-m {
  display: none;
}
.esg-text-last-m{
  display: none;
}
@media (max-width: 540px) {
  .esg-img {
    display: none;
  }

  .esg-img-m {
    display: block;
    width: 100%;
  }

  .esg-head {
    font-weight: bold;
    font-size: 7.4074vw;
  }

  .esg-head-content {
    font-size: 4.4444vw;
  }

  .esg-head-content-sub {
    font-size: 3.1481vw;
  }
  .esg-middle{
    display:none;
  }
  .esg-middle-m {
    display:block;
    font-weight: bold;
    font-size: 5.5556vw;
    text-align: center;
    margin: 10% 0 10% 0
  }
  .esg-text-last{
    display:none;
  }
  .esg-text-last-m{
    display:block;
    background-color: #dddddd;
    font-size: 3.7037vw;
    padding: 3.7037vw 10% 3.7037vw 10%;
    border-radius: 1.0417vw;
  }
}
</style>