// introduce.js
import AboutUs from "@/views/introduce/AboutUs.vue";
import Greeting from "@/views/introduce/Greeting.vue";
import OurPeople from "@/views/introduce/OurPeople.vue";
import History from "@/views/introduce/History.vue";
import TeamStructure from "@/views/introduce/TeamStructure.vue";
import Directions from "@/views/introduce/Directions.vue";

const introduceRoutes = [
    {
        path: "about-us",
        component: AboutUs,
        meta: { main: '회사소개', sub: 'About Us' }
    },
    {
        path: "greeting",
        component: Greeting,
        meta: { main: '회사소개', sub: '대표인사말' }
    },
    {
        path: "ourPeople",
        component: OurPeople,
        meta: { main: '회사소개', sub: '인재상' }
    },
    {
        path: "history",
        component: History,
        meta: { main: '회사소개', sub: '회사 연혁' }
    },
    {
        path: "team-structure",
        component: TeamStructure,
        meta: { main: '회사소개', sub: '조직구성' }
    },
    {
        path: "directions",
        component: Directions,
        meta: { main: '회사소개', sub: '오시는 길' }
    }
];

export default introduceRoutes;
