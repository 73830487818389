<template>
  <img
    ref="firstImage"
    class="first-img fade-in-up"
    src="/img/public_img/bg_02_img.png"
    alt="Background Image"
  />
  <div class="card">
      <!-- <TabList>
        <Tab value="0">
          <Button
            @click="handleTabClick('0')"
            :severity="isClicked === '0' ? 'danger' : 'secondary'"
            class="tab-button"
            rounded
          >
            2020 ~ 현재
          </Button>
        </Tab>
        <Tab value="1">
          <Button
            @click="handleTabClick('1')"
            :severity="isClicked === '1' ? 'danger' : 'secondary'"
            class="tab-button"
            rounded
          >
            2010 ~ 2019
          </Button>
        </Tab>
        <Tab value="2">
          <Button
            @click="handleTabClick('2')"
            :severity="isClicked === '2' ? 'danger' : 'secondary'"
            class="tab-button"
            rounded
          >
            2002 ~ 2009
          </Button>
        </Tab>
      </TabList> -->
      <div class="tab-panel">
        <div >
          <div class="history-item">
            <p class="history-year">2023</p>
            <span class="dot">
              <img
                class="dot-img"
                src="/img/introduce/obj.png"
                alt="arrow Image"
            /></span>
            <div class="history-content">
              <div class="history-entry">
                <span class="history-month">12</span>
                <p class="description">
                  2023.12.31 기준 출하자동화 프로그램 외,
                  46개 한국저작권위원회 인증
                </p>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div class="history-item">
            <p class="history-year">2017</p>
            <span class="dot">
              <img
                class="dot-img"
                src="/img/introduce/obj.png"
                alt="arrow Image"
            /></span>
            <div class="history-content">
              <div class="history-entry">
                <span class="history-month">11</span>
                <p class="description">상표등록</p>
              </div>
            </div>
          </div>

          <div class="history-item">
            <p class="history-year">2016</p>
            <span class="dot">
              <img
                class="dot-img"
                src="/img/introduce/obj.png"
                alt="arrow Image"
            /></span>
            <div class="history-content">
              <div class="history-entry">
                <span class="history-month">02</span>
                <p class="description">
                  본점을 경기도 성남시 중원구 사기막골로 159, 201호로 이전
                </p>
              </div>
            </div>
          </div>

          <div class="history-item">
            <p class="history-year">2013</p>
            <span class="dot">
              <img
                class="dot-img"
                src="/img/introduce/obj.png"
                alt="arrow Image"
              />
            </span>
            <div class="history-content">
              <div class="history-entry">
                <span class="history-month">04</span>
                <p class="description">CC-Link – Partner Association</p>
              </div>
            </div>
          </div>
          <div class="history-item">
            <p class="history-year">2011</p>
            <span class="dot">
              <img
                class="dot-img"
                src="/img/introduce/obj.png"
                alt="arrow Image"
              />
            </span>
            <div class="history-content">
              <div class="history-entry">
                <span class="history-month">08</span>
                <p class="description">Schneider Electric – Certificate</p>
              </div>
            </div>
          </div>
          <div class="history-item">
            <p class="history-year">2010</p>
            <span class="dot">
              <img
                class="dot-img"
                src="/img/introduce/obj.png"
                alt="arrow Image"
              />
            </span>
            <div class="history-content">
              <div class="history-entry">
                <span class="history-month">01</span>
                <p class="description">기술혁신형 중소기업 인증 [INNOBIZ]</p>
              </div>
            </div>
          </div>
        </div>
        <div >
          <div class="history-item">
            <p class="history-year">2009</p>
            <span class="dot">
              <img
                class="dot-img"
                src="/img/introduce/obj.png"
                alt="arrow Image"
              />
            </span>
            <div class="history-content">
              <div class="history-entry">
                <span class="history-month">09</span>
                <p class="description">
                  기업부설 연구소 설립/인증 (한국산업기술진흥협회)
                </p>
              </div>
              <div class="history-entry">
                <span class="history-month-hidden">09</span>
                <p class="description">
                  품질경영시스템 인증(KS Q/ISO 9001:2008)
                </p>
              </div>
            </div>
          </div>

          <div class="history-item">
            <p class="history-year">2008</p>
            <span class="dot">
              <img
                class="dot-img"
                src="/img/introduce/obj.png"
                alt="arrow Image"
              />
            </span>
            <div class="history-content">
              <div class="history-entry">
                <span class="history-month">06</span>
                <p class="description">ABB-Certified Premier Partner</p>
              </div>

              <div class="history-entry">
                <span class="history-month">05</span>
                <p class="description">
                  새 정부와 함께하는 미래를 여는 핵심기업 선정, 헤럴드 경제
                </p>
              </div>

              <div class="history-entry">
                <span class="history-month">04</span>
                <p class="description">납입자본금을 100백만원으로 증자</p>
              </div>

              <div class="history-entry">
                <span class="history-month">01</span>
                <p class="description">
                  ABB Korea, Automation Solution Provider 체결
                </p>
              </div>
            </div>
          </div>

          <div class="history-item">
            <p class="history-year">2007</p>
            <span class="dot">
              <img
                class="dot-img"
                src="/img/introduce/obj.png"
                alt="arrow Image"
              />
            </span>
            <div class="history-content">
              <div class="history-entry">
                <span class="history-month">12</span>
                <p class="description">
                  한국마이크로소프트, 2008 Solution Builder Program (ISV) 선정
                </p>
              </div>

              <div class="history-entry">
                <span class="history-month-hidden">12</span>
                <p class="description">MS, 2008 Solution Builder ISV 선정</p>
              </div>

              <div class="history-entry">
                <span class="history-month-hidden">12</span>
                <p class="description">
                  S-OIL, 한국시멘트(주) 등 12개 기업의 생산자동화 시스템 개발 및
                  유지보수
                </p>
              </div>

              <div class="history-entry">
                <span class="history-month">03</span>
                <p class="description">제조 외 사업종목 추가</p>
              </div>

              <div class="history-entry">
                <span class="history-month-hidden">03</span>
                <p class="description">
                  본점을 서울시 광진구 화양동 110-37 화양타워 617호에서 323로
                  이전
                </p>
              </div>

              <div class="history-entry">
                <span class="history-month">01</span>
                <p class="description">Wonderware-Certification</p>
              </div>
            </div>
          </div>

          <div class="history-item">
            <p class="history-year">2006</p>
            <span class="dot">
              <img
                class="dot-img"
                src="/img/introduce/obj.png"
                alt="arrow Image"
              />
            </span>
            <div class="history-content">
              <div class="history-entry">
                <span class="history-month">07</span>
                <p class="description">
                  한국소프트웨어산업협회, 소프트웨어사업자 신고
                </p>
              </div>

              <div class="history-entry">
                <span class="history-month-hidden">07</span>
                <p class="description">
                  소프트웨어사업자 신고(한국소프트웨어산업협회)
                </p>
              </div>
            </div>
          </div>

          <div class="history-item">
            <p class="history-year">2004</p>
            <span class="dot">
              <img
                class="dot-img"
                src="/img/introduce/obj.png"
                alt="arrow Image"
              />
            </span>
            <div class="history-content">
              <div class="history-entry">
                <span class="history-month">01</span>
                <p class="description">
                  Advantech Technologies, eAutomation Solution Partner 체결
                </p>
              </div>
            </div>
          </div>

          <div class="history-item">
            <p class="history-year">2003</p>
            <span class="dot">
              <img
                class="dot-img"
                src="/img/introduce/obj.png"
                alt="arrow Image"
              />
            </span>
            <div class="history-content">
              <div class="history-entry">
                <span class="history-month">01</span>
                <p class="description">Wonderware, Sub Distributor 체결</p>
              </div>
            </div>
          </div>

          <div class="history-item">
            <p class="history-year">2002</p>
            <span class="dot">
              <img
                class="dot-img"
                src="/img/introduce/obj.png"
                alt="arrow Image"
              />
            </span>
            <div class="history-content">
              <div class="history-entry">
                <span class="history-month">12</span>
                <p class="description">
                  Schneider Electric, System Integrator 체결
                </p>
              </div>
              <div class="history-entry">
                <span class="history-month-hidden">12</span>
                <p class="description">
                  소프트웨어개발 및 컨설팅 등을 목적으로 대표이사 서광익에 의해
                  가후이엔씨(주) 설립
                </p>
              </div>
              <div class="history-entry">
                <span class="history-month-hidden">12</span>
                <p class="description">쌍용정보통신(주) 자동화사업부 분사</p>
              </div>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>

<script setup>
//import Tabs from "primevue/tabs";
//import TabList from "primevue/tablist";
//import Tab from "primevue/tab";
import TabPanels from "primevue/tabpanels";
import TabPanel from "primevue/tabpanel";
import Button from "primevue/button";
import Chip from "primevue/chip";
import { ref, onMounted } from "vue";
import { useIntersectionObserver } from "@/utils/useIntersectionObserver";

const firstImage = ref(null);

useIntersectionObserver([firstImage]);

//const isClicked = ref("0");

// const handleTabClick = (value) => {
//   isClicked.value = value;
// };
</script>

<style scoped>
.first-img {
  width: 99vw;
  margin: 0 auto;
}
p {
  margin: 0px;
}
.card {
  display: flex;
  justify-content: center;
  margin-bottom: 20%;
}
.p-tab {
  background-color: white;
  padding: 0px;
  margin: 2.0833vw 2.0833vw 2.0833vw 2.0833vw;
}
.p-tab-active {
  border-color: white;
  background: white;
}
.tab-button {
  width: 14.1667vw;
  height: 4.1667vw;
  font-size: 1.6667vw;
  font-weight: bold;
}
.history-item {
  display: flex;
  align-items: flex-start;
  color: #555;
  margin-bottom: 5%;
  margin-top: 3%;
}

.history-year {
  font-size: 3.75vw;
  margin-right: 0.5208vw;
  width: 20%;
}

.dot {
  border-radius: 50%;
  margin-right: 1.0417vw;
  margin-top: 0.9375vw;
}

.history-content {
  display: flex;
  align-items: baseline;
  flex-direction: column;
  width: 60%;
}
.history-entry {
  display: flex;
  align-items: center;
  width: 100%;
}
.history-month {
  font-size: 2.4vw;
  font-weight: bold;
  margin-right: 0.5208vw;
  width: 10%;
  text-align: center;
  vertical-align: middle;
}
.history-month-hidden {
  font-size: 2.1875vw;
  font-weight: bold;
  margin-right: 0.5208vw;
  visibility: hidden;
  width: 10%;
}
.description {
  font-size: 1.4583vw;
  color: #000000;
  margin: 0;
  background-color: #dddddd;
  padding: 0.8vw;
  border-radius: 0.2604vw;
  width: 80%;
}

.p-tablist {
  --p-tabs-tab-hover-background: white;
}
@media (max-width: 540px) {
  .first-img {
    display: none;
  }
  .history-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: #555;
    margin-bottom: 5%;
    margin-top: 3%;

    /* margin-left: auto !important;
    margin-right: auto !important; */
  }
  .dot {
    width: 92.5926vw;
    display: inline-block; /* 텍스트 너비에 맞추기 위해 인라인 블록 요소로 설정 */
    border-bottom: 1px solid #555; /* 원하는 두께와 색상으로 밑줄 추가 */
    margin-left: auto;
    margin-right: auto;
  }
  .dot-img {
    display: none;
  }
  .history-year {
    font-size: 8.8889vw;
    font-weight: normal;
    margin-left: 5%;
  }
  .history-entry {
    display: flex;
    align-items: center;
    width: 92.5926vw;
    margin: 5px 0 5px 0;
  }
  .history-month {
    width: 20%;
    font-size: 6.6667vw;
    margin: 0;
  }
  .history-month-hidden {
    width: 20%;
    margin: 0;
  }
  .description {
    width: 80%;
    font-size: 5.1852vw;
  }
  .tab-button {
    width: 25.1852vw;
    height: 7.4074vw;
    font-size: 2.963vw;
    font-weight: bold;
  }
  .p-tab {
    background-color: white;
    padding: 0px;
    margin: 3.7037vw 3.7037vw 3.7037vw 3.7037vw;
  }
  .tab-panel {
    width: 100vw;
  }
}
</style>
