<script setup>

</script>

<template>
  <div class="esg-environmental-container">
    <div class="content-block content-block-m">
      <p class="esg-head" >환경정책</p>
      <p class="esg-head-sub">ENVIRONMENTAL</p>
      <p class="esg-head-content">가후이엔씨의 경영이념을 구체적으로 실현하는 환경경영 체제를 구축하고 환경리스크 대응 역량을 강화하여 이해관계자와 협력하여 환경보호 및 기후 위기 극복에
        기여하는 것을 목적으로 합니다.</p>
    </div>

    <div class="content-block">
      <img src="/img/esg/banner01.png" alt="Image" class="esg-img"/>
      <img src="/img/esg/m/m_esg_img_01.png" alt="Image" class="esg-img-m"/>


      <p class="esg-topic1-title">1) 환경법규 준수 및 환경영향 개선</p>
      <p class="esg-content">• 환경·에너지 관련 국제 협약 및 법규를 준수하고 제품의 개발과 생산, 사용, 폐기 등의 전 과정에서 환경영향 개선을 위해 노력한다.</p>
      <p class="esg-content">• 친환경생산 공정 도입과 환경오염 방지 최적기술 적용으로 오염물질 배출을 최소화한다.</p>
      <p class="esg-topic1-title">2) 기후변화 대응</p>
      <p class="esg-content">• 기후변화에 따른 리스크 자체 평가체계를 마련하고, 이행 가능한 목표를 설정하며 성과를 자체 평가한다.</p>
      <p class="esg-content">• 화석연료 및 화석원료 사용량을 저감하고 에너지 효율 향상 및 신재생에너지의 개발 및 이용을 통해 온실가스 배출을 감축하기 위해 노력한다.</p>
      <p class="esg-topic1-title">3) 생물 다양성 보전 및 자연환경 복원</p>
      <p class="esg-content">• 천연자원, 부산물 등을 효율적으로 활용하여 자연 생태계의 복원과 생물 다양성 보전을 위해 노력한다.</p>
      <p class="esg-content">• 녹지·산림·습지·해양 등 지구환경 훼손을 최소화하기 위해 사업장 확대 및 신규 사업장 설치 시 리스크 평가를 실시하고, 사업 철수 시 재조림, 녹지화 등 기존
        자연환경 복원을 위해 노력한다.</p>
      <p class="esg-topic1-title">4) 원부자재 및 용수</p>
      <p class="esg-content">• 완제품의 자원사용량을 고려하고, 사용하는 자재를 재생가능한 원료, 불순물이 적은 원료 등의 친환경 물질로 대체하거나, 절수기 설치와 같이 자원 사용을 줄일 수
        있는 신규 설비 혹은 청정생산기술을 도입하여 생산활동에 투입되는 자원의 양을 저감하기 위해 노력한다.</p>
      <p class="esg-topic1-title">5) 폐기물 및 폐수</p>
      <p class="esg-content">• 제품 생산과정에서 발생하는 폐기물 및 폐수의 발생을 최소화할 수 있도록 하고, 발생한 폐기물 및 폐수에 대해서는 최대한 재활용하며, 사업장 밖으로 배출하는
        폐기물 및 폐수에 대해서는 환경에 미치는 영향을 최소화하기 위하여 필요한 폐기물 처리 또는 정수 처리 시스템을 구축한다.</p>
      <p class="esg-topic1-title">6) 유해화학물질</p>
      <p class="esg-content">• 유해화학물질을 체계적으로 관리하여 유출, 누출 등의 화학물질 사고가 발생하지 않도록 하며, 유해화학물질의 이용 및 배출을 저감하기 위해 노력한다.</p>
    </div>
    <div class="content-block">
      <img src="/img/esg/banner02.png" alt="Image" class="esg-img"/>
      <img src="/img/esg/m/m_esg_img_02.png" alt="Image" class="esg-img-m"/>

      <p class="esg-topic2-title">1 ) 환경관리시스템 구축</p>
      <p class="esg-content">• 회사의 사업활동에 특유한 환경 리스크를 평가하고 이에 대응할 수 있는 환경관리시스템 (Environmental Management System)을 구축하고,
        환경 관련 구체적 성과 목표를 설정하고 이를 지속적으로 개선한다.</p>
      <p class="esg-content">• 이사회와 경영진은 환경관리시스템 관련 중요한 의사결정 및 관리·감독에 적극적으로 참여하고, 환경관리시스템이 실질적으로 설치, 운영될 수 있도록 담당 실무진을
        두어 인적·물적으로 지원한다.</p>
      <p class="esg-content">• 환경관리시스템의 운영을 위하여 각 회사의 사업 실정에 맞게 기존 정책에 환경 관련 요소를 포함하고, 필요시 생물 다양성·산림·습지·기후 리스크 등 세부 환경
        분야에 대한 구체적 정책·지침·사규를 수립하여 준수한다.</p>
      <p class="esg-content">• 협력업체, 공급사, 거래상대방 등 이해관계자에게도 환경보호 및 기후변화 대응의 중요성을 소통하고 환경경영체계를 구축할 수 있도록 지원한다.</p>
      <p class="esg-content">• 임원 및 부서장은 소속 임직원들이 환경경영의 중요성을 인식하고 환경관리시스템을 이해할 수 있도록 교육을 실시한다.</p>
      <p class="esg-topic2-title">2 ) 기후변화 대응</p>
      <p class="esg-content">• 기후변화 관련 전환 리스크, 물리적 리스크를 전사적인 리스크 평가 체계 및 경영 전략에 통합하고, 문서화하여 관리한다.</p>
      <p class="esg-content">• 기후변화 관련 리스크 및 전략 평가는 이사회·경영진에 적절히 보고되도록 한다.</p>
      <p class="esg-content">• 온실가스 배출 저감 및 탄소중립 목표 달성을 위해 구체적·현실적인 방안을 마련하고 이를 이행한다.</p>
      <p class="esg-content">• TCFD 등 기후변화 관련 인증·공시 프레임워크에 따르거나 이에 준하는 방안을 통해 기후변화 대응 노력 및 현황을 합리적 범위에서 공개한다.</p>
      <p class="esg-topic2-title">3) 생산 및 사업장 환경 관리</p>
      <p class="esg-content">• KPI 등 목표 관리, 교육/훈련, 지침 수립 및 관리, 내부 심사 등의 프로세스 절차를 통해 생산 및 사업장의 환경 관리가 적절히 이루어지도록 한다.</p>
      <p class="esg-content">• 친환경 생산공정과 최적 방지 기술 적용으로 오염물질 배출 및 배출하는 오염물질의 환경영향을 최소화한다.</p>
      <p class="esg-content">• 생산시설·사업장 특성에 맞춰 환경경영 매뉴얼을 제정·운영하며, 설비가동시 발생하는 환경영향을 모니터링하고 지속적으로 개선한다.</p>
      <p class="esg-content">•사업장 개설·확대 및 철수 시에는 생태계 및 지구환경 보전을 위해 철저히 리스크를 평가하고 순 환경영향 제로를 위해 노력한다. 산림 및 습지를 파괴하는 사업활동을
        하지 않으며, 산림·습지 복원 활동 등 생태계에 미치는 영향을 완화하는 최선의 방향을 모색하고 실시한다.</p>
      <p class="esg-topic2-title">4 ) 제품 및 서비스 개발·유통</p>
      <p class="esg-content">• 저탄소 경제로의 전환 과정 속 위기와 기회요인을 인지하고, 친환경 제품 및 부산물의 공급 확대, 운송·물류 과정에서의 환경 리스크 평가를 통해 제품·서비스 개발
        및 공급의 전 과정에서 환경영향을 최소화한다.</p>
      <p class="esg-content">• 저탄소·친환경·신재생에너지 제품 개발을 통해 경쟁력을 제고한다.</p>
      <p class="esg-topic2-title">5 ) 공급망 관리, 업체 선정 및 평가</p>
      <p class="esg-content">• 외부 업체(공급·계약·서비스 제공) 선정 시 환경관리 수준 등을 평가 항목을 반영함으로써 환경 영향을 최소화한다.</p>
      <p class="esg-content">• 공급사·협력업체가 높은 수준의 환경경영 체계를 구축할 수 있도록 지원하며, 자체 공급사 환경경영 성과 평가를 통해 공급망 전체의 환경 리스크를 선제적으로
        관리한다.</p>
      <p class="esg-content">• 자재·비품의 조달시 친환경 구매 정책을 준수한다.</p>
      <p class="esg-topic2-title">6 ) 신규 프로젝트 및 인수·합병</p>
      <p class="esg-content">• 신규 사업/투자 검토과정에 환경관리계획 및 환경영향평가 등을 통해 신규 사업의 환경 리스크를 최소화한다. 인수·합병 검토 시 사전 실사를 통해 환경 리스크를
        파악하여 사전 대응한다</p>
    </div>
  </div>
</template>

<style scoped>
.esg-head{
  font-weight: bold;
  font-size: 2.2917vw;
  margin-bottom: 0.0000vw;
}
.esg-head-sub{
  font-weight: bold;
  font-size: 1.5625vw;
  margin-top: 0.0000vw;
  color: #cccccc;
}
.esg-head-content{
  font-size: 1.4583vw;
}


.esg-environmental-container {
  width: 78.1250vw;
  margin: 0.0000vw auto;
}

.esg-topic1-title {
  background-color: #dddddd;
  padding: 0.5208vw 0.0000vw 0.5208vw 2.6042vw;
  font-size: 1.6667vw;
  font-weight: bold;
  border-radius: 2.0833vw 0.0000vw 2.0833vw 0.0000vw;
}

.esg-topic2-title {
  background-color: #6bd26b;
  color: white;
  padding: 0.5208vw 0.0000vw 0.5208vw 2.6042vw;
  font-size: 1.6667vw;
  font-weight: bold;
  border-radius: 2.0833vw 0.0000vw 2.0833vw 0.0000vw;
}

.esg-content {
  width: 90%;
  margin: 0.0000vw auto;
  font-size: 1.1458vw;
  padding-bottom: 0.7813vw;
}
.content-block{
  margin-bottom: 10%;
  margin-top: 5%;
}
.esg-img{
  width: 100%;
}
.esg-img-m{
  display: none;
}
@media (max-width: 540px) {
  .esg-img{
    display: none;
  }
  .esg-img-m{
    display: block;
    width: 100%;
  }
  .esg-head{
    font-size: 6.6667vw;
    margin: 0 2.7778vw 0 0;
  }
  .esg-head-sub{
    font-size: 5.1852vw;

    margin: 0;
  }
  .esg-head-content{
    font-size: 4.4444vw;
    word-break: keep-all; /* 단어 단위로 줄바꿈이 발생하도록 */
    white-space: normal;  /* 기본 줄바꿈 동작 유지 */
  }
  .content-block-m{
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
  }
  .esg-topic1-title{
    font-size: 3.7037vw;
  }
  .esg-content{
    font-size: 2.9630vw;
    word-break: keep-all; /* 단어 단위로 줄바꿈이 발생하도록 */
    white-space: normal;  /* 기본 줄바꿈 동작 유지 */
  }
  .esg-topic2-title{
    font-size: 3.7037vw;
  }
}
</style>