<template>
  <div class="main-top-vision-container">
    <img src="/img/main/main_statement_titleTxt.png"
         alt="titleTxt Image" class="vision-title fade-in-up"
         ref="visionTitleImage"
    />
    <div class="vision-text-container">
      <span ref="visionTitleText" class="fade-in-up">
        나는 자리이타(自利利他) 정신을 기반으로,<br/>
        직원과 고객 모두가 참여하고 행복한 기업 생태계를 구축하는데 최선을 다할 것을 선언합니다.
      </span>
      <hr ref="visionUnderLine" class="under-line-vision fade-in-up">
      <div ref="visionList1" class="vision-list fade-in-up">
        <span class="vision-list-num">첫째, </span>
        <span class="vision-list-text">
          대한민국의 미래사회를 선도할 경영자로서의 사회적 책임과 소명을 충실히 이행하겠습니다.
        </span>
      </div>
      <div ref="visionList2" class="vision-list fade-in-up">
        <span class="vision-list-num">둘째, </span>
        <span class="vision-list-text">
          단기 이윤 추구를 넘어, 지속 가능하고 영혼이 살아 숨쉬는 기업을 구축하겠습니다.
        </span>
      </div>
      <div ref="visionList3" class="vision-list fade-in-up">
        <span class="vision-list-num">셋째, </span>
        <span class="vision-list-text">
          능력과 인품을 바탕으로 한 리더쉽을 갖추어, 사회 대중으로부터 신뢰와 존중을 받는 경영자가 되기 위하여 노력하겠습니다.
        </span>
      </div>
    </div>
    <span ref="visionEndText" class="vision-end-text fade-in-up">
        <b>가후이엔씨(주) 대표이사 서 광 익</b>
      </span>
  </div>

  <div class="main-top-container" ref="bannerContainer">
    <img ref="bannerLeft" src="/img/main/m_banner01.png" alt="banner01 Image" class="banner-left slide-in-left"/>
    <img ref="bannerRight" src="/img/main/m_banner02.png" alt="banner02 Image" class="banner-right slide-in-right"/>
  </div>
  <div class="mobile-top-container">
    <span class="mobile-top-head">가후이엔씨</span>
    <img class="main-bg-2" src="/img/main/mobile_img/m_main_bg2_01.png" alt="main-bg-2"/>

  </div>

  <div class="main-middle-container" ref="middleContainer">
    <p ref="midLeft" class="mid-left fade-in-up">자동화 분야의 주역, <br/> 가후이엔씨</p>
    <p ref="midRight" class="mid-right fade-in-up">
      자동화 분야라면 누구에게도 뒤지지 않는 경험과 기술력이<br/> 가후이엔씨에 있습니다.<br/>
      당사는 각종 제어 장비, 계측기기 및 관련 S/W를 공급하고 있습니다.
      당사가 제공하는 각종 계기 및 계측기 설계, 납품, 설치, 감리,<br/> 시운전, After Service까지 모든 것을 책임집니다.
    </p>
    <div ref="comBan" class="com-ban">
      <img ref="comRef1" class="scale-up" src="/img/main/main_icons/company_ban01.png" alt="com1"/>
      <img ref="comRef2" class="scale-up" src="/img/main/main_icons/company_ban02.png" alt="com2"/>
      <img ref="comRef3" class="scale-up" src="/img/main/main_icons/company_ban03.png" alt="com3"/>
      <img ref="comRef4" class="scale-up" src="/img/main/main_icons/company_ban04.png" alt="com4"/>
      <img ref="comRef5" class="scale-up" src="/img/main/main_icons/company_ban05.png" alt="com5"/>
      <img ref="comRef6" class="scale-up" src="/img/main/main_icons/company_ban06.png" alt="com6"/>
    </div>
  </div>

  <div class="mobile-middle-container" ref="middleContainer">
    <p class="m-middle-head">자동화 분야의 주역, <br/> 가후이엔씨</p>
    <span class="m-middle-content1">
      자동화 분야라면 누구에게도 뒤지지 않는 경험과 기술력이 가후이엔씨에 있습니다.
    </span>
    <p class="m-middle-content2">
      당사는 각종 제어 장비, 계측기기 및
      관련 S/W를 공급하고 있습니다.
      당사가 제공하는 각종 계기 및
      계측기 설계, 납품, 설치, 감리, 시운전,
      After Service까지 모든 것을
      책임집니다.
    </p>
    <img src="/img/main/mobile_img/m_card_img01.png" alt="com1"/>
    <img src="/img/main/mobile_img/m_card_img02.png" alt="com2"/>
    <img src="/img/main/mobile_img/m_card_img03.png" alt="com3"/>
    <img src="/img/main/mobile_img/m_card_img04.png" alt="com4"/>
    <img src="/img/main/mobile_img/m_card_img05.png" alt="com5"/>
    <img src="/img/main/mobile_img/m_card_img06.png" alt="com6"/>
  </div>

  <div class="main-end-container" ref="endContainer">
    <p
        ref="clientMainText"
        class="client-main-text fade-in-up clickable-text"
        @click="navigateToClient"
    >
      가후이엔씨와 함께한 주요 고객사
    </p>    <hr class="under-line">
    <Carousel class="client-container" :value="clients" :numVisible="3" :numScroll="1"
              :responsiveOptions="responsiveOptions" circular :autoplayInterval="5000">
      <template #item="slotProps">
        <div class="border border-surface-200 dark:border-surface-700 rounded m-1 p-2">
          <div class="relative mx-auto">
            <img :src="slotProps.data.path" :alt="slotProps.data.name" class="client-img rounded" />
          </div>
        </div>
      </template>
    </Carousel>
  </div>
</template>

<script setup>
import {ref} from 'vue';
import {useIntersectionObserver} from '@/utils/useIntersectionObserver';
import Carousel from 'primevue/carousel';
import { useRouter } from 'vue-router';
const router = useRouter();

const clients = ref([
  { path: '/img/business/client/oil/client_oil_logo_banner01.png', name: 'S-OIL' },
  { path: '/img/business/client/food/client_food_logo_banner01.png', name: 'CJ' },
  { path: '/img/business/client/food/client_food_logo_banner03.png', name: '참고을' },
  { path: '/img/business/client/oil/client_oil_logo_banner02.png', name: 'S-OIL 토탈 윤활유' },
  { path: '/img/business/client/food/client_food_logo_banner02.png', name: 'SPC' },
  { path: '/img/business/client/cement/client_cement_logo_banner02.png', name: '한국시멘트' },
  { path: '/img/business/client/etc/client_etc_logo_banner02.png', name: '현대제철' },
]);


const responsiveOptions = ref([
  {
    breakpoint: '1400px',
    numVisible: 2,
    numScroll: 1
  },
  {
    breakpoint: '1199px',
    numVisible: 3,
    numScroll: 1
  },
  {
    breakpoint: '767px',
    numVisible: 2,
    numScroll: 1
  },
  {
    breakpoint: '575px',
    numVisible: 1,
    numScroll: 1
  }
]);

const navigateToClient = (() => router.push('/business/client'));

// 각 요소에 대한 ref 설정
const visionTitleImage = ref(null);
const visionTitleText = ref(null);
const visionUnderLine = ref(null);
const visionList1 = ref(null);
const visionList2 = ref(null);
const visionList3 = ref(null);
const visionEndText = ref(null);
const middleContainer = ref(null);
const endContainer = ref(null);
const bannerLeft = ref(null);
const bannerRight = ref(null);
const midLeft = ref(null);
const midRight = ref(null);
const comBan = ref(null);
const clientSubText = ref(null);
const clientMainText = ref(null);

// comRef1 ~ comRef6에 대한 개별 ref 설정
const comRef1 = ref(null);
const comRef2 = ref(null);
const comRef3 = ref(null);
const comRef4 = ref(null);
const comRef5 = ref(null);
const comRef6 = ref(null);


// useIntersectionObserver 훅 호출
useIntersectionObserver([
  visionTitleImage,
  visionTitleText,
  visionUnderLine,
  visionList1,
  visionList2,
  visionList3,
  visionEndText,
  bannerLeft,
  bannerRight,
  midLeft,
  midRight,
  comRef1,
  comRef2,
  comRef3,
  comRef4,
  comRef5,
  comRef6,
  clientSubText,
  clientMainText,
]);

</script>


<style scoped>
.main-top-vision-container {
  width: 99.0000vw;
  height: 56.2500vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-image: url('/img/main/main_statement_bg.jpg');
  background-size: cover;
  background-position: center;
  gap: 3.1250vw;
}
.vision-title{
  width: 18.7500vw;
  margin-top:6.2500vw;
  content: url('/img/main/main_statement_titleTxt.png');
}
.under-line-vision{
  width: 15.6250vw;
  border: 0;
  height: 0.2083vw;
  background-color: #1453dc;
}
.vision-text-container {
  width: 72.9167vw;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  gap: 2.0833vw;
  font-size: 1.8750vw;
}

.vision-list {
  display: flex;
  align-items: baseline;
  justify-content: start;
  flex-direction: row;
  font-size: 1.5625vw;
  text-align: left;
}

.vision-list-num {
  font-weight: bold;
  margin-right: 0.5208vw;
  width: 3.6458vw;
  color: #006cbb;
}
.vision-end-text{
  font-size: 1.8750vw;
  margin-top: 2.0833vw;
  margin-bottom: 6.2500vw;
}
.main-top-container {
  display: flex;
  justify-content: space-between; /* 이미지가 양 끝에 배치되도록 설정 */
  align-items: center; /* 이미지 수직 중앙 정렬 */
  width: 100%;
  margin-top: 10%;
  margin-bottom: 10%;
  flex-wrap: wrap; /* 화면 크기에 따라 이미지가 감싸지도록 설정 */
}

.banner-left,
.banner-right {
  flex: 1 1 auto; /* 이미지를 유연하게 조절 */
  max-width: 50%; /* 이미지의 최대 너비 제한 */
  height: auto;
}

.main-middle-container {
  width: 78.1250vw;
  margin: 0px auto;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 10%;
  flex-wrap: wrap;
}

.com-ban {
  margin-top: 8%;
  display: flex;
  flex-wrap: wrap;
  gap: 2.6042vw;
}

.mid-left {
  width: 45%;
  font-size: 3.6458vw;
  margin: 0px;
  font-weight: bold;
}

.mid-right {
  width: 55%;
  font-size: 1.5625vw;
  margin: 0px;
}

.main-end-container {
  background-color: #ebebeb;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.client-main-text {
  color: #4a4a4a;
  font-size: 2.6042vw;
  margin: 2.6042vw 0 0 0;
}

.under-line {
  width: 20%;
  border: 0;
  height: 0.2083vw;
  background-color: crimson;
}

.client-container {
  width: 78.1250vw;
  margin: 0px auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 5%;
  flex-wrap: wrap;
  gap: 1.0417vw;
  margin-top: 2.0833vw;
}
.client-img{
  width: 100%;
}




.mobile-top-container {
  display: none;
}

.mobile-middle-container {
  display: none;
}

/* 화면 크기가 1400px 이하일 때 레이아웃 변경 */
@media (max-width: 800px) {
  .main-top-container {
    flex-direction: column; /* 이미지를 위아래로 배치 */
  }

  .banner-left,
  .banner-right {
    max-width: 100%; /* 이미지의 너비를 100%로 설정 */
  }

  .banner-left {
    margin-bottom: 20px; /* 상단 이미지와 하단 이미지 간 간격 조절 */
  }
}

/* 작은 화면을 위한 미디어 쿼리 */
@media (max-width: 540px) {
  .main-top-vision-container {
    width: 100.0000vw;
    height: 185.1852vw;
    background-image: url('/img/main/mobile_img/main_m_statement_bg.jpg');
    gap: 11.1111vw;
  }
  .vision-title{
    width: 46.2963vw;
    margin-top:0;
    content: url('/img/main/mobile_img/main_statement_m_titleTxt.jpg');
  }
  .under-line-vision{
    width: 37.0370vw;
    height: 0.3704vw;
  }
  .vision-text-container {
    width: 85.1852vw;
    gap: 7.4074vw;
    font-size: 4.4444vw;
  }

  .vision-list {
    font-size: 3.7037vw;
  }

  .vision-list-num {
    font-weight: bold;
    width: 9.2593vw;
    color: #006cbb;
  }
  .vision-list-text{
    width: 75.9259vw;
  }
  .vision-end-text{
    font-size: 4.4444vw;
    margin-top: 3.7037vw;
    margin-bottom: 0;
  }
  .mobile-top-container {
    background-size: auto;
    background-position: top;
    background-repeat: no-repeat;
    background-image: url('/img/public_img/bg_02_img.png');
    display: flex;
    flex-direction: column;
    margin-bottom: 27.7778vw;
  }

  .mobile-top-head {
    margin: 27.7778vw 0 0 9.2593vw;
    font-size: 8.8889vw;
    font-weight: bold;
  }

  .main-bg-2 {
    margin-top: 3.7037vw;
  }

  .mobile-middle-container {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url('/img/main/mobile_img/m_main3_bg.jpg');
    display: flex;
    flex-direction: column;
    color: white;
    padding: 18.5185vw 9.2593vw 27.7778vw 9.2593vw;
    gap: 3.7037vw;
  }

  /* .main-top-container 레이아웃 조정 */
  .main-top-container {
    display: none;
  }

  .main-middle-container {
    display: none;
  }

  .m-middle-head {
    font-size: 8.8889vw;
  }

  .m-middle-content1 {
    font-size: 4.8148vw;
  }

  .m-middle-content2 {
    font-size: 4.8148vw;
  }

  .main-end-container {
    padding: 18.5185vw 4.0741vw 10.0000vw 5.5556vw;
    align-items: start;
  }
  .client-main-text {
    color: #4a4a4a;
    margin: 0 auto;
    font-size: 6.0vw;
    font-weight: bold;
  }

  .under-line {
    margin-bottom: 9.2593vw;
    width: 50%;
    height: 0.4083vw;
  }

  .client-container {
    width: 92.5926vw;
    max-width: 92.5926vw;
    height: auto;
  }

}
</style>

<style>
@media (max-width: 540px) {
  .client-container .p-carousel-content-container{
    width: 92.5926vw;
  }
}

</style>
