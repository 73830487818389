<script setup>

</script>

<template>
  <div class="partner-section">
    <h1 class="main-title">
      우리 고객사들은 각자의 산업에서 혁신과 성장을 선도하는 주역들입니다.
    </h1>

    <!-- 시멘트 -->
    <div class="category-container">
      <span class="category-title">시멘트</span>
      <div class="image-gallery">
        <div
            v-for="(item, index) in 9"
            :key="index"
            class="image-wrapper"
            :style="{ '--delay': (index * 0.2) + 's' }"
        >
          <img
              class="co-img"
              :src="`/img/business/client/cement/client_cement_logo_banner${(index + 1).toString().padStart(2, '0')}.png`"
              alt="Partner Logo"
          />
        </div>
      </div>
    </div>

    <!-- 정유 및 석유화학 -->
    <div class="category-container">
      <span class="category-title">정유 및 석유화학</span>
      <div class="image-gallery">
        <div
            v-for="(item, index) in 12"
            :key="index"
            class="image-wrapper"
            :style="{ '--delay': (index * 0.2) + 's' }"
        >
          <img
              class="co-img"
              :src="`/img/business/client/oil/client_oil_logo_banner${(index + 1).toString().padStart(2, '0')}.png`"
              alt="Partner Logo"
          />
        </div>
      </div>
    </div>

    <!-- 식품 및 음료 -->
    <div class="category-container">
      <span class="category-title">식품 및 음료</span>
      <div class="image-gallery">
        <div
            v-for="(item, index) in 7"
            :key="index"
            class="image-wrapper"
            :style="{ '--delay': (index * 0.2) + 's' }"
        >
          <img
              class="co-img"
              :src="`/img/business/client/food/client_food_logo_banner${(index + 1).toString().padStart(2, '0')}.png`"
              alt="Partner Logo"
          />
        </div>
      </div>
    </div>

    <!-- 환경(소각) -->
    <div class="category-container">
      <span class="category-title">환경(소각)</span>
      <div class="image-gallery">
        <div
            v-for="(item, index) in 5"
            :key="index"
            class="image-wrapper"
            :style="{ '--delay': (index * 0.2) + 's' }"
        >
          <img
              class="co-img"
              :src="`/img/business/client/environment/client_environment_logo_banner${(index + 1).toString().padStart(2, '0')}.png`"
              alt="Partner Logo"
          />
        </div>
      </div>
    </div>

    <!-- 기타 -->
    <div class="category-container">
      <span class="category-title">기타</span>
      <div class="image-gallery">
        <div
            v-for="(item, index) in 13"
            :key="index"
            class="image-wrapper"
            :style="{ '--delay': (index * 0.2) + 's' }"
        >
          <img
              class="co-img"
              :src="`/img/business/client/etc/client_etc_logo_banner${(index + 1).toString().padStart(2, '0')}.png`"
              alt="Partner Logo"
          />
        </div>
      </div>
    </div>

  </div>
</template>

<style scoped>
/* ------------------------------------------------
  1) 섹션 스타일
------------------------------------------------ */
.partner-section {
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.6), rgba(245, 245, 245, 0.8));
  backdrop-filter: blur(10px);
  color: #333;
  text-align: center;
  padding: 40px 20px 80px 20px; /* 제목 강조를 위해 여백 증가 */
  border-radius: 20px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

/* ------------------------------------------------
  2) 메인 타이틀 스타일
------------------------------------------------ */
.main-title {
  font-size: 3.5rem; /* 기본 데스크톱 크기 */
  font-weight: 800;
  margin-bottom: 140px;
  letter-spacing: -0.5px;

  /* 파란색 계열 그라디언트 */
  background: linear-gradient(45deg, #4a90e2, #89c3f1);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  text-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
}

/* 반응형(태블릿, 모바일) 메인 타이틀 크기 조정 */
@media (max-width: 768px) {
  .main-title {
    font-size: 2.5rem;
  }
}
@media (max-width: 480px) {
  .main-title {
    font-size: 1.8rem;
    margin-bottom: 120px;
  }
  .co-img {
    width: 100% !important; /* 가로 크기를 80%로 줄임 */
    height: auto; /* 세로 비율 유지 */
    border-radius: 15px; /* 모서리를 조금 더 둥글게 */
  }

  .image-wrapper {
    animation: none; /* 작은 화면에서는 애니메이션 비활성화 */
  }

  .image-gallery {
    display: grid;
    grid-template-columns: repeat(2, 1fr) !important; /* 2열로 설정 */
    gap: 16px; /* 이미지 간격 조정 */
    max-width: 100%; /* 전체 화면에 맞게 조정 */
  }
}

/* ------------------------------------------------
  3) 카테고리 컨테이너 (★디자인 강화★)
------------------------------------------------ */
.category-container {
  width: 80%;
  /* 기존 기본 간격 */
  margin: 0 auto 120px;
  /* 예시: 카드 형태로 감싸기 */
  position: relative;
  padding: 40px 20px;

  /* 반투명+그라디언트 배경 */
  background: linear-gradient(
      135deg,
      rgba(255, 255, 255, 0.5),
      rgba(240, 240, 240, 0.3)
  );
  backdrop-filter: blur(10px);

  /* 모서리와 그림자 */
  border-radius: 20px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.15);

  /* 세로로 구분감 부각 (원한다면 없애도 됨) */
  border: 1px solid rgba(255, 255, 255, 0.3);
}

/* 카드 뒤에 살짝 빛이 번지는 효과 */
.category-container::before {
  content: '';
  position: absolute;
  inset: 0;
  z-index: -1;
  border-radius: 20px;
  background: radial-gradient(
      circle at top left,
      rgba(255,255,255,0.2),
      transparent 70%
  );
}

/* ------------------------------------------------
  4) 카테고리 타이틀 (★디자인 강화★)
------------------------------------------------ */
.category-title {
  font-size:3.6vw;
  font-weight: 700;
  margin-bottom: 40px;
  color: #333;
  text-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);

  /* 중앙 정렬용 */
  display: inline-block;
  padding: 0.5em 1em;
  position: relative;

}

.category-title:hover::after {
  transform: scaleX(1);
}

/* ------------------------------------------------
  5) 이미지 갤러리 (반응형 그리드) - 기존 유지
------------------------------------------------ */
.image-gallery {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  gap: 32px;
  max-width: 1200px;
  margin: 0 auto;

  perspective: 1200px; /* 3D 깊이감 */
  overflow: visible;
}

@media (min-width: 1200px) {
  .image-gallery {
    grid-template-columns: repeat(auto-fit, minmax(360px, 1fr)); /* PC에서 3배 크기 */
  }
}
@media (min-width: 768px) and (max-width: 1199px) {
  .image-gallery {
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  }
}

/* ------------------------------------------------
  6) 이미지 래퍼 (애니메이션) - 기존 유지
------------------------------------------------ */
.image-wrapper {
  position: relative;
  overflow: visible;
  animation: subtleFloat 3s ease-in-out infinite;
  animation-delay: var(--delay);
}
.image-wrapper::before {
  content: '';
  position: absolute;
  inset: 0;
  border-radius: 20px;
  background: linear-gradient(
      120deg,
      rgba(255, 255, 255, 0.15) 0%,
      rgba(255, 255, 255, 0) 30%,
      rgba(255, 255, 255, 0.15) 60%,
      rgba(255, 255, 255, 0) 100%
  );
  opacity: 0;
  transition: opacity 0.8s ease;
  z-index: 1;
  pointer-events: none;
}
.image-wrapper:hover::before {
  opacity: 1;
}

/* ------------------------------------------------
  7) 이미지 스타일 - 기존 유지
------------------------------------------------ */
.co-img {
  width: 100%;
  height: auto;
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);

  transform-origin: center center;
  transition:
      transform 0.6s cubic-bezier(0.25, 1, 0.5, 1),
      box-shadow 0.6s cubic-bezier(0.25, 1, 0.5, 1),
      filter 0.6s cubic-bezier(0.25, 1, 0.5, 1);
}
.image-wrapper:hover .co-img {
  transform: rotate(2deg) scale(1.08);
  box-shadow: 0 16px 32px rgba(0, 0, 0, 0.25);
  filter: brightness(1.08) saturate(1.15);
}

/* ------------------------------------------------
  8) 라디얼 글로우 효과 - 기존 유지
------------------------------------------------ */
.image-wrapper::after {
  content: '';
  position: absolute;
  inset: 0;
  border-radius: 20px;
  pointer-events: none;
  z-index: 2;
  background: linear-gradient(
      to bottom left,
      rgba(255, 255, 255, 0.3),
      rgba(255, 215, 0, 0)
  );
  transform: scale(0);
  transition: transform 0.5s ease, opacity 0.5s ease;
  opacity: 0;
}
.image-wrapper:hover::after {
  transform: scale(1.1);
  opacity: 1;
}

/* ------------------------------------------------
  9) 이미지 둥실둥실 움직임 (각 이미지 지연) - 기존 유지
------------------------------------------------ */
@keyframes subtleFloat {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0);
  }
}
</style>
