<template>
  <img ref="firstImage" class="fade-in-up business-img" src="/img/public_img/bg_02_img.png" alt="Logo"/>
  <div class="main-container">
    <div class="business-head-div" >
      <p class="business-head">
        <span class="business-head-big">자동화의 새로운 기준,</span>
        가후이엔씨가 제시합니다!
      </p>
      <p class="business-head-content">자동화 분야라면 누구에게도 뒤지지 않는 경험과 기술력이 가후이엔씨에 있습니다.<br/><br/>
        자동화 솔루션은 다양한 산업에서 혁신과 경쟁력을 고객에게 제공합니다.<br/>
        20년이 넘는 업력을 바탕으로 급변하는 산업환경에 유연하게 대응할 수 있도록<br/>
        자동화 시스템을 진단 및 설계하고 최적의 솔루션, 소프트웨어, 네트워크 시스템과 서비스를 제공합니다!<br/>
      </p>
    </div>
    <div class="business-diagram">
      <img class="business-img-1" src="/img/business/info_img01.png" alt="info"/>
      <img class="business-img-m" src="/img/business/m/m_info_img01.png" alt="info"/>
    </div>
    <span class="column-m">연구개발 | <span class="sub-title-m">R&D</span></span>
    <div class="system-container">
      <p class="system-title">R&D</p>
      <hr class="under-line">
      <p class="sub-title">연구개발</p>
      <div class="badge-container">
        <span class="badge-content">
          Exp. Batch Controller<br/>
          <span class="business-sub-sub">KLC/KLD/KLS (User define)</span>
        </span>
        <span class="badge-content">
          Package S/W Development
        </span>
      </div>
    </div>
    <div class="business-middle-div">
      <p class="business-middle-text">
        소형에서 대형에 이르는<br/>
        설비 자동화 시스템 구축과 경험을 바탕으로,<br/>
        최신의 기술 동향을 접목하여<br/>
        귀사의 공정 개선과 자동화 시스템을 구축합니다!<br/></p>
    </div>
    <span class="column-m">주요 공정 시스템 | <span class="sub-title-m">Main Contact Process</span></span>
    <div class="system-container">
      <p class="system-title">Main Contact Process</p>
      <hr class="under-line">
      <p class="sub-title">주요 공정 시스템</p>
      <div class="badge-container">
        <img class="business-img" src="/img/business/main_process_img01.png" alt="info"/>
        <img class="business-img" src="/img/business/main_process_img02.png" alt="info"/>
        <img class="business-img" src="/img/business/main_process_img03.png" alt="info"/>
        <img class="business-img" src="/img/business/main_process_img04.png" alt="info"/>
      </div>
    </div>
    <div>
      <p class="business-middle-under-text">
        자동화 시스템은<br/>
        생산성 향상 뿐만 아니라<br/>
        작업환경의 개선 및 생산 시스템의<br/>
        효율적인 운영을 실현합니다!</p>
    </div>
    <span class="column-m">특화 시스템 | <span class="sub-title-m">Special Solution</span></span>
    <div class="system-container">
      <p class="system-title">Special Solution</p>
      <hr class="under-line">
      <p class="sub-title">특화 시스템</p>
      <div class="badge-container">
        <span class="badge-content">
          Cement Automation
        </span>
        <span class="badge-content">
          Loading Terminal <br/> Automation
        </span>
      </div>
    </div>
  </div>
</template>

<script setup>
import {ref} from 'vue';
import {useIntersectionObserver} from '@/utils/useIntersectionObserver';

const firstImage = ref(null);

useIntersectionObserver([firstImage]);

</script>

<style scoped>
p {
  margin: 0px;
}
.business-head-div{
  margin-bottom: 10.4167vw;
}
.business-head{
  font-weight: bold;
  font-size: 2.0833vw;
}
.business-head-big{
  font-size: 2.6042vw;
}
.business-head-content{
  font-size: 1.5625vw;
}
.business-sub-sub{
  font-size: 1.2500vw;
}
.business-middle-div{
  margin-bottom: 10.4167vw;
}
.business-middle-text{
  font-size: 2.6042vw;
  text-align: center;
  font-weight: bold;
}
.business-middle-under-text{
  margin-bottom: 10.4167vw;
  margin-top: 5.2083vw;
  font-size: 2.6042vw;
  text-align: center;
  font-weight: bold;
}
.business-diagram {
  padding-top: 5%;
  background-image: url('/img/business/bg_txt_img.png');
  background-position: calc(100% - 2.0833vw) top;
  background-size: auto;
  background-repeat: no-repeat;
  margin-bottom: 10.4167vw;
}

.system-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.system-title {
  font-size: 2.9167vw;
  color: #dddddd;
}

.sub-title {
  font-size: 2.9167vw;
}

.badge-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2.6042vw;
  margin: 2.6042vw 0.0000vw 5.2083vw 0.0000vw;

}

.badge-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #3b6ea5;
  padding: 2.6042vw;
  border-radius: 5.2083vw;
  color: white;
  font-size: 2.0833vw;
  text-align: center;
  width: 33.8542vw;
  height: 10.4167vw;
}

.under-line {
  width: 6%;
  border: 0;
  height: 0.2083vw;
  background-color: darkblue;
}
.business-img{
  width: 17.7083vw;
}
.business-img-m{
  display: none;
}
.business-img-1{
  width: 100%;
}
.column-m{
  display: none;
}
.sub-title-m{
  display: none;
}
@media (max-width: 540px) {

  .main-container{
    width: 100%;
    padding: 0 5.5556vw 0 5.5556vw;
  }
  .business-head-div{
    margin: 12.9630vw 0 0 0;
  }
  .business-img{
    width: 20.3704vw;
    height: auto;
  }
  .business-head{
    font-size: 5.9259vw;
    word-break: keep-all; /* 단어 단위로 줄바꿈이 발생하도록 */
    white-space: normal; /* 기본 줄바꿈 동작 유지 */
  }
  .business-head-big{
    font-size: 5.9259vw;
    display: block;
  }
  .business-head-content{
    font-size: 4.0741vw;
    margin: 3.7037vw 0 0 0;
    word-break: keep-all; /* 단어 단위로 줄바꿈이 발생하도록 */
    white-space: normal; /* 기본 줄바꿈 동작 유지 */
  }
  .business-diagram {
    background-image: none;
  }
  .system-container{
    flex-direction: row;
    flex-wrap: wrap;
  }
  .system-title{
    display: none;
  }
  .under-line{
    display: none;
  }
  .sub-title{
    display: none;
  }
  .business-img-1{
    display: none;
  }
  .business-img-m{
    display: block;
    width: 100%;
  }
  .badge-content{

  }
  .business-sub-sub{
    font-size: 4.0741vw;
  }
  .column-m{
    display: inline;
    font-size: 4.8148vw;
  }
  .sub-title-m{
    display: inline;
    color: #dddddd;
  }
  .badge-content {
    padding: 5.5556vw;
    border-radius: 9.2593vw;
    font-size: 4.4444vw;
    width: 42.5926vw;
    height: 27.7778vw;
  }
  .business-middle-text{
    margin: 5.5556vw 0 0 0;
    font-size: 4.4444vw;
  }
  .business-middle-under-text{
    font-size: 4.4444vw;
  }
}
</style>
