import Notices from "@/views/news/Notices.vue";
import NewsPress from "@/views/news/NewsPress.vue";

const newsRoutes = [
    {
        path: "notices",
        component: Notices,
        meta: { main: '소식', sub: '공지사항' }
    },
    {
        path: "news-press",
        component: NewsPress,
        meta: { main: '소식', sub: '뉴스, 언론보도' }
    }
];

export default newsRoutes;
