<template>
  <img ref="firstImage" class="first-image fade-in-up" style="width: 100%;" src="/img/public_img/bg_02_img.png" alt="Logo"/>
  <div class="main-container hs">
    <div class="div-head">
      <span class="head-text">
        이제 더 이상 장비교체로 인한 고비용 및 신규 장비 사용으로 인한<br/>
        불편은 필요 없습니다.<br/>
      </span>
      <span class="head-sub-text">
        당사의 시스템 개조 및 Upgrade Service 그리고 Engineering Service를 통하여 저렴한 비용으로 최상의 효과를 느껴 보십시오.<br/>
        또한, 최고의 엔지니어가 자동화 설비에 대한 유지, 보수 용역을 제공합니다.<br/>
        이제는 자동 제어 시스템 및 계측기에 대한 유지, 보수 및 교육에 대하여 당사와 협의 하십시오.<br/>
        시스템 유지, 보수는 Outsourcing으로 해결하십시오.<br/>
      </span>
    </div>
    <div class="product-card">
      <div class="product-card-img">
        <img class="card-img" src="/img/product/others/ect_banner01.jpg" alt="Logo"/>
        <img class="card-img-m" src="/img/product/others/m/cate_img06_01.jpg" alt="Logo"/>
      </div>
      <div class="product-card-text">
        <span class="card-text-head">
          Control System Upgrade
        </span>
        <div class="card-text-tags">
          <span class="card-text-tag">Relay Panel System → PLC System</span>
          <span class="card-text-tag">Old, PLC and HMI System → Revamping, New System</span>
          <span class="card-text-tag">Old, Instrument → Field Bus를 이용한 Smart System</span>
          <span class="card-text-tag">Control Logic 개선 및 보완작업</span>
        </div>
      </div>
    </div>
    <div class="product-card">
      <div class="product-card-img">
        <img class="card-img" src="/img/product/others/ect_banner02.jpg" alt="Logo"/>
        <img class="card-img-m" src="/img/product/others/m/cate_img06_02.jpg" alt="Logo"/>
      </div>
      <div class="product-card-text">
        <span class="card-text-head">
          Operation & Supervision System Upgrade
        </span>
        <div class="card-text-tags">
          <span class="card-text-tag">Panel Based Control System → Computer Based Control System</span>
          <span class="card-text-tag">Text mode Control System → Graphic mode Control System</span>
          <span class="card-text-tag">분산 Data 관리 시스템 → 통합 Data 관리 시스템</span>
        </div>
      </div>
    </div>
    <div class="product-card">
      <div class="product-card-img">
        <img class="card-img" src="/img/product/others/ect_banner03.jpg" alt="Logo"/>
        <img class="card-img-m" src="/img/product/others/m/cate_img06_03.jpg" alt="Logo"/>
      </div>
      <div class="product-card-text">
        <span class="card-text-head">
          System Maintenance & Training
        </span>
        <div class="card-text-tags">
          <span class="card-text-tag">Control System 유지보수</span>
          <span class="card-text-tag">Instrument and Control Panel 유지보수</span>
          <span class="card-text-tag">Special Tool (S/W package, Loader) 공급</span>
          <span class="card-text-tag">PLC and DCS, Basic and Advanced 교육</span>
          <span class="card-text-tag">HMI, Basic and Advanced 교육</span>
        </div>
      </div>
    </div>
    <div class="product-card">
      <div class="product-card-img">
        <img class="card-img" src="/img/product/others/ect_banner04.jpg" alt="Logo"/>
        <img class="card-img-m" src="/img/product/others/m/cate_img06_04.jpg" alt="Logo"/>
      </div>
      <div class="product-card-text">
        <span class="card-text-head">
          System Commissioning & Supervision
        </span>
        <div class="card-text-tags">
          <span class="card-text-tag">자동화 시스템 설계 및 자문</span>
          <span class="card-text-tag">제어설비 시운전</span>
          <span class="card-text-tag">Tuning and Optimization</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {ref} from 'vue';
import {useIntersectionObserver} from '@/utils/useIntersectionObserver';

const firstImage = ref(null);

useIntersectionObserver([firstImage]);
</script>

<style scoped>
.div-head {
  margin: 0 0 2.0833vw 0;
}

.head-text {
  font-weight: bold;
  font-size: 2.5000vw;
}

.head-sub-text {
  font-size: 1.4583vw;
}
.product-card{
  display: flex;
  background-color: #dddddd;
  margin-bottom: 2.6042vw;
}
.product-card-img{
}
.card-img-m{
  display: none;
}
.product-card-text{
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 5.2083vw;
}
.card-text-head{
  font-size: 2.5000vw;
  font-weight: bold;
  margin-bottom: 1.0417vw;
}
.card-text-tags{
  display: flex;
  flex-wrap: wrap;
  gap: 0.5208vw;
}
.card-text-tag{
  padding: 0.5208vw 1.0417vw 0.5208vw 1.0417vw;
  background-color: #f5f5f5;
  font-size: 1.0417vw;
  border-radius: 0.5208vw;
  width: 70%;
}
.hs{
  margin-bottom: 10.4167vw;
}
@media (max-width: 540px) {
  .first-image{
    display: none;
  }
  .main-container{
    width: 92.5926vw;
  }
  .product-card{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 9.2593vw;
    height: 74.0741vw;
  }
  .product-card-text{
    margin: 5.5556vw 0 0 0 ;
    align-items: center;
  }
  .card-img-m{
    display:block;
    width: 100%;
  }

  .card-img{
    display:none;
  }

  .head-text{
    display:block;
    font-size: 5.1852vw;
    margin: 9.2593vw 0 3.7037vw 0;
  }
  .head-sub-text{
    display:block;
    font-size: 4.0741vw;
    margin: 0 0 18.5185vw 0;
  }
  .card-text-head{
    font-size: 5.1852vw;
    width: 83.3333vw;
  }
  .card-text-tags{
    gap: 0.9259vw;
    margin: 0 auto;

  }
  .card-text-tag{
    font-size: 2.5926vw;
    border-radius: 1.8519vw;
    padding: 0.5556vw 1.8519vw 0.5556vw 1.8519vw;
    width: 83.3333vw;
    margin: 0 auto;
  }
}
</style>