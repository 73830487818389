
<template>
  <div>

    <img ref="firstImage" class="fade-in-up firstImage" style="width: 100.0000vw;" src="/img/introduce/bg_02_img.png" alt="Logo" />
    <img src="/img/introduce/m/m_sub_kahuLogo.jpg" alt="kahulogo-m" class="kahulogo-m" />

    <div class="image-container fade-in" ref="imageContainer">
      <img src="/img/introduce/bg_01_img.jpg" alt="Background Image" class="background-image1" />
      <div class="overlay-text1">
        <p class="content-head"><b>업계 최고의</b></p>
        <p class="content-head"><b>자동화 시스템 제공</b></p>
        <p class="content-content"><b>가후이엔씨</b>는 세계 일류의 자동화 관련 소프트웨어 개발 및 컨설팅 전문가 집단을 만들기 위하여 2002년 12월 설립된 자동화 전문 SI회사입니다.<br/><br/>
        사업분야 쌍용정보통신 자동화 부문이 새로운 이름으로 여러분에게 다가갑니다.<br/>
        자동화 분야라면 누구에게도 뒤지지 않는 경험과 기술력이 가후이엔씨에 있습니다.<br/>
        새로운 천년, 고객과 함께 일류기업을 실현하고자 합니다.</p>
        <span class="content-content-m">
        <b>가후이엔씨</b>는 세계 일류의 자동화 관련 <br/>
        소프트웨어 개발 및 <br/>
          컨설팅 전문가 집단을 만들기 위하여 <br/>
          2002년 12월 설립된 자동화 전문 SI회사입니다.<br/><br/>
        사업분야 쌍용정보통신 자동화 부문이<br/>
          새로운 이름으로 여러분에게 다가갑니다.<br/>
        자동화 분야라면 누구에게도 뒤지지 않는<br/>
          경험과 기술력이 가후이엔씨에 있습니다.<br/><br/>
        새로운 천년, 고객과 함께 일류기업을 <br/>실현하고자 합니다.
        </span>
      </div>
    </div>
    <div class="center-align">
      <div class="container-title fade-in" ref="visionContainer">
        <span class="text-title">VISION</span>
        <span class="title-axis">&nbsp;|&nbsp;</span>
        <hr class="under-line">
        <span class="text-title-kr">비전</span>
        <img class="title-image-m" src="/img/introduce/intro/bullet_img.jpg" alt="Image"  />
      </div>
        <div class="container-lead " ref="missionContainer">
          <span class="text-lead1">
            혁신적 기술로 자동화 업계를 선도하며,<br/>
            가치창출을 통하여<br/>
            지속 가능한 성공과 행복을 도모합니다.
          </span>
        </div>
      <div class="container-title">
        <span class="text-title">SLOGAN</span>
        <span class="title-axis">&nbsp;|&nbsp;</span>
        <hr class="under-line">
        <span class="text-title-kr">슬로건</span>
        <img class="title-image-m" src="/img/introduce/intro/bullet_img.jpg" alt="Image"  />
      </div>
      <div class="fade-in" ref="sloganContainer">
        <img src="/img/introduce/intro/intro_page02_slogan.png" alt="Image" class="slogan-image" />
      </div>
      <div class="container-title">
        <span class="text-title">MISSION</span>
        <span class="title-axis">&nbsp;|&nbsp;</span>
        <hr class="under-line">
        <span class="text-title-kr">미션</span>
        <img class="title-image-m" src="/img/introduce/intro/bullet_img.jpg" alt="Image"  />
      </div>
      <div class="fade-in" ref="missionContainer">
        <div class="mission-list">
          <div class="mission-list-head">
            <img class="mission-list-head-num" src="/img/introduce/intro/intro_page_num01.png" alt="Image"  />
            <span class="mission-list-head-text">공장 및 공정 자동화 토탈 솔루션 제공</span>
          </div>
          <span class="mission-list-content">
            가후이엔씨는 첨단 기술을 활용하여 공장 및 공정 자동화를 위한 토탈 솔루션을 제공합니다.<br/>
            업계 최고의 효율성과 성능을 갖춘 솔루션으로 고객의 생산성을 극대화하고, 비용을 최소화합니다.
          </span>
        </div>
        <div class="mission-list">
          <div class="mission-list-head">
            <img class="mission-list-head-num" src="/img/introduce/intro/intro_page_num02.png" alt="Image"  />
            <span class="mission-list-head-text">고객 맞춤형 자동화 솔루션 제공</span>
          </div>
          <span class="mission-list-content">
            가후이엔씨는 고객의 특정 요구사항을 깊이 이해하고, 이에 맞는 맞춤형 자동화 솔루션을 설계 및 구현합니다.<br/>
            우리의 목표는 고객의 운영 효율성을 최적화하고, 경쟁력을 강화하는 것입니다.
          </span>
        </div>
        <div class="mission-list">
          <div class="mission-list-head">
            <img class="mission-list-head-num" src="/img/introduce/intro/intro_page_num03.png" alt="Image"  />
            <span class="mission-list-head-text list-3rd">투명하고 책임감 있고 지속 가능한 경영으로 신뢰받는 기업</span>
            <span class="mission-list-head-text-m">투명하고 책임감 있고 지속 가능한</span>
          </div>
          <span class="mission-list-head-text-m" style="margin: 1.8519vw 0 0 8.3333vw;">경영으로 신뢰받는 기업</span>
          <span class="mission-list-content">
            가후이엔씨는 모든 경영 활동에서 투명성을 유지하고 사회적, 환경적 책임을 성실히 이행합니다.<br/>
            지속 가능한 방식으로 비즈니스를 운영함으로써, 이해관계자들로부터 신뢰를 얻고 장기적인 파트너십을 구축합니다.
          </span>
        </div>
      </div>
      <div class="container-title">
        <span class="text-title">VALUE</span>
        <span class="title-axis">&nbsp;|&nbsp;</span>
        <hr class="under-line">
        <span class="text-title-kr">핵심 가치</span>
        <img class="title-image-m" src="/img/introduce/intro/bullet_img.jpg" alt="Image"  />
      </div>
      <div class="end" ref="valueContainer">
        <div class="mission-list">
          <div class="mission-list-head">
            <img class="value-list-head" src="/img/introduce/intro/intro_page02_num01.png" alt="Image"  />
            <img class="value-list-head-m" src="/img/introduce/intro/m/intro_page02_m01.png" alt="Image"  />
          </div>
          <span class="value-list-content">
            <b>자동화 업계 선도를 위해 지속적으로 혁신합니다.</b><br/>
            첨단 기술과 창의적 접근을 통하여 고객에게 최상의 솔루션을 제공하며 기술적 한계를 넘어섭니다.
          </span>
        </div>
        <div class="mission-list">
          <div class="mission-list-head">
            <img class="value-list-head" src="/img/introduce/intro/intro_page02_num02.png" alt="Image"  />
            <img class="value-list-head-m" src="/img/introduce/intro/m/intro_page02_m02.png" alt="Image"  />
          </div>
          <span class="value-list-content">
            <b>고객의 성공을 우리의 성공으로 여깁니다.</b><br/>
            고객의 요구사항을 충분히 이해하고 충족시키기 위해 끊임없이 노력하며, 고객과의 긴밀한 관계를 유지합니다.
          </span>
        </div>
        <div class="mission-list">
          <div class="mission-list-head">
            <img class="value-list-head" src="/img/introduce/intro/intro_page02_num03.png" alt="Image"  />
            <img class="value-list-head-m" src="/img/introduce/intro/m/intro_page02_m03.png" alt="Image"  />
          </div>
          <span class="value-list-content">
            <b>모든 비즈니스 프로세스에서 투명성을 유지하고 우리의 행동에 책임을 집니다.</b><br/>
            신뢰와 존중을 바탕으로 모든 이해관계자와의 관계를 구축하고 유지합니다.
          </span>
        </div>
        <div class="mission-list">
          <div class="mission-list-head">
            <img class="value-list-head" src="/img/introduce/intro/intro_page02_num04.png" alt="Image"  />
            <img class="value-list-head-m" src="/img/introduce/intro/m/intro_page02_m04.png" alt="Image"  />
          </div>
          <span class="value-list-content">
            <b>환경적, 사회적, 경제적 지속 가능성을 추구합니다.</b><br/>
            우리의 모든 결정은 장기적인 영향을 고려하며 다음 세대를 위해 지금을 책임집니다.
          </span>
        </div>
        <div class="mission-list">
          <div class="mission-list-head">
            <img class="value-list-head" src="/img/introduce/intro/intro_page02_num05.png" alt="Image"  />
            <img class="value-list-head-m" src="/img/introduce/intro/m/intro_page02_m05.png" alt="Image"  />
          </div>
          <span class="value-list-content">
            <b>다양한 배경을 가진 구성원들과 긴밀히 협력하여 목표를 달성합니다.</b><br/>
            개인의 장점을 인정하고 팀의 성공을 위해 함께 노력합니다.
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref } from 'vue';
import { useIntersectionObserver } from '@/utils/useIntersectionObserver';

const imageContainer = ref(null);
const imageTextContainer = ref(null);
const visionContainer = ref(null);
const missionContainer = ref(null);
const sloganContainer = ref(null);
const valueContainer = ref(null);
const firstImage = ref(null);

// 커스텀 훅 호출
useIntersectionObserver([imageContainer, imageTextContainer, visionContainer, missionContainer,valueContainer, sloganContainer, firstImage]);
</script>

<style scoped>
.image-container {
  position: relative;
  width: 100.0000vw; /* 부모 컨테이너의 너비를 100%로 설정 */
  padding-bottom: 10%;
}
.background-image1{
  position: relative;
  left: 0; /* 이미지 우측의 5% 떨어진 지점에서 시작 */
  width: 55%;
}
.overlay-text1 {
  position: absolute;
  top: 40%; /* 부모 요소의 높이를 기준으로 30% 아래에 위치 */
  left: 55%; /* 이미지 우측의 5% 떨어진 지점에서 시작 */
  color: black; /* 텍스트 색상 */
  font-size: 1.3542vw; /* 텍스트 크기 */
  text-align: left; /* 텍스트 왼쪽 정렬 */
  padding-right: 10%;
}

.content-head{
  font-size: 1.8750vw;
  margin: 0.0000vw;
}
.slogan-image{
  width: 100%;
}
.mission-list{
  text-align: left;
  margin: 3.1250vw 0 0 0;
}
.mission-list-head{
  display: flex;
  align-items: flex-end;

  gap:1.0417vw;
}
.value-list-head{
  display: block;
  width:100%;
}
.mission-list-head-num{
  width: 5.3125vw;
}
.mission-list-head-text{
  font-size: 3.1250vw;
  font-weight: bold;
  color: darkblue;
  line-height: 0.75; /* 기본 여백 제거 */
}
.mission-list-content{
  display: block;
  margin:1.5625vw 0 0 6.2500vw;
  font-size: 1.5625vw;
  line-height: 1.5; /* 기본 여백 제거 */
}
.value-list-content{
  display: block;
  margin:1.5625vw 0 0 6.2500vw;
  font-size: 1.5625vw;
  line-height: 1.5; /* 기본 여백 제거 */
}
.center-align{
  text-align:center;
  width: 78.1250vw;
  margin: 0 auto;
}
.container-title{
  padding-top: 7.8125vw;
  padding-bottom: 2.6042vw;
}
.text-title{
  font-size: 2.8125vw;
  font-weight: lighter;
  color: darkgrey;
}
.text-title-kr{
  font-size: 3.6458vw;
}
.under-line {
  width: 6%;
  border: 0;
  height: 0.2083vw;
  background-color: darkblue;
}
.container-lead{
  padding: 0% 10%;
}
.text-lead1{
  padding-top: 2.6042vw;
  font-size: 2.5000vw;
  line-height: 1.5;
}
.kahulogo-m{
  display: none;
}
.content-content-m{
  display: none;
}
.title-axis{
  display: none;
}
.title-image-m{
  display: none;
}
.mission-list-head-text-m{
  display: none;
}
.value-list-head-m{
  display: none;
  width:100%;
}
.end{
  margin-bottom: 100px;
}
@media (max-width: 540px) {
  .center-align{
    width: 88.8889vw;
  }
  .background-image1{
    display: none;
  }
  .kahulogo-m{
    display: flex;
    width: 100.0000vw;
    padding: 9.2593vw 0 0 0 ;
  }
  .container-lead{
    padding:0;
  }
  .firstImage{
    display: none;
  }
  .overlay-text1 {
    position: relative;
    top: 0; /* 부모 요소의 높이를 기준으로 30% 아래에 위치 */
    left: 0; /* 이미지 우측의 5% 떨어진 지점에서 시작 */
    color: black; /* 텍스트 색상 */
    font-size: 5.1852vw; /* 텍스트 크기 */
    text-align: left; /* 텍스트 왼쪽 정렬 */
    padding: 18.5185vw 0 0 0;
    width: 83.3333vw;
    margin: 0 auto;
  }
  .content-head{
    font-size: 8.3333vw;
    margin: 0.0000vw;
  }
  .content-content-m{
    padding: 5.5556vw 0 9.2593vw 0;
    display: block;
  }
  .content-content{
    display: none;
  }
  .container-title{
    width: 92.5926vw;
    margin: 0 auto;
    display: flex;
    align-items: baseline;
    flex-direction: row-reverse;
    justify-content: flex-end;
    padding: 14.8148vw 0 0 0;
  }
  .under-line{
    display: none;
  }
  .text-title{
    font-size: 5.9259vw;
    color: #3b6ea5;
  }
  .title-axis{
    font-size: 5.9259vw;
    display: block;
    color: #3b6ea5;
    font-weight: lighter;
  }
  .text-title-kr{
    font-weight: bold;
    font-size: 5.9259vw;
  }
  .text-lead1{
    font-size: 4.8148vw;
    display: block;
    text-align: left;
  }
  .title-image-m{
    display: block;
    margin: 0 1.8519vw 0 0;
  }
  .mission-list{
    margin: 7.4074vw 0 0 0;
  }
  .mission-list-head-num{
    width: 7.4074vw;
  }
  .mission-list-head-text{
    font-size: 5.1852vw;
  }
  .mission-list-content{
    font-size: 3.7037vw;
    margin:3.7037vw 0 0 0;
  }
  .value-list-content{
    font-size: 3.7037vw;
    margin:3.7037vw 3.7037vw 0 3.7037vw;
  }
  .list-3rd{
    display: none;
  }
  .mission-list-head-text-m{
    display: block;
    font-size: 5.1852vw;
    font-weight: bold;
    color: darkblue;
    line-height: 0.75; /* 기본 여백 제거 */
  }
  .value-list-head{
    display: none;
  }
  .value-list-head-m{
    display: block;
  }

}
</style>