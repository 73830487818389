<script setup>
</script>

<template>
  <div class="partner-section">
    <h1 class="main-title">우리는 업계를 선도하는 글로벌 파트너사들과 함께 혁신을 만들어갑니다.</h1>

    <!-- 이미지 갤러리 (상위 컨테이너에 perspective 적용) -->
    <div class="image-gallery">
      <div
          v-for="(item, index) in 18"
          :key="index"
          class="image-wrapper"
          :style="{'--delay': (index * 0.2) + 's'}"
      >
        <img
            class="co-img"
            :src="`/img/business/partner/co_img${(index + 1).toString().padStart(2, '0')}.png`"
            alt="Partner Logo"
        />
      </div>
    </div>
  </div>
</template>

<style scoped>
/* ------------------------------------------------
  1) 섹션 스타일
------------------------------------------------ */
.partner-section {
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.6), rgba(245, 245, 245, 0.8));
  backdrop-filter: blur(10px);
  color: #333;
  text-align: center;
  padding: 40px 20px 80px 20px; /* 제목 강조를 위해 여백 증가 */
  border-radius: 20px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

/* ------------------------------------------------
  2) 제목 스타일
------------------------------------------------ */
.main-title {
  font-size: 3.5rem; /* 기본 데스크톱 크기 */
  font-weight: 800;
  margin-bottom: 140px;
  letter-spacing: -0.5px;


  background: linear-gradient(45deg, #ff6f61, #ffcd54);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  text-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
}
/* ------------------------------------------------
  3) 반응형 글자 크기 조정
------------------------------------------------ */
/* 태블릿 크기 */
@media (max-width: 768px) {
  .main-title {
    font-size: 2.5rem; /* 태블릿에서는 조금 더 작은 크기 */
  }
}

/* 모바일 크기 */
@media (max-width: 480px) {
  .main-title {
    font-size: 1.8rem; /* 모바일에서는 더 작은 크기 */
    margin-bottom: 120px; /* 글자 아래 간격도 줄임 */
  }
}
/* ------------------------------------------------
  3) 이미지 갤러리 (반응형 그리드)
------------------------------------------------ */
.image-gallery {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr)); /* 기본 크기 */
  gap: 32px;
  max-width: 1200px;
  margin: 0 auto;

  perspective: 1200px; /* 3D 깊이감 */
  overflow: visible;
}

/* 데스크톱 화면에서 그리드 크기 조정 */
@media (min-width: 1200px) {
  .image-gallery {
    grid-template-columns: repeat(auto-fit, minmax(360px, 1fr)); /* PC에서 3배 크기 */
  }
}

/* 태블릿 크기 조정 */
@media (min-width: 768px) and (max-width: 1199px) {
  .image-gallery {
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr)); /* 중간 크기 */
  }
}

/* ------------------------------------------------
  4) 이미지 래퍼
------------------------------------------------ */
.image-wrapper {
  position: relative;
  overflow: visible;

  animation: subtleFloat 3s ease-in-out infinite;
  animation-delay: var(--delay);
}

.image-wrapper::before {
  content: '';
  position: absolute;
  inset: 0;
  border-radius: 20px;
  background: linear-gradient(
      120deg,
      rgba(255, 255, 255, 0.15) 0%,
      rgba(255, 255, 255, 0) 30%,
      rgba(255, 255, 255, 0.15) 60%,
      rgba(255, 255, 255, 0) 100%
  );
  opacity: 0;
  transition: opacity 0.8s ease;
  z-index: 1;
  pointer-events: none;
}

.image-wrapper:hover::before {
  opacity: 1;
}

/* ------------------------------------------------
  5) 이미지 스타일
------------------------------------------------ */
.co-img {
  width: 100%;
  height: auto;
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(5px);

  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);

  transform-origin: center center;
  transition:
      transform 0.6s cubic-bezier(0.25, 1, 0.5, 1),
      box-shadow 0.6s cubic-bezier(0.25, 1, 0.5, 1),
      filter 0.6s cubic-bezier(0.25, 1, 0.5, 1);
}

.image-wrapper:hover .co-img {
  transform: rotate(2deg) scale(1.08);
  box-shadow: 0 16px 32px rgba(0, 0, 0, 0.25);
  filter: brightness(1.08) saturate(1.15);
}

/* ------------------------------------------------
  6) 라디얼 글로우 효과
------------------------------------------------ */
.image-wrapper::after {
  content: '';
  position: absolute;
  inset: 0;
  border-radius: 20px;
  pointer-events: none;
  z-index: 2;

  /* 빛 효과: 우상단에서 좌하단으로 */
  background: linear-gradient(
      to bottom left, /* 방향을 `to` 키워드로 설정 */ rgba(255, 255, 255, 0.3),
      rgba(255, 215, 0, 0)
  );

  transform: scale(0); /* 처음에는 작게 */
  transition: transform 0.5s ease, opacity 0.5s ease;
  opacity: 0; /* 초기에는 보이지 않음 */
}

.image-wrapper:hover::after {
  transform: scale(1.1); /* 빛 효과가 커짐 */
  opacity: 1;
}






/* ------------------------------------------------
  7) 부드러운 위아래 움직임 (각 이미지 지연)
------------------------------------------------ */
@keyframes subtleFloat {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0);
  }
}
</style>
