<template>
  <img ref="firstImage" class="first-image fade-in-up" style="width: 100%;" src="/img/public_img/bg_02_img.png" alt="Logo"/>
  <div class="main-container">
    <div class="div-head">
      <span class="head-text">SCADA<span class="sub-title-m">(Supervisory Control and Data Acquisition)</span></span>
    </div>
    <div class="div-sub-head">
      <span class="head-sub-text">SCADA는 감시 제어 및 데이터 수집 시스템을 의미합니다. SCADA 시스템은 산업 자동화에서 매우 중요한 역할을 하며,<br/>
      공장, 발전소, 물 처리 시설, 송전 및 배전 네트워크 등 다양한 분야에서 사용됩니다.</span>
    </div>
    <div class="centered-container">
      <img ref="scadaCom" class="img-card fade-in-up" src="/img/product/scada/scada_com.png" alt="Logo"/>
      <div class="div-middle">
        <span class="middle-text">SCADA 시스템의 장점</span>
      </div>
      <div class="grid-container-m">
        <img class="img-card-m" src="/img/product/scada/m/cate_img03_01.png" alt="Logo"/>
        <img class="img-card-m" src="/img/product/scada/m/cate_img03_02.png" alt="Logo"/>
        <img class="img-card-m" src="/img/product/scada/m/cate_img03_03.png" alt="Logo"/>
        <img class="img-card-m" src="/img/product/scada/m/cate_img03_04.png" alt="Logo"/>
      </div>
      <div class="grid-container">
        <img class="img-card" src="/img/product/scada/01.png" alt="Logo"/>
        <img class="img-card" src="/img/product/scada/02.png" alt="Logo"/>
        <img class="img-card" src="/img/product/scada/03.png" alt="Logo"/>
        <img class="img-card" src="/img/product/scada/04.png" alt="Logo"/>
      </div>


        <span class="middle-text middle-text-end">
          가후이엔씨의 SCADA Solution은<br/>
          최적의 시스템만을 공급하고 있습니다.<br/>
          또한, 세계 유수의 장비 제조업체와 긴밀한 협력 체계로<br/>
          첨단 SCADA Solution을 구현합니다.<br/>
        </span>
    </div>
  </div>
  <div class="middle-img-container img1">
    <div class="main-container img-box">
      <span class="middle-img-head">설비 관리 시스템</span>
      <span class="middle-img-content">
        공장 설비 원격 관리 시스템<br/>
        모바일 원격 관리 시스템<br/>
        바코드 및 RF 카드를 이용한 설비 관리 시스템<br/>
        통신 기지국 및 교통 설비 원격 관리 시스템<br/>
      </span>
    </div>
  </div>
  <div class="middle-img-container img2">
    <div class="main-container img-box">
      <span class="middle-img-head">전력 등 유틸리티 관리 시스템</span>
      <span class="middle-img-content">
        수,배전 및 저압 배전반 전력 관리 시스템<br/>
        가스 및 물,스팀 등 유틸리티 사용량 관리 시스템<br/>
        중량선별기 및 이물질 감시장치 등 검사장비 관리 시스템<br/>
        냉,난방 및 공조설비 관리 시스템<br/>
        공기 압축설비 관리 시스템<br/>
      </span>
    </div>
  </div>
  <div class="middle-img-container img3">
    <div class="main-container img-box">
      <span class="middle-img-head">
        TM/TC 시스템 <br/>
        <span class="middle-img-head-sub">
          Telemetering & Telecommunication SYSTEM
        </span>
      </span>
      <span class="middle-img-content">
        송유관 설비 원격 제어 시스템<br/>
        원격 검침 서비스를 이용한 각종 계측 자동화 시스템<br/>
        Stack 및 Duct 가스 측정 시스템<br/>
        오, 폐수 원격 관리 시스템<br/>
      </span>
    </div>
  </div>
</template>

<script setup>
import {ref} from 'vue';
import {useIntersectionObserver} from '@/utils/useIntersectionObserver';

const firstImage = ref(null);
const scadaCom = ref(null);

useIntersectionObserver([firstImage, scadaCom]);
</script>

<style scoped>
.div-head {
  margin: 0 0 2.0833vw 0;
}

.head-text {
  font-weight: bold;
  font-size: 2.5000vw;
}

.head-sub-text {
  font-size: 1.4583vw;
}

.div-sub-head {
  margin: 0 0 10.4167vw 0;
}

.div-middle {
  margin: 14.5833vw 0 2.6042vw 0;
  text-align: center;
}

.middle-text {
  font-weight: bold;
  font-size: 2.5000vw;
  text-align: center;
}
.centered-container{
  display: flex; /* 플렉스 박스 사용 */
  justify-content: center; /* 수평 가운데 정렬 */
  align-items: center; /* 수직 가운데 정렬 */
  flex-direction: column;
}
.grid-container{
  display: grid;
  grid-template-columns: repeat(2, minmax(7.8125vw, 1fr)); /* 최소 7.8125vw, 최대 1fr */
  grid-template-rows: repeat(2, auto); /* 행의 크기 자동 조정 */
  gap: 2.0833vw; /* 카드 사이의 간격 */
  width: 100%; /* 그리드의 전체 너비 */
  padding: 0.5208vw; /* 모서리와 그리드 사이의 간격 */
  box-sizing: border-box; /* 패딩을 포함한 전체 너비 계산 */
  margin-bottom: 10.4167vw;
}
.img-card{
  width: 100%;
}
.co-img{
  width: 100%;
}
.middle-text-end{
  margin-bottom: 15.6250vw;
}
.middle-img-container{
  width: 100%;
  height: 26.0417vw;
  background-size: cover; /* 비율을 유지하며 영역을 채움 */
  background-position: center; /* 이미지를 중앙에 위치 */
  background-repeat: no-repeat;
}
.img1{
  background-image: url('/img/product/scada/system_banner01.jpg');
}
.img2{
  background-image: url('/img/product/scada/system_banner02.jpg');
}
.img3{
  background-image: url('/img/product/scada/system_banner03.jpg');
}
.img-box{
  display: flex;
}
.middle-img-head{
  width: 50%;
  font-size: 2.5000vw;
  color: white;
  margin-top: 5.2083vw;
  font-weight: bold;
}
.middle-img-head-sub{
  font-size: 1.4583vw;
}
.middle-img-content{
  color: white;
  font-size: 1.4583vw;
  margin-top: 5.2083vw;
  line-height: 2; /* 줄 간격을 1.5배로 설정 */
}
.end-container{
  background-color: #dddddd;
  padding: 10.4167vw 0 10.4167vw 0;
}
.end-title{
  font-weight: bold;
  font-size: 2.1875vw;
  margin-bottom: 5.2083vw;
}
.end-img-container{
  display: grid;
  grid-template-columns: repeat(4, minmax(7.8125vw, 1fr)); /* 최소 7.8125vw, 최대 1fr */
  grid-template-rows: repeat(4, auto); /* 행의 크기 자동 조정 */
  gap: 2.0833vw; /* 카드 사이의 간격 */
  width: 100%; /* 그리드의 전체 너비 */
  box-sizing: border-box; /* 패딩을 포함한 전체 너비 계산 */

}
.grid-container-m{
  display: none;
}
@media (max-width: 540px) {
  .grid-container{
    display: none;
  }
  .main-container{
    width: 92.5926vw;
  }
  .first-image{
    display: none;
  }
  .div-head{
    margin: 11.1111vw 0 0 0;
  }
  .head-text{
    font-size: 4.0741vw;
  }
  .sub-title-m{
    display:block;
    margin: 0 0 5.5556vw 0;
  }
  .head-sub-text{
    font-size: 3.7037vw;
  }
  .middle-text{
    font-size: 4.0741vw;
  }
  .grid-container-m{
    display: block;
    margin: 5.5556vw 0 11.1111vw 0;
  }
  .middle-img-head{
    width: 100%;
    font-size: 5.1852vw;
  }
  .middle-img-content{
    font-size: 3.7037vw;
    margin-top: 5.5556vw;
    line-height: 1.3; /* 줄 간격을 1.5배로 설정 */
  }
  .img-box{
    flex-direction: column;

  }
  .middle-img-container{
    height: 48.1481vw;
  }
  .middle-img-head-sub{
    font-size: 2.9630vw;
  }
  .end-title{
    font-size: 5.9259vw;
  }
  .img-card-m{
    width: 96.2963vw;
  }
}
</style>