import { onMounted, onUnmounted } from 'vue';

/**
 * useIntersectionObserver
 *
 * Vue 컴포넌트에서 IntersectionObserver를 사용하여 요소가 뷰포트에 들어왔을 때
 * 클래스('show')를 추가하는 커스텀 훅. 애니메이션이 적용된 후 해당 요소에 대한
 * 관찰을 중지하여 성능을 최적화합니다.
 *
 * @param {Array} targetRefs - 관찰할 대상 요소들의 ref 배열.
 * @param {Object} options - IntersectionObserver의 옵션 객체. 기본적으로 threshold가 0.8로 설정됩니다.
 */
export function useIntersectionObserver(targetRefs, options = { threshold: 0.8 }) {
    /**
     * handleIntersect
     *
     * IntersectionObserver의 콜백 함수로, 요소가 뷰포트에 들어왔을 때 'show' 클래스를
     * 추가하고, 해당 요소에 대한 관찰을 중지합니다.
     *
     * @param {Array} entries - IntersectionObserverEntry 객체 배열.
     * @param {IntersectionObserver} observer - 현재 관찰 중인 IntersectionObserver 인스턴스.
     */
    const handleIntersect = (entries, observer) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                // 요소가 뷰포트에 들어오면 'show' 클래스 추가
                entry.target.classList.add('show');
                // 애니메이션 적용 후 관찰 중지
                observer.unobserve(entry.target);
            }
        });
    };

    /**
     * onMounted
     *
     * Vue의 onMounted 훅에서 IntersectionObserver를 설정합니다.
     * 모든 대상 ref에 대해 관찰을 시작합니다.
     */
    onMounted(() => {
        const observer = new IntersectionObserver(handleIntersect, options);

        // targetRefs 배열에 있는 각 ref에 대해 관찰 시작
        targetRefs.forEach(ref => {
            if (ref.value) {
                observer.observe(ref.value);
            }
        });

        // 컴포넌트가 언마운트될 때 관찰 중지
        onUnmounted(() => {
            observer.disconnect();
        });
    });
}

/**
 * initializeIntersectionObserver
 *
 * 직접 호출 가능한 함수로, 필요할 때마다 IntersectionObserver를 설정하고 관찰을 시작할 수 있습니다.
 *
 * @param {Array} targetRefs - 관찰할 대상 요소들의 ref 배열.
 * @param {Object} options - IntersectionObserver의 옵션 객체. 기본적으로 threshold가 0.8로 설정됩니다.
 * @returns {Function} stopObserving - 관찰을 중지하는 함수를 반환합니다.
 */
export function initializeIntersectionObserver(targetRefs, options = { threshold: 0.8 }) {
    const observer = new IntersectionObserver((entries, observerInstance) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                entry.target.classList.add('show');
                observerInstance.unobserve(entry.target);
            }
        });
    }, options);

    // targetRefs 배열에 있는 각 ref에 대해 관찰 시작
    targetRefs.forEach(ref => {
        console.log("ref.value : ", ref);
        console.log("ref.value : ", ref.value);
        if (ref.value) {
            observer.observe(ref.value);
        }
    });

    // 관찰을 중지하는 함수 반환
    return () => {
        observer.disconnect();
    };
}
