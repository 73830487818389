<template>
  <div class="header-all" :style="headerStyle" :class="{ 'home-header': isHome, 'default-header': !isHome }">
    <div  class="header-menu ">
      <Menubar :model="items">
        <template #start>
          <img alt="Logo" src="/img/logo/kahu_logo_white.png" class="custom-logo" @click="goHome">
        </template>
        <template #end>
        </template>
      </Menubar>
    </div>

    <div ref="animatedElement" v-show="isHome" class="fade-in-down title-container">
      <p class="main-title">AUTOMATION</p>
      <p class="main-sub-title">
        <span>가후이엔씨는 '자동화'라는</span>
        <span><br class="br-m" />&nbsp;큰 바다의 등대로 서 있겠습니다.</span>
      </p>
    </div>

    <div v-show="!isHome" class="header-nav">
      <div ref="menubarElement" class="header-left fade-in-up">
        <div class="main-topic">
          <span>{{ mainTopic }}</span>
        </div>
        <div class="sub-topic">
          <span>{{ subTopic }}</span>
        </div>
      </div>
      <div class="header-right">
        <Breadcrumb :home="home" :model="breadcrumbItems">
          <template #item="{ item, props }">
            <router-link v-if="item.route" v-slot="{ href, navigate }" :to="item.route" custom>
              <a :href="href" v-bind="props.action" @click="navigate">
                <span :class="[item.icon, 'text-color']"/>
                <span class="text-primary font-semibold">{{ item.label }}</span>
              </a>
            </router-link>
            <span v-else>
              <span class="text-surface-700 dark:text-surface-0">{{ item.label }}</span>
            </span>
          </template>
        </Breadcrumb>
      </div>
    </div>
    <div class="m-topic">
      <div class="main-topic">
        <span>{{ mainTopic }}</span>
      </div>
      <div class="sub-topic">
        <span>{{ subTopic }}</span>
      </div>
    </div>
  </div>
</template>
<script setup>
import Menubar from 'primevue/menubar';
import Breadcrumb from 'primevue/breadcrumb';
import { ref, watch, computed, nextTick, onMounted, onBeforeUnmount } from "vue";
import { useRouter, useRoute } from 'vue-router';

const animatedElement = ref(null);
const menubarElement = ref(null);  // Menubar 애니메이션을 위한 ref

let observer = null;

const router = useRouter();
const route = useRoute();

const breadcrumbItems = ref([]);
const mainTopic = ref('');
const subTopic = ref('');
const headerStyle = ref({});

const isMobile = ref(false);

const setHeaderStyle = () => {
  const backgroundImage = isMobile.value
      ? route.meta.backgroundImage_m || ''
      : route.meta.backgroundImage || '';

  headerStyle.value = {
    backgroundImage: `url(${backgroundImage})`,
  };
};

// 화면 크기 변화에 따라 이미지를 바꾸기 위한 미디어 쿼리
const mediaQuery = window.matchMedia('(max-width: 540px)');

const handleMediaChange = (e) => {
  isMobile.value = e.matches;
  setHeaderStyle();
};

// 초기화 및 이벤트 리스너 설정
onMounted(() => {
  isMobile.value = mediaQuery.matches;
  setHeaderStyle();
  mediaQuery.addEventListener('change', handleMediaChange);
});

// 컴포넌트가 언마운트될 때 이벤트 리스너 제거
onBeforeUnmount(() => {
  mediaQuery.removeEventListener('change', handleMediaChange);
});









const isHome = computed(() => route.path === '/');

const home = ref({
  icon: 'pi pi-home',
  route: '/'
});

// 애니메이션 초기화 및 IntersectionObserver 설정
const setupIntersectionObserver = () => {
  if (observer) {
    observer.disconnect(); // 기존 observer 해제
  }

  // 요소 상태 초기화 (show 클래스 제거)
  if (animatedElement.value) {
    animatedElement.value.classList.remove('show');
  }
  if (menubarElement.value) {
    menubarElement.value.classList.remove('show');
  }
  // 새로운 IntersectionObserver 설정
  observer = new IntersectionObserver((entries) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        entry.target.classList.add('show');
        observer.unobserve(entry.target); // 한 번 애니메이션 적용 후 해제
      }
    });
  }, { threshold: 0.5 });

  if (animatedElement.value) {
    observer.observe(animatedElement.value); // 요소 관찰 시작
  }

  if (menubarElement.value) {
    observer.observe(menubarElement.value); // Menubar 애니메이션 관찰 시작
  }
};

// watch 훅을 통해 route가 변경될 때마다 호출
watch(route, async () => {
  updateBreadcrumbAndBackground();
  await nextTick();  // DOM 업데이트 후 실행
  setupIntersectionObserver();
});

// 컴포넌트가 언마운트될 때 observer 해제
onBeforeUnmount(() => {
  if (observer) {
    observer.disconnect();
  }
});


const updateBreadcrumbAndBackground = () => {
  const main = route.meta.main || '';
  const sub = route.meta.sub || '';

  breadcrumbItems.value = [
    { label: main },
    { label: sub },
  ];

  mainTopic.value = main;
  subTopic.value = sub;

  // 미디어 쿼리에 따라 배경 이미지 설정
  setHeaderStyle();
};


// 초기 로딩 시 호출
updateBreadcrumbAndBackground();

const items = ref([
  {
    label: '회사소개',
    items: [
      {
        label: 'About Us',
        command: () => router.push('/introduce/about-us')
      },
      {
        label: '대표인사말',
        command: () => router.push('/introduce/greeting')
      },
      {
        label: '회사 연혁',
        command: () => router.push('/introduce/history')
      },
      {
        label: '인재상',
        command: () => router.push('/introduce/ourPeople')
      },
      {
        label: '조직구성',
        command: () => router.push('/introduce/team-structure')
      },
      {
        label: '오시는 길',
        command: () => router.push('/introduce/directions')
      }
    ]
  },
  {
    label: '지속 가능 경영',
    items: [
      {
        label: '가후이엔씨의 ESG',
        command: () => router.push('/esg/esg-practice')
      },
      {
        label: 'Environmental',
        command: () => router.push('/esg/environmental')
      },
      {
        label: 'Social',
        command: () => router.push('/esg/social')
      },
      {
        label: 'Governance',
        command: () => router.push('/esg/governance')
      }
    ]
  },
  {
    label: '사업분야',
    items: [
      {
        label: '주요사업영역',
        command: () => router.push('/business/areas')
      },
      {
        label: '사업소개',
        command: () => router.push('/business/business-introduce')
      },
      {
        label: '사업실적',
        command: () => router.push('/business/achievements')
      },
      {
        label: '고객사',
        command: () => router.push('/business/client')
      },
      {
        label: '협력사',
        command: () => router.push('/business/partner')
      }
    ]
  },
  {
    label: '제품소개',
    items: [
      {
        label: '공정제어',
        command: () => router.push('/products/factory-control')
      },
      {
        label: 'SCADA',
        command: () => router.push('/products/scada')
      },
      {
        label: 'HW & SW',
        command: () => router.push('/products/hardware-software')
      },
      {
        label: '기타',
        command: () => router.push('/products/others')
      }
    ]
  },
  {
    label: '교육',
    items: [
      {
        label: '사이버연수원',
        command: () => window.open("https://kahuencflex.hunet.co.kr/Home")
      },
    ]
  }
]);

const goHome = () => {
  router.push('/');
  breadcrumbItems.value = [];
  mainTopic.value = '';
  subTopic.value = '';
};
</script>

<style scoped>
.header-all {
  padding: 0 10%;
  color: rgba(248, 248, 255, 0.90);

  background-size: cover; /* 'auto'에서 'cover'로 변경하여 배경 이미지가 컨테이너를 채우도록 설정 */
  background-repeat: no-repeat; /* 이미지가 반복되지 않도록 설정 */
  background-position: center center; /* 이미지의 중심을 컨테이너의 중심에 맞춤 */

  transition: background-image 1s ease-in-out, height 1.5s ease; /* height의 전환 효과 추가 */
}
.main-title{
  font-weight: bold;
  font-size: 6.25vw;
  margin-bottom: 0px;
}
.main-sub-title{
  font-size: 1.9792vw;
  margin: 0px;
}
.header-menu{
  width: 78.1250vw;
  padding-top: 1.0417vw;
  padding-bottom: 1.8958vw;
}
.home-header {
  height: 56.2500vw; /* 홈 페이지에서의 헤더 높이 */
}
.default-header {
  height: 20.8333vw; /* 다른 페이지에서의 헤더 높이 */
}
.p-breadcrumb {
  background: none;
  font-size: 1.0417vw;
}


.header-nav {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  padding: 0px 0px 1.0417vw 0.6250vw;
}

.header-left {
  display: flex;
  flex-direction: column;
  width: 73%;
}

.main-topic {
  font-size: 1.3542vw;
}

.sub-topic {
  font-size: 2.7083vw;
  font-weight: bold;
}

.header-right {
  display: flex;
  align-items: center;
}

.custom-logo {
  width: 7.8125vw;
  cursor: pointer;
}

.p-menubar {
  border: 0px;
  background: none;
  font-size: 1.0417vw;
}
.br-m{
  display: none;
}
.m-topic{
  display: none;
}

@media (max-width: 960px) {
  /* Use ::v-deep to penetrate the component's styles */
  :deep(.p-menubar) {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  :deep(.p-menubar .p-menubar-start){
    width: 95%;
  }

  :deep(.p-menubar .p-menuitem-link) {
    /* Override the text color */
    color: #fff; /* Replace with your desired color */
  }

  :deep(.p-menubar .p-menubar-button) {
    /* Override the text color */
    color: #fff; /* Replace with your desired color */
  }
  :deep(.p-menubar .p-menubar-button:hover) {
    /* Override the text color */
    color: #fff; /* Replace with your desired color */
    background-color: transparent;
  }

  :deep(.p-menubar .p-menubar-button .p-icon){
    /* Override the text color */
    width: 3.1250vw;
    height: 3.1250vw;
  }

  :deep(.p-menubar .p-menuitem-link:hover) {
    /* Override the hover state */
    background-color: #444; /* Replace with your desired color */
  }

  :deep(.p-menubar .p-menubar-root-list) {
    /* Override the text color */
    background-color: #043450; /* Replace with your desired color */
    font-size: 1.8750vw;
    border:none;
  }

  :deep(.p-menubar .p-menubar-root-list .p-menubar-item .p-menubar-submenu) {
    /* Override the text color */
    background-color: #043450; /* Replace with your desired color */
    font-size: 1.8750vw;
  }
  :deep(.p-menubar .p-menubar-root-list .p-menubar-item .p-menubar-submenu .p-menubar-item .p-menubar-item-content .p-menubar-item-link .p-menubar-item-label) {
    color: white;
  }
}

@media (max-width: 540px) {
  .default-header {
    height: 55.5556vw; /* 다른 페이지에서의 헤더 높이 */
  }
  .header-nav{
    display: none;
  }
  .m-topic{
    display: flex;
  }
  .header-all{
    padding: 0;
    color: rgba(248, 248, 255, 0.79);

    background-size: cover; /* 'auto'에서 'cover'로 변경하여 배경 이미지가 컨테이너를 채우도록 설정 */
    background-repeat: no-repeat; /* 이미지가 반복되지 않도록 설정 */
    background-position: center center; /* 이미지의 중심을 컨테이너의 중심에 맞춤 */

    transition: background-image 1s ease-in-out, height 1.5s ease; /* height의 전환 효과 추가 */
  }
  .home-header {
    height: 177.7778vw; /* 홈 페이지에서의 헤더 높이 */
  }
  .header-menu{
    width: 100%;
  }
  .custom-logo{
    width: 22.2222vw;
  }
  /* Use ::v-deep to penetrate the component's styles */
  :deep(.p-menubar) {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  :deep(.p-menubar .p-menubar-start){
    width: 95%;
  }

  :deep(.p-menubar .p-menuitem-link) {
    /* Override the text color */
    color: #fff; /* Replace with your desired color */
  }

  :deep(.p-menubar .p-menubar-button) {
    /* Override the text color */
    color: #fff; /* Replace with your desired color */
  }
  :deep(.p-menubar .p-menubar-button:hover) {
    /* Override the text color */
    color: #fff; /* Replace with your desired color */
    background-color: transparent;
  }

  :deep(.p-menubar .p-menubar-button .p-icon){
    /* Override the text color */
    width: 5.5556vw;
    height: 5.5556vw;
  }

  :deep(.p-menubar .p-menuitem-link:hover) {
    /* Override the hover state */
    background-color: #444; /* Replace with your desired color */
  }

  :deep(.p-menubar .p-menubar-root-list) {
    /* Override the text color */
    background-color: #043450; /* Replace with your desired color */
    font-size: 3.3333vw;
    border:none;
  }

  :deep(.p-menubar .p-menubar-root-list .p-menubar-item .p-menubar-submenu) {
    /* Override the text color */
    background-color: #043450; /* Replace with your desired color */
    font-size: 3.3333vw;
  }
  :deep(.p-menubar .p-menubar-root-list .p-menubar-item .p-menubar-submenu .p-menubar-item .p-menubar-item-content .p-menubar-item-link .p-menubar-item-label) {
    color: white;
  }
  .title-container{
    display: flex;
    align-items: center;
    flex-direction: column;

  }
  .main-title{
    color: white;
    font-size: 12.0370vw;
  }
  .main-sub-title{
    padding: 0 18.5185vw 0 18.5185vw;
    color: white;
    font-size: 4.8148vw;
  }
  .br-m{
    display: inline;
  }
  .m-topic {
    display: flex;
    flex-direction: column;
    width: 100vw;
    align-items: center;
  }
  .main-topic{
    font-size: 7.0370vw;
  }
  .sub-topic{
    font-size: 9.6296vw;
  }
}

</style>
