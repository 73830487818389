import BusinessAreas from "@/views/business/BusinessAreas.vue";
import BusinessIntroduce from "@/views/business/BusinessIntroduce.vue";
import BusinessAchievements from "@/views/business/BusinessAchievements.vue";
import BusinessClient from "@/views/business/BusinessClient.vue";
import BusinessPartner from "@/views/business/BusinessPartner.vue";

const businessRoutes = [
    {
        path: "areas",
        component: BusinessAreas,
        meta: { main: '사업분야', sub: '주요사업영역' }
    },
    {
        path: "business-introduce",
        component: BusinessIntroduce,
        meta: { main: '사업분야', sub: '사업소개' }
    },
    {
        path: "achievements",
        component: BusinessAchievements,
        meta: { main: '사업분야', sub: '사업실적' }
    },
    {
        path: "client",
        component: BusinessClient,
        meta: { main: '사업분야', sub: '고객사' }
    },
    {
        path: "partner",
        component: BusinessPartner,
        meta: { main: '사업분야', sub: '협력사' }
    }
];

export default businessRoutes;
