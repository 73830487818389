<script setup>
import { onMounted, ref } from "vue";

const googleMap = ref(null);  // Google Maps를 표시할 div 요소의 ref

// API 키
const googleMapsApiKey = "AIzaSyCH_iBF2DZbEZcFnWaSlPvGKz_Mf3drJvc";

const loadGoogleMapsScript = () => {
  return new Promise((resolve, reject) => {
    if (window.google && window.google.maps) {
      resolve(); // 이미 로드된 경우
    } else {
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=${googleMapsApiKey}`;
      script.async = true;
      script.defer = true;
      script.onload = resolve;
      script.onerror = reject;
      document.head.appendChild(script);
    }
  });
};

const initializeMap = () => {
  const mapOptions = {
    center: { lat: 37.4427, lng: 127.1773 }, // 예시로 서울의 위도, 경도를 사용
    zoom: 15,
  };
  googleMap.value = new window.google.maps.Map(document.getElementById("googleMap"), mapOptions);

  // 원하는 위치에 마커 추가
  const marker = new window.google.maps.Marker({
    position: { lat: 37.4427, lng: 127.1773 }, // 예시로 서울의 위도, 경도를 사용
    map: googleMap.value,
    title: "가후이엔씨",
  });
};

// 컴포넌트가 마운트될 때 Google Maps API를 로드하고 지도 초기화
onMounted(async () => {
  try {
    await loadGoogleMapsScript();
    initializeMap();
  } catch (error) {
    console.error("Google Maps API 로드에 실패했습니다:", error);
  }
});
</script>

<template>
    <div class="maps">
      <div id="googleMap" class="map"></div>
    </div>
  <div class="background-container">
    <div class="address-container">
      <div class="kahu-address">
        <div class="address-title">
          <img class="icon" src="/img/introduce/icon_map.png" alt="Main Image"/>
          <span class="address-text">회사주소</span>
        </div>
        <table>
          <tbody>
          <tr class="tr1">
            <td class="td1">신주소<span class="m-visible">(도로명주소)</span></td>
            <td class="td2">경기도 성남시 중원구 사기막골로 159 (금강하이테크2차) 2층 201호</td>
          </tr>
          <tr class="tr2">
            <td class="td1">구주소</td>
            <td class="td2">경기도 성남시 중원구 상대원1동 138-1 (금강하이테크2차) 2층 201호</td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="kahu-address">
        <div class="address-title-vehicle">
          <img class="icon-vehicle" src="/img/introduce/icon_car.png" alt="Main Image"/>
          <span class="address-text">자가용 이용 시</span>
        </div>
        <div class="address-content">
          <span class="address-content-content">자가용 이용 시, 네비게이션에서 '가후이엔씨' 또는 '사기막골로 159'를 검색하여 이동하시기 바랍니다. <br/>
            금강하이테크2차 건물은 일방통행 도로 옆에 위치하여 주차장 안내 표시판을 반드시 확인하셔야 합니다.</span>
        </div>
      </div>
      <div class="divider"></div>
      <div class="kahu-address">
        <div class="address-title-vehicle">
          <img class="icon-vehicle" src="/img/introduce/icon_bus.png" alt="Main Image"/>
          <span class="address-text">대중교통 이용 시</span>
        </div>
        <div class="address-content">
        <span class="address-content-content">지하철 8호선 남한산성입구역 2번 출구 <br/>
          2번 출구 앞 버스정류장에서 51번 또는 333번 버스 탑승 후 사기막골 종점에서 하차 <br/>
          약 300m 직진 금강하이테크 2차 건물을 확인하시기 바랍니다.</span>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.background-container{
  /* 배경 이미지 설정 */
  background-image: url('/img/introduce/bg04_img.jpg');
  background-repeat: no-repeat; /* 배경 이미지가 반복되지 않도록 설정 */
  background-position: right center; /* 배경 이미지가 오른쪽 중앙에 위치 */
  background-size: contain; /* 배경 이미지가 비율을 유지하며 컨테이너에 맞도록 조정 */
}
.address-container {
  display: flex;
  justify-content: center;
  align-items: baseline;
  flex-direction: column;
  width: 100%;        /* 컨테이너의 기본 너비를 100%로 설정 */
  max-width: 78.1250vw;  /* 최대 너비를 1500px로 제한 */
  margin: 0 auto;     /* 가운데 정렬 */


}
.maps{
  margin-bottom: 5.2083vw;
}
.map{
  width: 100%;
  height: 26.0417vw;
}
.kahu-address {
  margin-bottom: 10%;
}

.address-title {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  gap: 1.0417vw;
}

.address-title-vehicle {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1.0417vw;
}

.icon {
  width: 2.0833vw;
  height: auto;
}

.icon-vehicle {
  width: 3.1250vw;
  height: auto;
}

.address-text {
  font-size: 3.1250vw;
}

.tr1 {
}

td {
  font-size: 1.6667vw;
}

.td1 {
  background-color: rgba(136, 136, 136, 0.8);
  padding: 0.2604vw;
  border-radius: 5px;
}

.td2 {
  background-color: rgba(136, 136, 136, 0.42);
  padding: 0.2604vw;
  border-radius: 5px;
}

.tr2 {

}

.address-content {
  margin: 5% 0% 5% 0%;
}

.address-content-title {
  font-size: 2.5000vw;
  margin-bottom: 0.5208vw;
  color: #3b6ea5;
}

.address-content-content {
  font-size: 1.6667vw;
}
.divider {
  width: 100%;
  height: 0.1042vw; /* 구분선의 두께 */
  background-color: #ccc; /* 구분선의 색상 */
  margin: 0 0 10% 0; /* 구분선 위아래의 여백 */
  align-self: stretch; /* 부모의 너비를 따라 늘어나게 설정 */

}
@media (max-width: 540px) {
  .map{
    height: 55.5556vw;
  }
  .address-container{
    max-width: 92.5926vw;
  }
  .icon{
    width: 2.7778vw;
  }
  .address-text{
    font-size: 5.1852vw;
  }
  .m-visible{
    display: none;
  }
  td{
    font-size:2.9630vw;
  }
  .td1 {
    width: 20%;
    padding: 2.7778vw;
    text-align:center;
  }

  .td2 {
    padding: 1.8519vw 7.4074vw 1.8519vw 7.4074vw;
    font-weight: bold;
    font-size:3.3333vw;
  }
  .address-content-title{
    font-size: 4.4444vw;
    margin-bottom: 1.8519vw;
  }
  .address-content-content{
    font-size: 3.3333vw;
  }
}
</style>