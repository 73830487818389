import FactoryControl from "@/views/products/FactoryControl.vue";
import SCADA from "@/views/products/SCADA.vue";
import HardwareSoftware from "@/views/products/HardwareSoftware.vue";
import Others from "@/views/products/Others.vue";

const productsRoutes = [
    {
        path: "factory-control",
        component: FactoryControl,
        meta: { main: '제품소개', sub: '공정제어' }
    },
    {
        path: "scada",
        component: SCADA,
        meta: { main: '제품소개', sub: 'SCADA' }
    },
    {
        path: "hardware-software",
        component: HardwareSoftware,
        meta: { main: '제품소개', sub: 'HW & SW' }
    },
    {
        path: "others",
        component: Others,
        meta: { main: '제품소개', sub: '기타' }
    }
];

export default productsRoutes;
