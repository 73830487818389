
<template>
  <div class="ts-container">
    <img ref="firstImage" class="ts-bg-img fade-in-up" src="/img/public_img/bg_02_img.png" alt="Background Image"/>
    <img class="ts-main-img" src="/img/introduce/img06.png" alt="Main Image"/>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { useIntersectionObserver } from '@/utils/useIntersectionObserver';

const firstImage = ref(null);

useIntersectionObserver([firstImage]);
</script>

<style scoped>
.ts-container{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.ts-bg-img, .ts-main-img {
  max-width: 100%;  /* 부모 요소의 가로 크기를 넘지 않도록 설정 */
  height: auto;     /* 세로 크기는 자동 조절 */
}
.ts-main-img{
  margin-bottom: 20%;
}
@media (max-width: 540px) {
  .ts-bg-img{
    display: none;
  }
  .ts-main-img{
    padding-top: 9.2593vw;
  }
}
</style>
