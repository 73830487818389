
<template>
  <img ref="firstImage" class="fade-in-up business-img"  src="/img/public_img/bg_02_img.png" alt="Logo"/>
  <div class="main-container">
    <p class="business-head">
      <span class="business-head-big">Scope of Service,</span>
      가후이엔씨와 만나는 순간부터 문제 해결의 길이 보입니다!</p>
    <p class="business-head-content">
      자동화 분야라면 누구에게도 뒤지지 않는 경험과 기술력이 가후이엔씨에 있습니다.<br/>
      고객의 성공 가후이엔씨가 함께합니다.
    </p>
  </div>
  <img class="business-img" style=" margin-bottom: 50px;" src="/img/business/img_banner.jpg" alt="Logo"/>
  <img class="business-img-m" style=" margin-bottom: 50px;" src="/img/business/m/m_sub3_img03.jpg" alt="Logo"/>
  <div class="main-container">
    <div class="content-container">
      <p class="content-header">1. Analysis & Consulting</p>
      <p class="content-content">
        소형에서 대형에 이르는 설비 자동화 시스템 구축과 경험을 바탕으로,<br/>
        최신의 기술 동향을 접목하여 귀사의 공정 개선과 자동화 전략 계획 수립 지원 그리고 최적의 시스템 구축을 위한<br/>
        과학적인 분석으로 고객이 만족할 때까지 고객이 부르는 곳이라면 어디라도 달려갑니다.
      </p>
      <div class="content-tags">
        <span class="content-tag">Process Analysis</span>
        <span class="content-tag">Control System Analysis (PLC/DCS System, Up-grade, Integration)</span>
        <span class="content-tag">System Configuration (Replace, Modification, Addition)</span>
        <span class="content-tag">Supply Work Scope and Schedule</span>
      </div>
    </div>
    <div class="content-container">
      <p class="content-header">2. Engineering & Design</p>
      <p class="content-content">
        설비 특성에 맞는 계기 선정과 공정의 감시와 제어를 위한 시스템 선정 그리고 설치 및 시공, 운전을 위한
        설계와 도면을 작성하고 제공합니다.
      </p>
      <div class="content-tags">
        <span class="content-tag">P & ID’s Drawing Support</span>
        <span class="content-tag">Operation & Control Concept Design</span>
        <span class="content-tag">System Configuration Design</span>
        <span class="content-tag">Database and Software Core Design</span>
        <span class="content-tag">Interface Design of Other System and Device</span>
        <span class="content-tag">Control Logic Diagram Drawing</span>
        <span class="content-tag">Control Panel Drawing</span>
        <span class="content-tag">Plot plan and Hook-up Drawing of Instrument</span>
        <span class="content-tag">Cable Schedule and Connection Drawing</span>
      </div>
    </div>
    <div class="content-container">
      <p class="content-header">3. Development & Construction</p>
      <p class="content-content">
        당사가 개발하고 유지와 보수가 용이한 각각의 기능별, 장치별 Function Block 및 Library를 이용하여 선정된 시스템의 하드웨어와
        소프트웨어를 하나로 결합하고, 시뮬레이션을 통하여 완벽한 시스템을 제공합니다.
      </p>
      <div class="content-tags">
        <span class="content-tag">Group Division and Interlock Diagram</span>
        <span class="content-tag">Control Station Memory Mapping</span>
        <span class="content-tag">Sequence and Loop Control Programming</span>
        <span class="content-tag">Data-Base Design</span>
        <span class="content-tag">Alarm, Event, Trend Design</span>
        <span class="content-tag">Static and Dynamic Object, Mimic Drawing</span>
        <span class="content-tag">Panel Construction and Simulation Test</span>
      </div>
    </div>
    <div class="content-container">
      <p class="content-header">4. Supply & Installation</p>
      <p class="content-content">
        시스템의 적기 공급 및 설치를 통하여 고객이 요구하는 최적의 조건을 제공할 것입니다.
      </p>
      <div class="content-tags">
        <span class="content-tag">Hardware & Software Supply and Installation</span>
      </div>
    </div>
    <div class="content-container">
      <p class="content-header">5. Commissioning & Field Service</p>
      <p class="content-content">
        풍부한 경험과 기술을 겸비한 엔지니어들이 제공하는 시운전 서비스는 귀사의 프로젝트를 계획된 공기 내에 성공적으로 이끌 것 입니다.
      </p>
      <div class="content-tags">
        <span class="content-tag">Instrument Calibration and Signal Check</span>
        <span class="content-tag">Commissioning and Supervision</span>
        <span class="content-tag">System Regulation</span>
        <span class="content-tag">Non-Load Test</span>
        <span class="content-tag">Load Test</span>
      </div>
    </div>
    <div class="content-container">
      <p class="content-header">6. Tuning & Optimization</p>
      <p class="content-content">
        주요 제어 장비에 대한 제어 로직 조정 및 최적화를 통하여 생산성 향상과 생산량 증대
        그리고 전력 원단위 절감을 기대합니다.
      </p>
      <div class="content-tags">
        <span class="content-tag">Open Loop Control</span>
        <span class="content-tag">Close Loop Control</span>
      </div>
    </div>
    <div class="content-container">
      <p class="content-header">7. Education & Support</p>
      <p class="content-content">
        시스템의 안정적인 운영을 위한 On-Site 교육 및 Maker 교육을 지원합니다. <br/>
        더불어 구축된 시스템의 성능 개선 및 예방 점검을 위한 유지보수 서비스도 제공합니다.
      </p>
    </div>
  </div>
</template>

<script setup>
import {ref} from 'vue';
import {useIntersectionObserver} from '@/utils/useIntersectionObserver';

const firstImage = ref(null);

useIntersectionObserver([firstImage]);

</script>

<style scoped>
p{
  margin: 0;
}
.business-head{
  font-weight: bold;
  font-size: 2.0833vw;
}
.business-head-big{
  font-size: 2.6042vw;
}
.business-head-content{
  font-size: 1.5625vw;
  margin-bottom: 2.6042vw;
}
.content-container{
  padding: 3.1250vw;
  background-color: #dddddd;
  border-radius: 1.5625vw;
  margin-top: 5.2083vw;
  margin-bottom: 5.2083vw;
}
.content-header{
  font-size: 2.6042vw;
  font-weight: bold;
}
.content-content{
  font-size: 1.5625vw;
}
.content-tags{
  display: flex;
  gap: 1.0417vw;
  flex-wrap: wrap;
  margin: 2.6042vw 0 0 0;
}
.content-tag{
  padding: 0.5208vw 1.5625vw 0.5208vw 1.5625vw;
  background-color: #565656;
  color: white;
  border-radius: 1.5625vw;
  font-size: 1.5625vw;
  text-align: center;
}
.business-img{
  width: 100%;
}
.business-img-m{
  display: none;
}
@media (max-width: 540px) {
  .main-container{
    width: 92.5926vw;
  }
  .business-head{
    font-size: 6.1111vw;
    margin: 9.2593vw 0 0 0;
  }
  .business-head-content{
    margin: 5.5556vw 0 5.5556vw 0;
    font-size: 4.0741vw;
  }
  .business-head-big{
    display: block;
    font-size: 6.6667vw;
  }
  .business-img{
    display: none;
  }
  .business-img-m{
    display: block;
    width: 100%;
  }
  .content-container{
    padding: 3.7037vw;
  }
  .content-header{
    font-size: 4.4444vw;
  }
  .content-content{
    font-size: 4.4444vw;
  }
  .content-tag{
    padding: 1.8519vw 3.7037vw 1.8519vw 3.7037vw;
    font-size: 3.7037vw;
    border-radius: 9.2593vw;
    text-align: left;
  }
}
</style>