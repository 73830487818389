<script setup>

</script>

<template>
  <div class="esg-governance-container">
    <div class="content-block content-block-m">
      <p class="esg-head" >지배구조</p>
      <p class="esg-head-sub" >Governance</p>
      <p class="esg-head-content" >가후이엔씨는 건전하고 투명한 지배구조로 건전한 산업발전에 기여하고자 노력합니다.</p>
      <img src="/img/esg/mid_banner04.jpg" alt="Image" class="esg-img"/>
    </div>
  </div>
      <img src="/img/esg/m/m_esg_img_04.jpg" alt="Image" class="esg-img-m"/>
  <div class="esg-governance-container esg-governance-container-m">
    <div class="content-block">
      <div class="esg-content-title-container">
        <p class="content-title">공정거래</p>
        <p class="content-title-sub">자유경쟁의 원칙에 따라 공정하고 자유로운 시장경제 질서를 존중하고 경쟁사와 공정하게 경쟁합니다. 또한 거래처, 협력업체 등과의 거래관계에 있어서 상호신뢰와 존중을 바탕으로 공정한 거래를 통한 상생을 추구합니다.</p>
      </div>
      <div class="esg-content-container">
        <p class="esg-content">1) 총칙</p>
        <p class="esg-content">- 본 공정거래 규정은 모든 사업과 영업활동을 수행함에 있어 구성원이 법규를 철저히 준수하고 투명하고 공정한 업무 수행으로 건전한 산업 발전에 기여함을 목적으로 합니다.</p>
        <p class="esg-content">- 본 공정거래 규정은 가후이엔씨(주)의 모든 임직원에게 적용됩니다.</p>
        <p class="esg-content">- 누구든지  가후이엔씨(주)의 임직원이 본 공정거래 규정을 위반한 사실을 인지한 때에는 이메일, 팩스, 전화 등을 이용하여 감사실, 준법관리인 및 신고센터 전담자(전담부서)에 신고할 수 있으며, 위반 시 사규에 따라 조치 및 처벌합니다.</p>
        <br/>
        <p class="esg-content">2) 자유경쟁 추구</p>
        <p class="esg-content">- 책임과 투명성을 기본으로 시장경제질서를 존중하며 공정거래 관습에 적극 참여합니다.</p>
        <p class="esg-content">- 부당한 공동행위 또는 공정성과 신뢰성을 훼손하는 불공정한 거래행위를 근절합니다.</p>
        <p class="esg-content">- 협력업체와의 모든 거래는 상호 대등한 위치에서 이루어지며, 정당한 이유 없이 거래의 거절, 중단, 또는 거래 내용 및 조건의 제한을 금지합니다.</p>
        <p class="esg-content">- 협력업체를 대상으로 부당한 기술자료의 요구를 금지합니다. 협력업체의 기술이나 자산을 사용할 경우 반드시 승인 하에 사용합니다.</p>
        <br/>
        <p class="esg-content">3) 법규의 준수</p>
        <p class="esg-content">- 경영활동이 이루어지는 국가 및 지역의 거래 관습을 존중하고, 모든 구성원이 자발적으로 제반 법규를 준수합니다.</p>
        <p class="esg-content">- 불공정한 약관의 사용과 허위·과장 광고 및 표시를 금지합니다.</p>
        <p class="esg-content">- 협력회사의 의사에 반하는 구입 행위를 강제하거나, 거래의 대가로 경제적 이익의 요구를 금지합니다.</p>
        <p class="esg-content">- 공정거래 관련 법령이나 내규 위반행위에 대해 지시, 승인, 방조 등을 철저히 금지합니다. 위반 우려가 있다고 판단되는 경우 감사실, 준법관리인 및 신고센터 등에 즉시 제보합니다.</p>
        <br/>
        <p class="esg-content">4) 상생협력</p>
        <p class="esg-content">- 구비된 자격에 부합하는 모든 협력회사에게 동등한 참여기회를 부여합니다. 협력회사는 공정한 기준을 적용하여 객관적이고 합리적으로 선정합니다.</p>
        <p class="esg-content">- 모든 거래의 거래조건 및 거래절차에 대해 사전에 충분히 협의를 거쳐야 하며, 그 과정에서 획득한 영업비밀을 준수합니다.</p>
        <p class="esg-content">- 협력회사를 대상으로 다양한 지원과 경영 협력을 통해 공동의 번영을 추구합니다.</p>
        <p class="esg-content">- 협력회사가 임직원 인권 보호, 공급망 투명성 확보 등 인권 및 환경에 위배되는 행위를 하지 않도록 최선을 다하여 지원합니다.</p>
      </div>
    </div>
    <div class="esg-content-title-container">
      <p class="content-title">사회공헌</p>
      <p class="content-title-sub">가후이엔씨는 ESG 기반 사회적 책임을 가지고 나눔의 정신을 신천하고 더 나은 세상으로의 변화에 기여하고자 꾸준한 기부와 사회공헌 활동을 펼치고 있습니다!</p>
    </div>
  </div>
  <div style="background-color: #dddddd">
    <div class="img-container esg-governance-container-m">
      <img src="/img/esg/img_certification.jpg" alt="Image" class="img_certification esg-img"/>
      <img src="/img/esg/m/m_esg_img_05.jpg" alt="Image" class="img_certification esg-img-m"/>
      <div class="img-description-container">
        <p class="cert-number">제 6339호</p>
        <p class="cert-subtitle"><span class="cert-title">포장증</span> Certification of Medal</p>
        <p class="cert-company">가후이엔씨주식회사</p>
        <p class="cert-content">위는 숭고한 사랑과 봉사의 정신을 발휘하여 인류의 복지 증진에 기여한 공이 크므로 대한적십자사 포상운영규정 제 14조의 규정에 의하여 적십자 회원유공장 명예장을 드립니다.</p>
        <p class="cert-date">2023.11.30</p>
        <p class="cert-chairperson">대한적십자사 회장 김철수</p>
        <p class="cert-note">이 증은 적십자포장대장에 기입함</p>
        <p class="cert-note">사무총장 이상천</p>
      </div>
    </div>
  </div>
  <div class="esg-governance-container esg-governance-container-m">
    <div class="content-block">
      <div class="esg-content-title-container">
        <p class="content-title">윤리경영</p>
        <p class="content-title-sub">가후이엔씨는 회사의 발전과 이익 추구를 바탕으로 투명하고 공정한 업무를 수행하고 우선시합니다. 또한 다양한 이해관계자들의 기대와 요구를 적극적으로 대응하며 회사의 발전과 이익을 바탕으로 투명하고 공정한 업무를 수행하고 추구합니다.</p>
      </div>
      <div class="esg-content-container background-img-container">
        <img src="/img/esg/m/m_esg_img_06.png" alt="Image" class="esg-img-m-06"/>
        <p class="esg-content">■ 윤리경영 원칙</p>
        <p class="esg-content">1. 임직원은 높은 윤리관과 준법정신을 바탕으로 업무수행에 있어서 윤리경영을 실천합니다.</p>
        <p class="esg-content">2. 임직원은 회사의 재산 및 정보자산을 본인의 사적인 목적을 위해서 사용하지 아니하며, 본인과 회사의 이해가 상충되는 업무 수행 시 회사 이익을 우선합니다.</p>
        <p class="esg-content">3. 임직원은 이해관계자로부터의 금품, 향응, 개인적 편의를 수수하지 않습니다.</p>
        <p class="esg-content">4. 임직원은 준법정신을 바탕으로 모든 상거래에 있어 제반 법규를 준수합니다.</p>
        <p class="esg-content">5. 회사는 임직원 개개인의 기본권을 존중하며, 임직원 각자를 독립되고 존엄한 인격체로서 대합니다.</p>
        <p class="esg-content">6. 회사는 실력 및 성과에 따른 공정한 평가/보상체계를 통해 근로의욕을 고취시킵니다.</p>
        <br/>
        <p class="esg-content">1) 고객에 대한 윤리</p>
        <p class="esg-content">- 신용과 정직성을 바탕으로 고객으로부터 신뢰를 얻을 수 있도록 최선을 다합니다.</p>
        <p class="esg-content">- 양질의 제품과 서비스를 통하여 고객의 만족을 제고할 수 있도록 합니다.</p>
        <p class="esg-content">- 업무와 관련되어 취득한 고객의 정보를 보호합니다.</p>
        <br/>
        <p class="esg-content">2) 주주에 대한 윤리</p>
        <p class="esg-content">- 기업가치를 제고하고 이익을 실현하여 주주의 권익 보호를 위하여 최선을 다합니다.</p>
        <p class="esg-content">- 주주에게 유용한 정보를 공시 및 IR 등의 채널을 통하여 적시에 제공합니다.</p>
        <p class="esg-content">- 주주의 정당한 권리 행사를 보장하며, 주주의 제안 및 결정을 존중합니다.</p>
        <br/>
        <p class="esg-content">3) 사회와 국가에 대한 윤리</p>
        <p class="esg-content">- 회사는 효율적인 경영과 혁신을 바탕으로 가치를 창출하여 기업시민으로서 경제에 기여합니다.</p>
        <p class="esg-content">- 회사는 환경 및 보건안전, 인권 등의 국내외 규범 및 법규를 준수합니다.</p>
        <p class="esg-content">- 회사는 사회의 일원으로서 사회적 책임을 다하고 건전한 사회발전에 공헌합니다.</p>
        <br/>
        <p class="esg-content">4) 윤리규범 적용</p>
        <p class="esg-content">- 본 윤리규범은 가후이엔씨의 모든 임직원에게 적용됩니다.</p>
        <p class="esg-content">- 누구든지  가후이엔씨의 임직원이 본 윤리규범을 위반한 사실을 인지한 때에는 이메일, 팩스, 전화 등을 이용하여 감사실, 준법관리인 및 신고센터 전담자(전담부서)에 신고할 수 있으며, 위반 시 사규에 따라 조치 및 처벌합니다.</p>
         </div>
    </div>
    <div class="content-block">
      <div class="esg-content-title-container">
        <p class="content-title">정보보안</p>
        <p class="content-title-sub">가후이엔씨는 사내의 정보자산 및 고객을 위한 안정적인 서비스 제공과 정보보안/개인정보보호를 우선시합니다.  ESG 경영원칙에 따라 정보보호 관리체계를 운영하여 보안 위협에 대응하고 있습니다.</p>
      </div>
      <div class="esg-content-container">
        <p class="esg-content">1) 개인정보보호 규정</p>
        <p class="esg-content">- 관련 법규를 준수하고 고객의 개인정보를 보호하기 위해 필요한 세부 사항을 규정합니다.</p>
        <p class="esg-content">- 이 규정은 모든 임직원과 회사 정보자산에 접근하는 제3자에게 적용됩니다.</p>
        <p class="esg-content">- 개인정보, 개인정보 처리, 정보주체 등 주요 용어를 정의합니다.</p>
        <p class="esg-content">- 개인정보 수집과 처리는 최소한으로 하며, 목적 외 사용을 금지하고, 안전하게 관리해야 한다는 원칙이 포함됩니다.</p>
        <br/>
        <p class="esg-content">2) 개인정보 보호 조직</p>
        <p class="esg-content">- 개인정보 보호책임자 의무: 고객 개인정보 보호를 위한 정책 수립, 유출 방지, 사고 발생 시 피해 구제 등의 업무를 총괄합니다.</p>
        <p class="esg-content">- 개인정보취급자 의무: 개인정보 보호법과 규정을 준수하고, 유출 시 보고 의무가 있습니다.</p>
        <br/>
        <p class="esg-content">3) 개인정보 주체의 권리 보장</p>
        <p class="esg-content">- 개인정보 처리에 관한 정보 제공, 열람, 정정, 삭제, 파기, 피해 구제 등을 요구할 권리를 명시합니다.</p>
        <br/>
        <p class="esg-content">4) 개인정보의 안전한 관리:</p>
        <p class="esg-content">- 개인정보 처리자는 개인정보의 안전성을 확보하기 위한 기술적, 관리적, 물리적 조치를 취해야 합니다.</p>
        <br/>
        <p class="esg-content">5) 보안사고 대응</p>
        <p class="esg-content">- 개인정보 유출 사고 발생 시 즉시 보고하고, 정보주체에게 통지해야 하며, 신속히 대응 조치를 취해야 합니다.</p>
        <br/>
        <p class="esg-content">6) 보안 점검 및 교육</p>
        <p class="esg-content">- 개인정보 보호를 위한 정기 점검과 교육을 계획하고 시행해야 합니다.</p>
        <br/>
        <p class="esg-content">7) 개인정보처리 방침</p>
        <p class="esg-content">- 개인정보처리자는 법에 따라 개인정보처리방침을 수립하고 공개해야 하며, 필요한 항목을 문서화해야 합니다.</p>
        <br/>
        <p class="esg-content">8) 개인정보보호 체크리스트</p>
        <p class="esg-content">개인정보 보호 관련 사항을 점검하기 위한 체크리스트를 포함합니다.</p>
        <br/>
        <p class="esg-content">9) 모니터링 계획</p>
        <p class="esg-content">정기 모니터링 및 교육 계획을 포함하여 개인정보 보호를 지속적으로 관리합니다.</p>
      </div>
    </div>
  </div>
</template>

<style scoped>
.esg-head{
  font-weight: bold;
  font-size: 2.2917vw;
  margin-bottom: 0.0000vw;
}
.esg-head-sub{
  font-weight: bold;
  font-size: 1.5625vw;
  margin-top: 0.0000vw;
  color: #cccccc;
}
.esg-head-content{
  font-size: 1.4583vw;
}
.esg-governance-container {
  width: 78.1250vw;
  margin: 0.0000vw auto;
}

.content-block {
  margin-bottom: 10%;
  margin-top: 5%;
}

.esg-content-title-container {
  display: flex;
  align-items: center;
}

.content-title {
  padding: 0.5208vw 0.0000vw 0.5208vw 2.6042vw;
  font-size: 2.7083vw;
  font-weight: bold;
  width: 35%;
}

.content-title-sub {
  font-size: 1.4583vw;
  color: #565656;
  width: 60%;
}

.esg-content-container {
  background-color: #dddddd;
  padding: 1.5625vw 0.0000vw 1.5625vw 0.0000vw;
}

.esg-content {
  width: 90%;
  margin: 0.0000vw auto;
  font-size: 1.2500vw;
  padding-bottom: 0.7813vw;
}
.background-img-container{
  background-image: url('/img/esg/img10.png');
  background-position: calc(100% - 2.0833vw) center;
  background-size: auto;
  background-repeat: no-repeat;
}
.img-container{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 78.1250vw;
  margin: 0.0000vw auto;
  padding: 2.6042vw;
  gap:2.6042vw;
}
.cert-number {
  font-size: 1.5625vw;
  margin: 0;
}
.cert-title {
  font-size: 2.0833vw;
  font-weight: bold;
  margin: 0;
}
.cert-subtitle {
  font-size: 1.0417vw;
  margin: 0;
}
.cert-company {
  font-size: 1.2500vw;
  margin: 0;
}
.cert-content {
  font-size: 1.2500vw;
  margin: 0;
  line-height: 1.6;
}
.cert-date {
  font-size: 1.4583vw;
  margin: 0;
}
.cert-chairperson {
  font-size: 1.2500vw;
  margin: 0;
}
.cert-note {
  font-size: 1.0417vw;
  margin: 0;
}
.esg-img{
  width: 100%;
}
.esg-img-m{
  display: none;
}
.esg-img-m-06{
  display: none;
}
@media (max-width: 540px) {
  .esg-img{
    display: none;
  }
  .esg-img-m{
    display: block;
    width: 100%;
  }

  .esg-head {
    font-size: 6.6667vw;
    margin: 0 2.7778vw 0 0;
  }

  .esg-head-sub {
    font-size: 5.1852vw;
    margin: 0;
  }

  .esg-head-content {
    font-size: 4.4444vw;
    word-break: keep-all; /* 단어 단위로 줄바꿈이 발생하도록 */
    white-space: normal; /* 기본 줄바꿈 동작 유지 */
  }

  .content-block-m {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
  }
  .esg-governance-container-m{
    width: 100%;
  }
  .esg-content-title-container{
    flex-direction: column;
    align-items: baseline;
  }
  .content-title{
    font-size: 5.5556vw;
    margin: 0;
    padding: 0 0 0 5.5556vw;
  }
  .content-title-sub{
    font-size: 4.0741vw;
    width: 100%;
    padding: 0 5.5556vw 0 5.5556vw;
    word-break: keep-all; /* 단어 단위로 줄바꿈이 발생하도록 */
    white-space: normal; /* 기본 줄바꿈 동작 유지 */
  }
  .esg-content{
    font-size: 3.7037vw;
  }
  .img-description-container{
    margin: 0 3.7037vw 0 3.7037vw;
  }
  .img-container{
    flex-direction: column;
  }
  .cert-number{
    font-size: 3.7037vw;
    margin: 0;
    padding: 0;
  }
  .cert-subtitle{
    font-size: 2.9630vw;

    margin: 1.8519vw 0 1.8519vw 0;
    padding: 0;
  }
  .cert-title{
    font-size: 4.8148vw;

    margin: 1.8519vw 0 1.8519vw 0;
    padding: 0;
  }
  .cert-company{
    font-size: 3.3333vw;
    margin: 1.8519vw 0 1.8519vw 0;
    padding: 0;
  }
  .cert-content{
    font-size: 2.9630vw;
    margin: 1.8519vw 0 1.8519vw 0;
    padding: 0;
    word-break: keep-all; /* 단어 단위로 줄바꿈이 발생하도록 */
    white-space: normal; /* 기본 줄바꿈 동작 유지 */
  }
  .cert-date{
    font-size: 3.3333vw;

    margin: 1.8519vw 0 1.8519vw 0;
    padding: 0;
  }
  .cert-chairperson{
    font-size: 3.3333vw;

    margin: 3.7037vw 0 3.7037vw 0;
    padding: 0;
  }
  .cert-note{
    font-size: 2.5926vw;
    padding: 0;
  }
  .background-img-container{
    background-image: none;
  }
  .esg-img-m-06{
    display: block;
    margin: 0 auto;
  }
}
</style>