<template>
  <!-- 첫 번째 이미지 -->
  <img ref="firstImage" class="responsive-img1 fade-in-up" src="/img/public_img/bg_02_img.png" alt="Background Image"/>
  <div class="greeting-container">
    <div class="greeting-content grid">
      <div class="greeting-text col-8">
        <span class="greeting-head">
          가후이엔씨<span class="small-text-head">(주)</span>, 자동화의 큰 바다에서 <br/>
          지속 가능한 혁신의 등대가 되다.<div class="none-m"><br/><br/></div>
        </span>
        <img class="responsive-img2-m" src="/img/introduce/m/intro_page01_m.jpg" alt="Image"/>
        <span class="greeting-text-content">
          안녕하십니까? <br/>
          저희 가후이엔씨<span class="small-text-head-sub">(주)</span>
          홈페이지를 방문해 주신 모든 분들을 진심으로 환영합니다.
        <br/><br/>
        </span>
        <span class="greeting-text-content-small">
쌍용정보통신(주) 자동화사업 부문에서 분사한 저희 회사는 세계 일류 수준의 자동화 소프트웨어 개발과 컨설팅 전문가 그룹으로 성장해 왔습니다.
<br/>
저희는 첨단 자동화 기술과 풍부한 경험을 토대로 고객의 요구를 정확하게 파악하고, 이를 충족시키기 위한 맞춤형 솔루션을 제공함으로써 단순한 고객 만족을 넘어선 행복을 추구합니다.
  <br/><br/>
가후이엔씨(주)는 자동화 부문에서의 Total Solution 제공 뿐만 아니라, 국산화를 위한 신제품 개발에도 전력을 다하고 있습니다.
<br/>
이 모든 과정에서 저희는 환경 보호, 사회적 책임, 건전한 기업 거버넌스를 중심으로 하는 ESG 경영을 실천하고 있습니다.
<br/><br/>
저희는 단순한 고객 만족을 넘어 모든 이해관계자와 함께 지속 가능한 미래를 만들어가는 데 전념하고 있습니다.
<br/>
가후이엔씨(주)는 친환경 기술과 프로세스를 통해 업계에서 지속 가능한 발전을 이끌며, 사회적 책임을 다하는 기업으로서의 역할을 충실히 수행하겠습니다.
<br/><br/>
저희의 여정에 변함없는 관심과 따뜻한 격려를 부탁드립니다.
<br/>
앞으로도 지속 가능한 혁신을 통해 고객 여러분과 함께 성장하며 나아가겠습니다.<br/><br/><div class="none-m"><br/><br/><br/></div>
        </span>
        <span class="greeting-text-content-close">
          <span class="greeting-text-content-close-first">대 표 이 사 | </span>  서 광 익
        </span>
      </div>
      <div class="greeting-img col-4">
        <img class="responsive-img2" src="/img/introduce/intro_page01.jpg" alt="Image"/>
      </div>
    </div>
  </div>
</template>

<script setup>
import {useIntersectionObserver} from '@/utils/useIntersectionObserver';

import {ref} from "vue";

const firstImage = ref(null);

useIntersectionObserver([firstImage]);

</script>

<style scoped>
.col-8 {
  width: 70%;
}
.col-4 {
  width: 30%;
}
.small-text-head {
  font-size: 1.8750vw;
}
.small-text-head-sub {
  font-size: 1.4583vw;
}
.greeting-container {

  width: 78.1250vw;
  margin: 0 auto;
}
.responsive-img1 {
  max-width: 100%;
  height: auto;
  display: block;
  position: relative;
  z-index: 1; /* 첫 번째 이미지를 최상단에 배치 */
}
.greeting-img {
  display: flex;
  align-items: center;
}
.responsive-img2 {
  max-width: 100%;
  height: 80%;
  position: relative;
  z-index: 1; /* 첫 번째 이미지를 최상단에 배치 */
}
.greeting-content {
  width: 100%;
  position: relative;
  z-index: 2; /* 배경 이미지보다 앞에 배치 */
}
.greeting-text {
  padding: 0 0 10% 0;
}
.greeting-head {
  font-weight: bold;
  color: #0b539f;
  font-size: 3.1250vw;
  margin: 0;
}
.greeting-text-content {
  font-size: 2.0313vw;

}
.greeting-text-content-small {
  font-size: 1.5625vw;
  line-height: 180%;
}
.greeting-text-content-close {
  font-size: 1.4583vw;
  font-weight: bold;
}
.greeting-text-content-close-first {
  font-size: 1.4583vw;
  font-weight: lighter;
  color: #565656;
}
.responsive-img2-m {
  display: none;
}
@media (max-width: 540px) {
  .none-m{
    display: none;
  }
  .col-4 {
    width: 100%;
  }
  .col-8 {
    width: 100%;
  }
  .responsive-img1 {
    display: none;
  }
  .greeting-img {
    display: none;
  }
  .greeting-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .greeting-container {
    display: flex;
    width: 97.0000vw;
  }
  .greeting-text {
    padding: 10% 0 10% 0;
  }
  .responsive-img2 {
    display: none;
  }
  .responsive-img2-m {
    display: flex;
    height: auto;
    width: 100.0000vw;
  }
  .greeting-head {
    display: block;
    font-size: 5.5556vw;
    width: 92.5926vw;
    margin: 0 auto;
    padding: 0;
    color: #565656;
  }
  .small-text-head{
    font-size: 5.5556vw;
  }
  .greeting-text-content{
    display: block;
    font-size: 3.8889vw;
    width: 88.8889vw;
    margin: 0 auto;
    padding: 5.5556vw 0 0 0;
  }
  .small-text-head-sub{
    font-size: 3.7037vw;
  }
  .greeting-text-content-small{
    display: block;
    font-size: 3.5185vw;
    width: 88.8889vw;
    margin: 0 auto;
    padding: 0;
  }
  .greeting-text-content-close{
    display: block;
    font-size: 3.7037vw;
    width: 81.4815vw;
    margin: 0 auto;
    padding: 0;
    text-align: right;
  }
  .greeting-text-content-close-first{
    font-size: 3.7037vw;
    margin: 0 auto;
    padding: 0;
  }
}
</style>
