<template>
  <img ref="firstImage" class="first-image fade-in-up" style="width: 100%;" src="/img/public_img/bg_02_img.png" alt="Logo"/>
  <div class="main-container">
    <div class="tab-container">
      <span
          class="tab-menu"
          v-for="(tab, index) in menuList"
          :key="index"
          :class="{ active: index === activeTab }"
          @click="selectTab(index)"
      >
        {{tab.title}}
      </span>
    </div>

    <!-- 선택된 탭에 따른 콘텐츠 렌더링 -->
    <div v-if="activeContent" class="content">
      <p class="content-title">{{ activeContent.title }}</p>
      <div
          v-for="(item, index) in activeContent.items"
          :key="index"
          class="content-item"
      >
        <span class="content-item-left">{{ item.left }}</span>
        <span class="content-item-right">{{ item.right }}</span>
      </div>
    </div>
  </div>
</template>

<script setup>
import {ref, computed} from 'vue';
import {useIntersectionObserver} from '@/utils/useIntersectionObserver';


const firstImage = ref(null);

useIntersectionObserver([firstImage]);

// 현재 활성화된 탭을 추적하는 변수
const activeTab = ref(0);

// 현재 선택된 탭의 콘텐츠를 반환하는 계산된 속성
const activeContent = computed(() => menuList[activeTab.value].content);

// 탭을 선택하는 함수
function selectTab(index) {
  activeTab.value = index;
}

const menuList = [
  {
    title: '2024-2026',
    content: {
      title: '2024-2026',
      items: [
        { left: '출하자동화시스템 연간 유지보수 용역 외', right: 'S-OIL(주) 온산공장 외 11개 저유소' },
        { left: '여수저유소 반자동 출하시스템 구축', right: 'S-OIL(주) 여수저유소' },
        { left: '인도네시아 PA8-Project HMI Intouch Program 외', right: 'CJ제일제당(주)' },
        { left: '제분 및 프리믹스 공정, FSP 시스템 구축', right: 'CJ제일제당(주) 양산공장' },
        { left: '중백당 AC 금속검출기 교체 외', right: 'CJ제일제당(주) 인천1공장' },
        { left: '기관실 수지탑 개선 외', right: 'CJ제일제당(주) 인천2공장' },
        { left: '동아제약 판피린 설비 자동화 프로그램 개발', right: '(주)동화이엔아이' },
        { left: '동재소성설비 투자 PCS 공사', right: '스틸싸이클에스씨(주)' },
        { left: 'MP #1 Two-way Damper 개선 공사 외', right: '한국씨앤씨(주)' },
        { left: '신규 VF 및 착유기 국산화 HMI 작업', right: '(주)참고을지평선공장' },
        { left: 'SPC Samlip 세종공장 / A라인 정선 설비 교체에 따른 전기설치 및 자동화', right: '(주)뷸러' },
        { left: 'Column Automation Control Project', right: '에어리퀴드' },
        { left: '현대제철 포항공장 스크랩 선별설비 Project 시스템', right: '현대제철' },
      ]
    }
  },
  {
    title: '2021-2023',
    content: {
      title: '2021-2023',
      items: [
        { left: '출하자동화시스템 연간 유지보수 용역 외', right: 'S-OIL(주) 온산공장 외 11개 저유소' },
        { left: 'Fly Ash Silo(250톤) 1기 증설(전기) 공사 외', right: '한국씨앤씨(주)' },
        { left: '청양공장 LAPS 제어 프로그램 공사', right: '애경산업(주)' },
        { left: 'PM분 배출라인 개선 자동화공사 외', right: 'CJ제일제당(주)' },
        { left: '품질RM 요리당 소물 비전 PLC설치공사 외', right: 'CJ제일제당(주) 인천1공장' },
        { left: '참기름 콜드프레스 HMI 국산화 외', right: 'CJ제일제당(주) 인천2공장' },
        { left: 'Smart HACCP 공사 외', right: 'CJ제일제당(주) 인천3공장' },
        { left: 'HMI수정 공사', right: 'CJ제일제당(주) 인천냉동공장' },
        { left: 'PROCESS CONTROL SYSTEM', right: '스틸싸이클에스씨(주)' },
        { left: '동화약품 30ML 액제조제설비공사', right: '(주)대성후드텍' },
      ]
    }
  },
  {
    title: '2018-2020',
    content: {
      title: '2018-2020',
      items: [
        { left: '출하자동화시스템 연간 유지보수 용역 외', right: 'S-OIL(주) 온산공장, 인천/영천/군산저유소' },
        { left: '출하자동화시스템 구축', right: 'S-OIL(주) 목포저유소' },
        { left: '판교 저유소에서 맞춤형 무인단말기도입', right: '에쓰오일(주)' },
        { left: '(S-Oil OIP) Offsite Improvement Plan Project', right: '삼성물산(주)' },
        { left: '(미얀마)시멘트공장 PLC & SCADA 시스템구축', right: '대선이엔씨(주)' },
        { left: '(중국)발효대두박 자동화 Program 개발공사', right: 'CJ(FOSHAN) BIOTECH' },
        { left: '공정 지표관리 시스템 구축 외', right: 'CJ제일제당(주) 인천2공장' },
        { left: '전분공장 참지조 자동화시스템 구축 외', right: 'CJ제일제당(주) 안산공장' },
        { left: '액젓이전 전처리 통합모니터링시스템 구축', right: 'CJ제일제당(주) 논산공장' },
        { left: '제분 WHT 공정 외 HMI 자동화 보완공사', right: 'CJ제일제당(주) 양산공장' },
        { left: '밀다원 제분정선공정 리모델링 프로그램 설치공사 외', right: '(주)밀다원' },
        { left: 'Ring Blower전기공사', right: '한국씨엔티(주)' },
        { left: '장성공장 생산공정 PLC 및 HMI 프로그램 유지보수 외', right: '고려시멘트' },
        { left: '외업공장 #2 압축기실 300HP 컴퓨레샤 상태 감지 시스템 추가 설치공사', right: '대한조선(주)' },
        { left: 'TRIPPER PLC UPGRADE 교체공사 외', right: '동석물류(주)' },
        { left: '5000톤 Silo 하부 Blower 이설 등 전기공사', right: '한국씨앤씨(주)' },
        { left: '탱크 재고관리 시스템 구축', right: '한일탱크터미널(주)' },
      ]
    }
  },
  {
    title: '2015-2017',
    content: {
      title: '2015-2017',
      items: [
        { left: '출하자동화시스템 연간 유지보수 용역 외', right: 'S-OIL(주) 온산공장, 인천/영천/군산저유소' },
        { left: '노후 출하자동화설비 개선작업 외', right: 'S-OIL(주) 인천저유소' },
        { left: 'BOT-43 Tank 개조공사(제어시스템 부문) 외', right: '에쓰-오일 토탈 윤활유(주)' },
        { left: '산당CCR 구중데이터 전송 보완공사 외', right: 'CJ제일제당(주) 인천1공장' },
        { left: '발효대두박, 원료사이로, 참깨분 공사 외', right: 'CJ제일제당(주) 인천2공장' },
        { left: '기능당 A10 자동화시스템 구축', right: 'CJ제일제당(주) 안산공장' },
        { left: '앙념장 조제실 MMI & MES연동 공사', right: 'CJ제일제당(주) 논산공장' },
        { left: '제분 Bulk제분 추가라인 구축 자동화 공사', right: 'CJ제일제당(주) 양산공장' },
        { left: '소주공장 시스템 구축공사', right: '(주)롯데주류' },
        { left: '분입고 기계설비 설치공사 외', right: '동석물류(주)' },
        { left: '플라이애쉬 정제설비 계장설비 공사', right: '(주)고려시멘트' },
        { left: 'M-P#1 5톤 Bin 설치 등 전기공사', right: '한국씨엔티(주)' },
        { left: 'RS-1209 반응기 공사 외', right: '삼화페인트(주)' },
        { left: '시럽실 Coke 블랜더용 자동제어 시스템 개선 외', right: '코카콜라음료(주)' },
        { left: '당진 및 태안화력 Fire Detection & Alarm System작업', right: '씨플러스(주)' },
      ]
    }
  },
  {
    title: '2012-2014',
    content: {
      title: '2012-2014',
      items: [
        { left: '출하자동화시스템 연간 유지보수 용역 외', right: 'S-OIL(주) 온산공장, 인천/영천/군산저유소' },
        { left: '출하자동화시스템', right: '(주)청운에너텍' },
        { left: '파주석산 Crusher Plant 제어시스템 외', right: '유진기업(주), 한국시멘트(주)' },
        { left: 'Chip Tire 투입설비 설치공사 외', right: '쌍용양회공업(주), 영동화력발전소' },
        { left: '전분 및 전분당 자동화 외', right: 'CJ제일제당(주), (주)신동방CP' },
        { left: '통합 모니터링 시스템 구축 외', right: '하선정종합식품(주), 코카콜라음료(주)' },
        { left: 'S Project 외', right: 'CJ제일제당(주)필리핀, 인도네시아' },
        { left: '액체제조공정 자동화 외', right: '애경산업(주), 현대중공업(주)' },
        { left: 'Chemical Injection LCU 개발, #2 PRS System 외', right: 'S-OIL(주), 에쓰-오일토탈윤활유(주)' },
        { left: '생활폐기물 소각시설 건설공사 외', right: '진영제지공업(주), 신대양제지(주), 한국수출포장(주)' },
        { left: '목재펠렛 제조시설 설치공사 외', right: '청원군, 의성군, 장흥군' },
        { left: '저등급 석탄건조기, 플라즈마 용융로 외', right: '한국에너지기술연구원, 한국생산기술연구원, (주)아펙, 전남생물지원센터' },
      ]
    }
  },
  {
    title: '2009-2011',
    content: {
      title: '2009-2011',
      items: [
        { left: '출하자동화시스템 연간 유지보수, 탱크 증설공사 외', right: 'S-OIL(주)' },
        { left: '대두피 펠렛화 계전공사, 구중관리시스템 외', right: 'CJ제일제당(주), 한국원자력연구소' },
        { left: '폐기물 재활용 소각설비 자동화', right: '신대양제지(주), 대한페이퍼택(주)' },
        { left: '4소결공정 집진기 HMI System 개발 외', right: 'POSCO(주), POSCO(주)기술연구소' },
        { left: 'Fly ASH 분쇄설비 자동화 시스템 외', right: '고려시멘트(주), 성신양회(주), 한국시멘트(주)' },
        { left: '인천지하철 송도 연장선 PSD System설치공사', right: '현대로템(주)' },
        { left: 'MES/HMI 시스템 구축공사 외', right: '(주)노루페인트, 영동화력발전소' },
      ]
    }
  },
  {
    title: '2006-2008',
    content: {
      title: '2006-2008',
      items: [
        { left: '출하자동화시스템 및 보완작업 외', right: '삼성아토피나(주), S-OIL(주), 현대석유화학(주)' },
        { left: 'Sugar SILO System 공사 외', right: 'CJ(주) 인천1, 인천2' },
        { left: 'PLC & MMI System 보완공사 외', right: '쌍용양회공업(주), 성신양회(주), S-OIL(주)' },
        { left: 'Clinker SILO 설치공사 외', right: '한국시멘트(주)' },
        { left: 'PLC & MMI System 보완공사 외', right: 'S-OIL(주), 애경산업(주)' },
        { left: '토너공정 자동화 공사, 13라인 통합모니터링', right: 'LG화학(주), 삼성전자(주)' },
        { left: '건면 건조기 PLC & MMI, 폐수처리장 제어시스템', right: '(주)농심, 서울대 국제백신연구소' },
        { left: 'N/F System PLC & MMI Programming', right: '종근당바이오(주)' },
        { left: 'MIDAS 연간 유지보수', right: '한국원자력연구소' },
        { left: '출하자동화시스템 연간 유지보수', right: 'S-OIL(주)' },
      ]
    }
  },
  {
    title: '2003-2005',
    content: {
      title: '2003-2005',
      items: [
        { left: '소각로제어시스템, 정수처리시스템, 3건', right: '한솔제지, LG전자 외' },
        { left: '반도체Wire제어설비자동화, 생산설비MES, 2건', right: '고려제강, 삼성코닝' },
        { left: '시멘트/레미콘 출하자동화 Version Upgrade', right: '쌍용양회' },
        { left: '전산업무 및 출하업무 M/A, 2건', right: '쌍용양회 SM' },
        { left: '온산공장 Truck Loading Facility Automation 외', right: 'S-OIL, 한일탱크터미널' },
      ]
    }
  },
  {
    title: '2000-2002',
    content: {
      title: '2000-2002',
      items: [
        { left: 'Raw Mill, Coal Mill 자동화, 3건', right: '쌍용양회' },
        { left: 'Slag Mill, Cement Mill, 석회석 항만자동화, 3건', right: '쌍용양회, 성신양회' },
        { left: '인도네시아 Pozzolan 화산회처리', right: '쌍용엔지니어링' },
        { left: '싱가포르 PDL 출하 자동화', right: '쌍용엔지니어링' },
        { left: 'IBS(BAS, TC, OA), 11건', right: '서울이동통신, 에스에어피코리아, 쌍용 외' },
        { left: '출하자동화, 8건', right: '쌍용양회, 태광기업 외' },
        { left: '환경감시시스템(Stack Monitoring), 2건', right: '쌍용양회' },
        { left: '전력관리시스템, 5건', right: '쌍용자동차, 용평리조트, 아세아시멘트 외' },
        { left: '제지공정 B/M, 저유Tank 외 4건', right: '쌍용제지, 쌍용정유' },
        { left: '발전기 제어장치, 7건', right: '쌍용중공업' },
        { left: '물류자동화 및 산업용 ID(RFID, Bar-Code), 6건', right: '쌍용자동차, 한화기계, 쌍용중공업' },
        { left: 'CIM, Robot감시, 도장자동화, 15건', right: '대우중공업, 쌍용자동차, 쌍용중공업 외' },
        { left: '공장업무전산화 및 LAN사업, 18건', right: '쌍용자동차, KITC 외' },
      ]
    }
  },
  {
    title: '1997-1999',
    content: {
      title: '1997-1999',
      items: [
        { left: 'Cement Mill 통합FA 및 운전최적화, 14건', right: '쌍용양회, 성신양회' },
        { left: '대불출하기지, 신광산 운전자동화, 2건', right: '쌍용양회' },
        { left: '탈황설비(Elsag Bailey DCS, Instrument)', right: '한국전력(보령#3-6)' },
        { left: '회정제 및 처리설비 자동화, 3건', right: '한국전력(삼천포), 쌍용양회' },
        { left: '환경감시 및 제어시스템(Stack, Tire), 7건', right: '중외제약, 광주군청, 대한타이어, 공업협회 외' },
        { left: 'IBS(BAS, TC, OA), 32건', right: '쌍용, 쌍용투자증권, 연합철강, 건원건축 외' },
        { left: 'GP Series PAD-Face 공급, 35건', right: '세림오토메이션, 신효하이테크' },
        { left: '출하자동화, 47건', right: '쌍용양회, 쌍용정유, 성남레미콘 외' },
        { left: '전력관리시스템, 7건', right: '쌍용자동차' },
        { left: 'CIM, SPC, Robot 감시, 22건', right: '대우중공업, 금호타이어, 쌍용자동차 외' },
        { left: '물류자동화 및 산업용ID(RFID, Bar-Code), 19건', right: '쌍용자동차, 한화기계, 쌍용중공업 외' },
        { left: '공장업무전산화 및 LAN 사업, 30건', right: '쌍용자동차, 건창운수 외' },
        { left: 'TM/TC', right: '가스공사' }
      ]
    }
  },
  {
    title: '1994-1996',
    content: {
      title: '1994-1996',
      items: [
        { left: 'Kiln 제어설비(ABB DCS)외 14건', right: '쌍용양회, 금성사 외' },
        { left: 'IBS(BAS, TC, DA) 설계 용역 및 개발, 4건', right: '쌍용투자증권, 쌍용건설' },
        { left: 'GP Series PRO-Face 공급, 32건', right: '세림오토메이션, 삼성엔지니어링 외' },
        { left: '환경 감시 시스템(Stack), 37건', right: '남해화학, 호남화력, 대한펄프, 환경처 외' },
        { left: 'Raw Mix, Slag Mill 제어설비, 5건', right: '쌍용양회' },
        { left: '출하자동화(계량, 포장기, 검사기, Bar-code), 12건', right: '해태제과, 쌍용양회, 포항제철, 쌍용자동차 외' },
        { left: '전력관리시스템, 3건', right: '쌍용자동차, 쌍용양회' },
        { left: '가스파이프 감시 및 제어, 2건', right: '서울도시가스' },
        { left: 'CIM, SPC, POP, 4건', right: '만도기계' },
        { left: 'MIS(물류, 영업, 생산, 인사 등), 16건', right: '쌍용해운, 쌍용제지, 쌍용중공업, 동국제강 외' }
      ]
    }
  },
  {
    title: '1991-1993',
    content: {
      title: '1991-1993',
      items: [
        { left: 'Cement Mill 통합FA 및 운전최적화, 14건', right: '쌍용양회, 성신양회' },
        { left: '대불출하기지, 신광산 운전자동화, 2건', right: '쌍용양회' },
        { left: '탈황설비(Elsag Bailey DCS, Instrument)', right: '한국전력(보령#3-6)' },
        { left: '회정제 및 처리설비 자동화, 3건', right: '한국전력(삼천포), 쌍용양회' },
        { left: '환경감시 및 제어시스템(Stack, Tire), 7건', right: '중외제약, 광주군청, 대한타이어, 공업협회 외' },
        { left: 'IBS(BAS, TC, OA), 32건', right: '쌍용, 쌍용투자증권, 연합철강, 건원건축 외' },
        { left: 'GP Series PAD-Face 공급, 35건', right: '세림오토메이션, 신효하이테크' },
        { left: '출하자동화, 47건', right: '쌍용양회, 쌍용정유, 성남레미콘 외' },
        { left: '전력관리시스템, 7건', right: '쌍용자동차' },
        { left: 'CIM, SPC, Robot 감시, 22건', right: '대우중공업, 금호타이어, 쌍용자동차 외' },
        { left: '물류자동화 및 산업용ID(RFID, Bar-Code), 19건', right: '쌍용자동차, 한화기계, 쌍용중공업 외' },
        { left: '공장업무전산화 및 LAN 사업, 30건', right: '쌍용자동차, 건창운수 외' },
        { left: 'TM/TC', right: '가스공사' }
      ]
    }
  }

];

</script>

<style scoped>
.customer-list{
  display: none;
}
p {
  margin: 0;
}

.tab-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 5.2083vw;
  gap: 0.5208vw;
}

.tab-menu {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2.0833vw;
  background-color: #868787;
  border-radius: 5.2083vw;
  color: white;
  padding: 1.0417vw 0 0.5208vw 0;
  width: 18.1042vw;
  cursor: pointer;
  transition: background-color 0.3s;
}

.tab-menu:hover {
  background-color: #555; /* 탭에 마우스를 올렸을 때 */
}

.tab-menu.active {
  background-color: #12659d; /* 선택된 탭의 색상 */
}

.content {
  font-size: 1.0417vw;
  text-align: left;
  margin: 5.2083vw 0 5.2083vw 0;
}
.content-title{
  font-size: 5.2083vw;
  font-weight: lighter;
  margin-bottom: 1.0417vw;
}
.content-item{
  display: flex;
  align-items: center;
  margin-bottom: 0.5208vw;
}
.content-item-left{
  padding: 0.5208vw 0 0.5208vw 3%;
  width: 70%;
  background-color: #12659d;
  color: white;
  font-size: 1.5625vw;
  height: 2.8646vw;
}
.content-item-right{
  padding: 0.5208vw 0 0.5208vw 3%;
  width: 30%;
  background-color: #f2f2f2;
  color: gray;
  font-size: 1.0417vw;
  height: 2.8646vw;
  display: flex;              /* Flexbox 사용 */
  align-items: center;        /* 수직 가운데 정렬 */
}

.background-img{
  padding-top: 2.6042vw;
  background-image: url('/img/business/bg_banner02.jpg');
  background-position: center;
  background-size: auto;
  background-repeat: no-repeat;
  height: 23.4375vw;
  margin-top: 26.0417vw;
}
.customer-container{
  width: 100%;
  text-align: center;
}
.customer-header{
  color: white;
  font-size: 2.3958vw;
}
.customer-list{
  margin-top: 5.2083vw;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5208vw;
  justify-content: center;
}
.customer-carousel .p-carousel-container {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5208vw;
  justify-content: center;
}

.customer-item {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2.0833vw 0 1.0417vw 0;
}
@media (max-width: 540px) {
  .first-image{
    display: none;
  }
  .main-container{
    width: 92.5926vw;
  }
  .tab-menu {
    font-size: 3.7037vw;
    border-radius: 1.8519vw;
    padding: 1.8519vw 1.8519vw 1.8519vw 1.8519vw;
    width: 30.1852vw;
  }
  .tab-container{
    margin: 5.5556vw 0 0 0 ;
    justify-content: space-between;
    gap: 0.9259vw;
  }
  .content-title{
    font-size: 9.6296vw;
  }
  .content-item {
    margin-bottom: 1.8519vw;
    align-items: stretch; /* 요소 높이 맞추기 */
  }

  .content-item-left, .content-item-right {
    padding: 1.8519vw;
    font-size: 3.7037vw;
    min-height: 12.0370vw;
    height: auto;
  }

  .content-item-left {
    flex: 3; /* 너비 비율 */
  }

  .content-item-right {
    flex: 2; /* 너비 비율 */
  }
  .background-img{
    padding-top: 5%;
    height: 55.5556vw;
  }
  .customer-header{
    font-size: 5.9259vw;

    margin: 0 0 9.2593vw 0;
  }
  .customer-list{
    display: none;
  }
  .customer-container {
    margin-top: 3.7037vw;
  }



  .customer-logo {
    width: 100%;
    height: auto;
    max-width: 22.2222vw; /* 최대 크기 제한 */
  }
}
</style>
